import { Actions } from '../enums/ActionEnums';
import { ActionType } from '../models/appcontext';

export const balanceReducer = (state: boolean, action: ActionType): boolean => {
  switch (action.type) {
    case Actions.BalanceLoading:
      return action.payload;
    default:
      return state;
  }
};
