import { Actions } from '../enums/ActionEnums';
import { ActionType, MessageType } from '../models/appcontext';

export const messageReducer = (state: MessageType, action: ActionType): MessageType => {
  switch (action.type) {
    case Actions.ShowMessage:
      return {
        open: true,
        text: action.payload.text,
        severity: action.payload.severity,
        autoHide: action.payload.autoHide ?? 3000
      };
    case Actions.HideMessage:
      return { ...state, open: false };
    default:
      return state;
  }
};
