import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
//* MUI
import { Box, Fade, InputAdornment, TextField } from '@mui/material';
//* MUI Icons
import { ConfirmationNumber, Done } from '@mui/icons-material';
//* Components
import CustomChip from '../../../Common/CustomChip/CustomChip';
import ActiveBonusControls from './ActiveBonusControls/ActiveBonusControls';
import PrimaryButton from '../../../Common/Buttons/PrimaryButton/PrimaryButton';
import SuggestedBonusControls from './SuggestedBonusControls/SuggestedBonusControls';
import CustomIconButton from '../../../Common/Buttons/CustomIconButton/CustomIconButton';
import MyAccountRowElement from '../../../Common/MyAccountRowElement/MyAccountRowElement';
//* Utils
import { getRecentBonusesData, getActiveBonusProgress } from '../../../../utils/bonuses';
//* Models
import { IMyAccountRowElement } from '../../../../models/wallet';
import { IBonus, IActiveBonus, IBonusTableSelectedFilter } from '../../../../models/bonus';
//* Styles
import './RecentBonuses.scss';

interface IProps {
  suggestedBonuses: IBonus[];
  addNewCouponIsVisible: boolean;
  activeBonus: IActiveBonus | undefined;
  handleClaimBonus: (suggestedBonus: IBonus) => void;
  handleActivateCoupon: (newCouponCode: string) => void;
  handleSetAddNewCouponIsVisible: (isVisible: boolean) => void;
  handleDeleteBonusConfirmationOpen: (bonus: IMyAccountRowElement) => void;
  handleBonusTableFilter: (
    selectedBonusContent: number,
    selectedFilterOption: IBonusTableSelectedFilter | null
  ) => void;
}

const RecentBonuses = ({
  activeBonus,
  suggestedBonuses,
  handleClaimBonus,
  handleActivateCoupon,
  addNewCouponIsVisible,
  handleBonusTableFilter,
  handleSetAddNewCouponIsVisible,
  handleDeleteBonusConfirmationOpen
}: IProps) => {
  const bonusFilterData = [
    { id: 0, label: t('myAccount.bonusPage.showAll') },
    { id: 1, label: t('myAccount.bonusPage.pastTitle') }
  ];

  const { i18n } = useTranslation();

  const [newCouponCode, setNewCouponCode] = useState('');

  const enrichRecentBonuses = ({
    activeBonus,
    recentBonuses
  }: {
    activeBonus: IActiveBonus | undefined;
    recentBonuses: IMyAccountRowElement[];
  }) => {
    const enrichedRecentBonuses = [...recentBonuses];
    let disableClaimBonus = false;

    enrichedRecentBonuses.map((bonus: IMyAccountRowElement) => {
      if (activeBonus && bonus.type === 'activeBonus') {
        const progress = getActiveBonusProgress(activeBonus);

        disableClaimBonus = Object.keys(activeBonus).length > 0;
        bonus.titleChips = <CustomChip condensed label="Active" type="highlighted" />;
        bonus.customControls = <ActiveBonusControls bonus={activeBonus} progress={progress} />;
      }

      if (bonus.type === 'suggestedBonus') {
        const bonusData = suggestedBonuses.find(
          (suggestedBonus) => suggestedBonus.code === bonus.title
        );

        if (bonusData) {
          bonus.customControls = (
            <SuggestedBonusControls
              bonus={bonusData}
              handleClaimBonus={handleClaimBonus}
              disableClaimBonus={disableClaimBonus}
            />
          );
        }
      }
    });

    return enrichedRecentBonuses;
  };

  const renderRecentBonuses = () => {
    const recentBonuses = getRecentBonusesData({
      activeBonus,
      suggestedBonuses,
      language: i18n.language
    });

    const enrichedRecentBonuses = enrichRecentBonuses({ activeBonus, recentBonuses });

    const bonuses: JSX.Element[] = enrichedRecentBonuses.map((recentBonus, index) => (
      <MyAccountRowElement
        key={`${index}-recent`}
        element={recentBonus}
        handleConfirmation={handleDeleteBonusConfirmationOpen}
      />
    ));

    return bonuses.slice(0, 3);
  };

  return (
    <>
      <Box className="bonuses__recent">{renderRecentBonuses()}</Box>
      {!addNewCouponIsVisible && (
        <Box className="bonuses__add-coupon">
          <PrimaryButton
            buttonVariant="outlined"
            text={t('myAccount.bonusPage.addNewCoupon')}
            onClick={() => handleSetAddNewCouponIsVisible(true)}
          />
        </Box>
      )}
      {addNewCouponIsVisible && (
        <Fade in timeout={300}>
          <Box className="bonuses__new-coupon">
            <TextField
              fullWidth
              color="info"
              margin="none"
              id="coupon"
              value={newCouponCode || ''}
              onChange={(event) => setNewCouponCode(event.target.value)}
              placeholder={t('wallet.depositPage.couponPlaceholder') as string}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="bonuses__new-coupon-adornment">
                    <ConfirmationNumber />
                  </InputAdornment>
                ),
                endAdornment: (
                  <Box className="bonuses__new-coupon-end-adornment">
                    <CustomIconButton color="gold" icon={<Done />} />
                  </Box>
                )
              }}
            />
            <PrimaryButton
              disabled={newCouponCode.length === 0}
              onClick={() => handleActivateCoupon(newCouponCode)}
              text={t('myAccount.bonusPage.couponActivateButton')}
            />
          </Box>
        </Fade>
      )}
      <button
        className="bonuses__see-all"
        onClick={() => handleBonusTableFilter(1, bonusFilterData[0])}
      >
        {t('myAccount.bonusPage.seeAll')}
      </button>
    </>
  );
};

export default RecentBonuses;
