import React, { useState } from 'react';
import { Box, TextField, InputAdornment, InputLabel, TextFieldProps } from '@mui/material';
import { AccountCircle, PermContactCalendar, Email } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { labelStyle } from '../SigninPage';
import { getIconColor, getBorderColor, IErrors, inputPropsStyle } from '../SignupPage';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker } from '@mui/lab';
import moment from 'moment';

interface IProps {
  formErrors: IErrors;
  handleFirstNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  firstName: string;
  handleLastNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  lastName: string;
  handleBirthdayChange: (value: moment.Moment | null) => void;
  birthday: moment.Moment | null;
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  email: string;
  minBirthday: moment.Moment;
}

const Step1: React.FunctionComponent<IProps> = ({
  formErrors,
  firstName,
  handleFirstNameChange,
  lastName,
  handleLastNameChange,
  birthday,
  handleBirthdayChange,
  email,
  handleEmailChange,
  minBirthday
}: IProps) => {
  const [openPicker, setOpenPicker] = useState(false);
  const { t } = useTranslation();

  return (
    <Box>
      <Box>
        <InputLabel htmlFor="firstName" style={labelStyle}>
          {t('account.firstNameLabel')}
        </InputLabel>
        <TextField
          id="firstName"
          name="firstName"
          margin="normal"
          variant="outlined"
          fullWidth
          required
          value={firstName}
          onChange={handleFirstNameChange}
          error={formErrors.firstName !== null}
          helperText={formErrors.firstName ? formErrors.firstName : ''}
          sx={{
            height: '46px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: getBorderColor(firstName)
              }
            }
          }}
          inputProps={{ sx: inputPropsStyle }}
          placeholder={t('account.namePlaceHolder') as string}
          color="info"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle sx={{ fill: getIconColor(firstName, formErrors.firstName) }} />
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box>
        <InputLabel htmlFor="lastName" style={labelStyle}>
          {t('account.lastNameLabel')}
        </InputLabel>
        <TextField
          id="lastName"
          name="lastName"
          margin="normal"
          variant="outlined"
          fullWidth
          required
          value={lastName}
          onChange={handleLastNameChange}
          error={formErrors.lastName !== null}
          helperText={formErrors.lastName ? formErrors.lastName : ''}
          sx={{
            height: '46px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: getBorderColor(lastName)
              }
            }
          }}
          inputProps={{ sx: inputPropsStyle }}
          placeholder={t('account.namePlaceHolder') as string}
          color="info"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle sx={{ fill: getIconColor(lastName, formErrors.lastName) }} />
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box style={{ marginTop: '13px' }}>
        <InputLabel htmlFor="birthday" style={{ ...labelStyle, top: '-3px' }}>
          {t('account.birthdayLabel')}
        </InputLabel>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            open={openPicker}
            onOpen={() => setOpenPicker(true)}
            onClose={() => setOpenPicker(false)}
            value={birthday}
            onChange={handleBirthdayChange}
            inputFormat="DD/MM/YYYY"
            maxDate={minBirthday}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                {...params}
                onClick={() => setOpenPicker(true)}
                variant="outlined"
                fullWidth
                color="info"
                error={formErrors.birthday !== null}
                helperText={formErrors.birthday ? formErrors.birthday : ''}
                disabled
                sx={{
                  height: '46px',
                  '& .MuiOutlinedInput-root': {
                    height: '46px',
                    '& fieldset': {
                      borderColor: getBorderColor(birthday?.format('dd/mm/yyyy'))
                    }
                  },
                  '& .Mui-disabled': {
                    color: formErrors.birthday ? 'error.main' : 'info.main',
                    WebkitTextFillColor: 'inherit'
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PermContactCalendar
                        sx={{ fill: getIconColor(birthday?.toISOString(), formErrors.birthday) }}
                      />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box style={{ marginTop: '10px' }}>
        <InputLabel htmlFor="email" style={labelStyle}>
          {t('account.emailLabel')}
        </InputLabel>
        <TextField
          id="email"
          name="email"
          margin="normal"
          variant="outlined"
          fullWidth
          required
          value={email}
          onChange={handleEmailChange}
          error={formErrors.email !== null}
          helperText={formErrors.email ? formErrors.email : ''}
          sx={{
            height: '46px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: getBorderColor(email)
              }
            }
          }}
          inputProps={{ sx: inputPropsStyle }}
          placeholder={t('account.emailPlaceHolder') as string}
          color="info"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email sx={{ fill: getIconColor(email, formErrors.email) }} />
              </InputAdornment>
            )
          }}
        />
      </Box>
    </Box>
  );
};

export default Step1;
