import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { colors } from '../../utils/theme';
import CSS from 'csstype';
import { Email } from '@mui/icons-material';
import { isValidEmail } from '../../utils/validators';
import { useMutation } from 'react-query';
import { ForgetPassQuery } from '../../queries/account';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';

interface IProps {
  handleClose: () => void;
}

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    width: 562,
    minHeight: 330,
    marginTop: '-290px',
    borderRadius: '8px'
  }
}));

const labelStyle: CSS.Properties = {
  top: '10px',
  color: colors.primary.main,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Poppins'
};

const ResetPasswordPage: React.FunctionComponent<IProps> = ({ handleClose }: IProps) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [enableSend, setEnableSend] = useState(false);
  const { mutate, isLoading } = useMutation(ForgetPassQuery);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setEnableSend(isValidEmail(value));
    setEmail(value);
  };

  const handleSend = () => {
    if (isValidEmail(email)) {
      mutate(email);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('account.resetPassEmailSent')
        }
      });
      handleClose();
    }
  };

  const close = () => {
    if (!isLoading) {
      handleClose();
    }
  };

  return (
    <BootstrapDialog open={true} onClose={close} style={{ marginTop: 20 }}>
      <DialogTitle id="reset-pass-title">
        <Box
          sx={{
            marginTop: '24px',
            color: 'primary.main',
            fontWeight: 600,
            fontStyle: 'normal',
            fontFamily: 'Poppins',
            lineHeight: '25.2px',
            fontSize: '18px'
          }}
        >{`${t('account.forgotPass')}?`}</Box>
        <Box
          sx={{
            color: colors.grey.disabled,
            fontWeight: 400,
            fontStyle: 'normal',
            fontFamily: 'Poppins',
            lineHeight: '22.4px',
            fontSize: '15px'
          }}
        >
          {t('account.forgotPassSubtitle')}
        </Box>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: '27.33px',
            top: '27.33px',
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon style={{ width: '9.33px', height: '9.33px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="email" style={labelStyle}>
          E-MAIL
        </InputLabel>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="email"
          name="email"
          style={{
            height: '46px'
          }}
          disabled={isLoading}
          value={email}
          onChange={handleEmailChange}
          // error={formErrors.username !== null}
          // helperText={formErrors.username ? formErrors.username : ''}
          placeholder={t('account.emailPlaceHolder')}
          color="info"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            )
          }}
        />
        <Button
          className="custom-button"
          variant="contained"
          disabled={!enableSend || isLoading}
          onClick={handleSend}
          fullWidth
          sx={{
            mt: 3,
            mb: 2,
            background: 'linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              background: (t) => t.palette.common.white,
              boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
            },
            color: 'primary.main'
          }}
        >
          {t('account.send')}
        </Button>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ResetPasswordPage;
