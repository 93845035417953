import React, { useState } from 'react';

import { t } from 'i18next';
//* MUI
import { Box } from '@mui/material';
//* MUI Icons
// import { InfoOutlined } from '@mui/icons-material';
//* Components
// import InfoBox from '../../../../Common/Dialogs/InfoBox';
// import InfoPairs from '../../../../Common/Fields/InfoPairs';
import ProgressBar from '../../../../Common/ProgressBar/ProgressBar';
// import CustomIconButton from '../../../../Common/Buttons/CustomIconButton/CustomIconButton';
//* Utils
//* Queries
//* Enums
//* Models
import { IActiveBonus } from '../../../../../models/bonus';
//* Styles
import './ActiveBonusControls.scss';

interface IProps {
  progress: number;
  bonus: IActiveBonus;
}

const ActiveBonusControls = ({ progress /*, bonus  */ }: IProps) => {
  // const [infoBoxIsOpen, setInfoBoxIsOpen] = useState(false);

  return (
    <Box className="active-bonus-controls">
      <ProgressBar progress={progress} label={t('myAccount.bonusPage.wagering') as string} />
      {/* <CustomIconButton icon={<InfoOutlined />} onClick={() => setInfoBoxIsOpen(true)} /> */}
      {/* <InfoBox title={bonus.name} open={infoBoxIsOpen} onClose={() => setInfoBoxIsOpen(false)}>
        <InfoPairs items={[]} />
      </InfoBox> */}
    </Box>
  );
};

export default ActiveBonusControls;
