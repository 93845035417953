import React, { useContext } from 'react';
import { oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
import { getUserCurrencySymbol } from '../../../utils/ui';
//* MUI
import StarsIcon from '@mui/icons-material/Stars';
import { Slider } from '@mui/material';
//* Images
import moneyImg from '../../../assets/tournaments/iconMoney.svg';
import wheelImg from '../../../assets/tournaments/wheel.svg';
//* Styles
import './ExchangeSlider.scss';

interface IProps {
  type?: string;
  rate: number;
  value: number;
  max: number;
  setValue: (input: number) => void;
  className?: string;
}

const ExchangeSlider: React.FunctionComponent<IProps> = ({
  type,
  rate,
  value,
  setValue,
  max,
  className
}: IProps) => {
  const { t } = useTranslation();
  const { state } = useContext(AppContext);

  const getTitle = () => {
    switch (type) {
      case 'wheel':
        return t('shop.titles.wheel');
      case 'bonus':
        return t('shop.titles.bonus');
      default:
        return '';
    }
  };

  const getExchangeRate = () => {
    switch (type) {
      case 'wheel':
        return t('shop.exchangeLegend.wheel', { price: rate });
      case 'bonus':
        return `${getUserCurrencySymbol(state.user.data?.currency)}1 = ${rate}`;
      default:
        return '';
    }
  };

  const handleMaxClicked = () => {
    setValue(max);
  };

  const getImgSrc = () => {
    switch (type) {
      case 'wheel':
        return wheelImg;
      case 'bonus':
        return moneyImg;
      default:
        return '';
    }
  };
  return (
    <div className={`exchange-slider ${className}`}>
      <span className="exchange-slider__title">{getTitle()}</span>
      <div className="exchange-slider__slider">
        <Slider
          value={value}
          marks
          onChange={(_, newValue) => setValue(newValue as number)}
          min={0}
          max={max}
          valueLabelDisplay="auto"
          step={1}
          className="custom-slider"
        />
      </div>
      <div className="exchange-slider__values">
        <div className="exchange-slider__values-current">
          <img className="exchange-slider__values-current-img" src={getImgSrc()} />
          {value}
        </div>
        <div className="exchange-slider__values-max" onClick={handleMaxClicked}>
          {t('common.button.max')}
        </div>
      </div>
      <div className="exchange-slider__legend">
        <div className="exchange-slider__legend-rate">
          <span className="exchange-slider__legend-rate-text">{getExchangeRate()}</span>
          <StarsIcon className="exchange-slider__legend-rate-icon" />
        </div>
        <span className="exchange-slider__legend-text">{t('shop.exchangeLegend.text')}</span>
      </div>
    </div>
  );
};

ExchangeSlider.propTypes = {
  type: oneOf(['wheel', 'bonus'])
};

ExchangeSlider.defaultProps = {
  type: 'wheel'
};

export default ExchangeSlider;
