import React from 'react';
import { Transition } from '../Common/Dialogs/DialogBox';
import { Box, Dialog } from '@mui/material';
import PrimaryButton from '../Common/Buttons/PrimaryButton/PrimaryButton';
import InputField from '../Common/Fields/InputField';
import { colors } from '../../utils/theme';
import SecondaryButton from '../Common/Buttons/SecondaryButton';
import { useTranslation } from 'react-i18next';
import { Payment } from '../../enums/RequestEnums';
import { t } from 'i18next';

interface IProps {
  cardNumber: string;
  setCardNumber: (input: string) => void;
  cvv: string;
  setCvv: (input: string) => void;
  expiration: string;
  setExpiration: (input: string) => void;
  onClose: () => void;
  submit: () => void;
  cardHolder: string;
  setHolder: (input: string) => void;
  paymentProvider: string;
}

const CardForm: React.FunctionComponent<IProps> = ({
  cardNumber,
  setCardNumber,
  cvv,
  setCvv,
  onClose,
  submit,
  cardHolder,
  setHolder,
  expiration,
  setExpiration,
  paymentProvider
}: IProps) => {
  const handleChange = (input: string, type: 'cvv' | 'cardNumber' | 'expiration') => {
    if (type === 'cvv') {
      const number = Number(input);
      if (!isNaN(number) || input.length === 0) {
        setCvv(input);
      }
    } else if (type === 'expiration') {
      const formattedValue = input
        .replace(/\D/g, '') // Remove non-numeric characters
        .slice(0, 4) // Limit to MMYY format
        .replace(/(\d{2})(\d{0,2})/, '$1/$2'); // Add slash between MM and YY

      setExpiration(formattedValue);
    } else {
      const formattedValue = input
        .replace(/\s/g, '')
        .replace(/(\d{4})/g, '$1 ')
        .trim();
      setCardNumber(formattedValue);
    }
  };

  const handleFinatecoMessage = () => {
    if (paymentProvider === Payment.Finateco && isValid()) {
      return (
        <Box sx={{ color: colors.gradients.yellowRgb, mt: '10px' }}>
          {t('wallet.depositPage.finatecoDepositMessage')}
        </Box>
      );
    }
    return null;
  };

  const isValid = (): boolean => {
    const cleanCard = cardNumber.replaceAll(' ', '');
    return (
      (cvv.length === 3 || cvv.length === 4) &&
      /^\d*$/.test(cleanCard) &&
      cardHolder.length > 4 &&
      cardExpIsInFuture()
    );
  };

  const cardExpIsInFuture = (): boolean => {
    if (expiration.length !== 5) {
      return false;
    }

    const [month, year] = expiration.split('/');
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100; // Last two digits of current year
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-based

    return (
      Number(month) >= 1 &&
      Number(month) <= 12 &&
      Number(year) >= currentYear &&
      (Number(year) !== currentYear || Number(month) >= currentMonth)
    );
  };

  const handleExpHelperText = () => {
    if (expiration.length === 5 && !cardExpIsInFuture()) {
      return t('wallet.depositPage.cardForm.invalidExpirationMessage');
    }
    return null;
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          margin: 0,
          width: { xs: '343px', sm: '562px', md: '562px' },
          padding: '24px',
          display: 'flex',
          flexFlow: 'column',
          position: 'absolute',
          top: '120px',
          borderRadius: '8px',
          background: colors.background.blue
        }
      }}
      sx={{
        '.MuiDialog-container': {
          alignItems: 'center'
        }
      }}
    >
      <Box display="flex" gap="16px" flexDirection="column">
        <InputField
          id="card-number"
          value={cardNumber}
          onChange={(e) => handleChange(e.currentTarget.value, 'cardNumber')}
          label={t('wallet.depositPage.cardForm.cardNumber') as string}
        />
        <InputField
          id="holder-name"
          value={cardHolder}
          onChange={(e) => setHolder(e.currentTarget.value)}
          label={t('wallet.depositPage.cardForm.cardHolderName') as string}
        />
        <Box display="flex" gap="16px">
          <Box sx={{ width: '100%' }}>
            <InputField
              id="card-exp"
              value={expiration}
              onChange={(e) => handleChange(e.currentTarget.value, 'expiration')}
              label={t('wallet.depositPage.cardForm.expiration') as string}
              placeholder="MM/YY"
              errorMsg={handleExpHelperText()}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <InputField
              id="card-cvv"
              value={cvv}
              onChange={(e) => handleChange(e.currentTarget.value, 'cvv')}
              label="cvv"
              type="password"
            />
          </Box>
        </Box>
      </Box>
      {handleFinatecoMessage()}
      <Box sx={{ display: 'flex', gap: '16px', mt: '24px' }}>
        <SecondaryButton
          text={t('wallet.depositPage.cardForm.cancel')}
          onClick={() => {
            setCvv('');
            setCardNumber('');
            setHolder('');
            setExpiration('');
            onClose();
          }}
          additionalStyle={{ color: colors.white }}
        />
        <PrimaryButton
          text={t('wallet.depositPage.cardForm.deposit')}
          onClick={submit}
          disabled={!isValid()}
        />
      </Box>
    </Dialog>
  );
};

export default CardForm;
