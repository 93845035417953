import React from 'react';
import DialogBox from '../Common/Dialogs/DialogBox';
import { Box, CardMedia } from '@mui/material';

interface IProps {
  url: string;
  fullscreen: boolean;
}

const PaymentModal: React.FunctionComponent<IProps> = ({ url, fullscreen }: IProps) => {
  return (
    <DialogBox fullscreen={fullscreen}>
      <CardMedia id="game-iframe" sx={{ border: 'none' }}>
        <iframe src={url} height="1100" width="710"></iframe>
      </CardMedia>
      {/* <Box sx={{ height: '1110px', width: '720px' }}>
      </Box> */}
    </DialogBox>
  );
};

export default PaymentModal;
