import React, { useContext } from 'react';
import { Box, IconButton } from '@mui/material';
import { CircleLoader } from 'react-spinners';
import './AccountDetailsBalanceBox.scss';
import { getUserCurrencySymbol } from '../../../utils/ui';
import { AppContext } from '../../../AppContext';
import StarsIcon from '@mui/icons-material/Stars';

interface IProps {
  text: string;
  value: number | string;
  hideCurrency?: boolean;
  loading?: boolean;
  icon: JSX.Element;
  showStars?: boolean;
  onClick?: () => void;
}

const AccountDetailsBalanceBox: React.FunctionComponent<IProps> = ({
  text,
  value,
  hideCurrency,
  loading,
  icon,
  showStars,
  onClick
}) => {
  const { state } = useContext(AppContext);
  return (
    <Box className="account-details-balance-box-container">
      <Box className="account-details-balance-box-container__text-value-box">
        <Box className="account-details-balance-box-container__text-box">{text}</Box>
        <Box className="account-details-balance-box-container__value-stars-box">
          <Box className="account-details-balance-box-container__value-box">
            {loading ? (
              <CircleLoader loading color="white" size={35} />
            ) : (
              `${!hideCurrency ? getUserCurrencySymbol(state.user.data?.currency) : ''}${value}`
            )}
          </Box>
          {showStars && <StarsIcon className="stars-icon" />}
        </Box>
      </Box>
      <IconButton className="icon-button" onClick={onClick}>
        <Box className="account-details-balance-box-container__icon-box">{icon}</Box>
      </IconButton>
    </Box>
  );
};

export default AccountDetailsBalanceBox;
