import React from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import './WalletMenuItem.scss';

interface IProps {
  title: string;
  value: string | number;
  onClick: () => void;
  actIcon: SvgIconComponent;
  valueIcon?: React.ReactNode;
}

const WalletMenuItem: React.FunctionComponent<IProps> = ({
  title,
  value,
  onClick,
  actIcon,
  valueIcon
}: IProps) => {
  return (
    <div className="wallet-menu-item">
      <div className="wallet-menu-item__info">
        <div className="wallet-menu-item__info__title">{title}</div>
        <div className="wallet-menu-item__info__value">
          <span className="wallet-menu-item__info__value__text">{value}</span>
          {valueIcon}
        </div>
      </div>
      <div className="wallet-menu-item__button" onClick={onClick}>
        {
          <SvgIcon
            className="wallet-menu-item__button__icon"
            component={actIcon as SvgIconComponent}
          />
        }
      </div>
    </div>
  );
};

export default WalletMenuItem;
