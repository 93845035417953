import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
//* MUI
import { Box } from '@mui/material';
//* MUI icons
import {
  Start,
  SwapHoriz,
  CardGiftcard,
  AccountCircle,
  SettingsOutlined,
  AccountBalanceWallet
} from '@mui/icons-material';
//* Components
import Deposit from './Deposit/Deposit';
import MyBonuses from './MyBonuses/MyBonuses';
import WithdrawPage from './Withdraw/Withdraw';
import MenuTabs from '../Common/MenuTabs/MenuTabs';
import AccountDetailsPage from './AccountDetails/AccountDetailsPage';
import AccountTransactionsPage from './AccountTransactions/AccountTransactionsPage';
//* Enums
import { Actions } from '../../enums/ActionEnums';
//* Styles
import './MyAccountOverview.scss';
import SecurityAndSettings from './SecurityAndSettings/SecurityAndSettings';

const MyAccount: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);

  const setSelectedMenuIndex = (index: number) => {
    dispatch({
      type: Actions.SetMyAccountMenuIndex,
      payload: index
    });
  };

  const menuTabOsptions = [
    {
      title: t('myAccount.accountPage.accountDetails'),
      icon: <AccountCircle />,
      iconRotationDegrees: ''
    },
    {
      title: t('wallet.deposit'),
      icon: <AccountBalanceWallet />,
      iconRotationDegrees: ''
    },
    {
      title: t('bonus.overview'),
      icon: <CardGiftcard />,
      iconRotationDegrees: ''
    },
    {
      title: t('wallet.withdraw'),
      icon: <Start />,
      iconRotationDegrees: '270'
    },
    {
      title: t('myAccount.accountPage.transactions'),
      icon: <SwapHoriz />,
      iconRotationDegrees: ''
    },
    {
      title: t('myAccount.accountPage.securityAndSettings'),
      icon: <SettingsOutlined />,
      iconRotationDegrees: ''
    }
  ];

  const getAccountSubpage = () => {
    switch (state.myAccountMenuIndex) {
      case 0:
        return <AccountDetailsPage />;
      case 1:
        return <Deposit />;
      case 2:
        return <MyBonuses />;
      case 3:
        return <WithdrawPage />;
      case 4:
        return <AccountTransactionsPage />;
      case 5:
        return <SecurityAndSettings />;
      default:
        return <AccountDetailsPage />;
    }
  };

  return (
    <Box className="account">
      <Box className="account-menu">
        <MenuTabs
          type="vertical"
          options={menuTabOsptions}
          handleSelection={setSelectedMenuIndex}
          selectedIndex={state.myAccountMenuIndex ?? 0}
        />
      </Box>
      <Box className="account-subpage">{getAccountSubpage()}</Box>
    </Box>
  );
};

export default MyAccount;
