import React from 'react';
//* MUI
import { Box } from '@mui/material';
//* Styles
import './ProgressBar.scss';

interface IProps {
  label?: string;
  progress: number;
}

const ProgressBar = ({ label, progress }: IProps) => {
  return (
    <Box className="progress-bar">
      <Box className="progress-bar__label">
        <Box className="progress-bar__label-text">{label}</Box>
        <Box className="progress-bar__label-percentage">{progress}%</Box>
      </Box>
      <Box className="progress-bar__bar">
        <Box className="progress-bar__bar-progress" sx={{ width: `${progress}%` }} />
      </Box>
    </Box>
  );
};

export default ProgressBar;
