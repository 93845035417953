import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Divider, Grid } from '@mui/material';
import CSS from 'csstype';
import { Link } from 'react-router-dom';
import { colors } from '../../utils/theme';
import { hideLayout } from '../../utils/ui';
import { PublicRoutes, RouteNames } from '../../enums/RouteEnums';
import ExternalLogo from '../Common/ExternalLogo';
import { useTranslation } from 'react-i18next';

const Copyright = ({ domain }: { domain: string }) => {
  return (
    <Typography
      variant="body2"
      sx={{
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '12px',
        marginTop: '40px'
      }}
    >
      {new Date().getFullYear()}
      {' © '}
      {`${domain} `}
      {'All Rights Reserved'}
    </Typography>
  );
};

const margin = '16px';
const headerStyle: CSS.Properties = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '22px',
  color: 'white',
  marginBottom: margin
};

const linkStyle: CSS.Properties = {
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '19.6px',
  textDecoration: 'none',
  color: colors.grey.disabled
};

export default function Footer() {
  const hide = hideLayout();
  const { t, i18n } = useTranslation();
  const isClone = process.env.ENVIRONMENT === 'licence';
  const domain = isClone ? 'SlotsSharks.com' : 'BubblesBet.com';

  return hide ? null : (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) => theme.palette.primary.dark,
        color: (theme) => theme.palette.grey[300]
      }}
    >
      <Container>
        <Grid container style={{ marginTop: 80 }}>
          <Grid item xs={6} sm={6} md={2}>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={headerStyle}>{t('navigation.legal')}</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: margin }}>
                <Link to={PublicRoutes.TermsAndCoditions} style={linkStyle}>
                  {t(`footer.${RouteNames.TermsAndCoditions}`)}
                </Link>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: margin }}>
                <Link to={PublicRoutes.PrivacyPolicy} style={linkStyle}>
                  {t(`footer.${RouteNames.PrivacyPolicy}`)}
                </Link>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: margin }}>
                <Link to={PublicRoutes.BonusTerms} style={linkStyle}>
                  {t(`footer.${RouteNames.BonusTerms}`)}
                </Link>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: margin }}>
                <Link to={PublicRoutes.ResponsibleGaming} style={linkStyle}>
                  {t(`footer.${RouteNames.ResponsibleGaming}`)}
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2}
            sx={{
              paddingLeft: {
                xs: '16px',
                sm: '16px',
                md: i18n.language === 'de' ? '63px' : '16px'
              }
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography style={headerStyle}>{t('navigation.info')}</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: margin }}>
                <Link to={PublicRoutes.AboutUs} style={linkStyle}>
                  {t(`footer.${RouteNames.AboutUs}`)}
                </Link>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: margin }}>
                <Link to={PublicRoutes.ContactUs} style={linkStyle}>
                  {t(`footer.${RouteNames.ContactUs}.title`)}
                </Link>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: margin }}>
                <Link to={isClone ? PublicRoutes.KYC : PublicRoutes.HelpCenter} style={linkStyle}>
                  {isClone ? t(`footer.${RouteNames.KYC}`) : t(`footer.${RouteNames.HelpCenter}`)}
                </Link>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: margin }}>
                <Link to={PublicRoutes.Affiliates} style={linkStyle}>
                  {t(`footer.${RouteNames.Affiliates}`)}
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={headerStyle}>{t('navigation.licenses')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={linkStyle}>{t('navigation.companyInfo')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={1}
            sx={{
              mt: {
                xs: '40px',
                sm: '40px',
                md: '0px'
              }
            }}
          >
            <Box
              style={{
                border: '2px solid #2575FC',
                borderRadius: '50px',
                filter: 'drop-shadow(0px 0px 5px rgba(37, 117, 252, 0.5))',
                textAlign: 'center',
                fontWeight: 600,
                fontSize: '18px',
                width: '48px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white'
              }}
            >
              18+
            </Box>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 40 }}>
          <Grid item xs={12}>
            <Divider sx={{ background: (theme) => theme.palette.grey[300] }} />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 32, marginBottom: 32 }}>
            <Grid container>
              <Grid item xs={3} sm={3} md={1.5}>
                <ExternalLogo name="visa" />
              </Grid>
              <Grid item xs={3} sm={3} md={1.5}>
                <ExternalLogo name="paysafe" />
              </Grid>
              <Grid item xs={3} sm={3} md={1.5}>
                <ExternalLogo name="jcb" />
              </Grid>
              <Grid item xs={3} sm={3} md={1.5}>
                <ExternalLogo name="skrill" />
              </Grid>
              <Grid item xs={3} sm={3} md={1.5}>
                <ExternalLogo name="visa" />
              </Grid>
              <Grid item xs={3} sm={3} md={1.5}>
                <ExternalLogo name="paysafe" />
              </Grid>
              <Grid item xs={3} sm={3} md={1.5}>
                <ExternalLogo name="jcb" />
              </Grid>
              <Grid item xs={3} sm={3} md={1.5}>
                <ExternalLogo name="skrill" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ background: (theme) => theme.palette.grey[300] }} />
          </Grid>
        </Grid>
        <Copyright domain={domain} />
      </Container>
    </Box>
  );
}
