import { Actions } from '../enums/ActionEnums';
import { ActionType, PopupTypeExtended } from '../models/appcontext';

export const popupReducer = (state: PopupTypeExtended, action: ActionType): PopupTypeExtended => {
  switch (action.type) {
    case Actions.ShowPopup:
      return {
        open: true,
        direction: action.payload.direction,
        state: action.payload.state,
        isCongrat: action.payload.isCongrat ?? false,
        redirectUrl: action.payload.redirectUrl,
        buttonText: action.payload.buttonText
      };
    case Actions.HidePopup:
      return { ...state, open: false, direction: null, state: null };
    default:
      return state;
  }
};
