import React from 'react';
import { Box, SxProps } from '@mui/material';
import { getBadge } from '../../utils/ui';
//* Styles
import './Badge.scss';

interface IProps {
  rank: number;
  sx?: SxProps;
  name?: string;
  showName?: boolean;
  withBackground?: boolean;
}

const Badge: React.FunctionComponent<IProps> = ({
  sx,
  rank,
  name = 'Guest',
  showName = true,
  withBackground = false
}: IProps) => {
  return (
    <Box className={`badge ${withBackground ? 'badge--with-background' : ''}`}>
      <Box
        id="badge-img"
        component="img"
        src={getBadge(rank)}
        sx={{
          width: '40px',
          height: '40px',
          ...(sx && { ...sx })
        }}
      />
      {showName && <Box id="badge-name">{name}</Box>}
    </Box>
  );
};

export default Badge;
