import React from 'react';
//* Mui
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
//* Mui Icons
import { Check } from '@mui/icons-material';
//* Models
import { ITransactionOption } from '../models/wallet';
//* Utils
import { colors } from './theme';

export const radius = '8px';
export const fieldColor = '#282D74';

export const accordionProps: SxProps = {
  color: 'white',
  backgroundColor: 'transparent'
};

export const summaryTitleProps: SxProps = {
  display: 'flex',
  marginTop: '16px',
  alignItems: 'center'
};

export const dropDownIconProps: SxProps = {
  width: '15px',
  height: '15px',
  color: 'common.white'
};

export const containerProps: SxProps = {
  borderRadius: radius,
  background: colors.background.blue,
  padding: { xs: '24px', md: '56px' },
  paddingTop: { xs: '8px', md: '40px' }
};

export const titleLabelProps: SxProps = {
  fontWeight: 600,
  display: 'flex',
  fontSize: '18px',
  lineHeight: '25px',
  alignItems: 'center',
  color: 'common.white',
  justifyContent: 'center'
};

export const accordionDetailsProps: SxProps = {
  borderTop: 'none',
  border: '1px solid white',
  borderBottomLeftRadius: radius,
  borderBottomRightRadius: radius,
  padding: { xs: '11px', md: '11px 23px 23px 23px' }
};

export const transactionTypeOptions: ITransactionOption[] = [
  { id: 1, label: 'wallet.historyPage.transactionTypeOptions.all' },
  { id: 2, label: 'wallet.historyPage.transactionTypeOptions.deposit' },
  { id: 3, label: 'wallet.historyPage.transactionTypeOptions.withdraw' }
];

export const getCheckIcon = () => (
  <Box
    sx={{
      borderRadius: '100px',
      backgroundColor: colors.info.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '32px',
      height: '32px',
      marginRight: '16px'
    }}
  >
    <Check sx={{ color: colors.white, width: '30px', height: '30px' }} />
  </Box>
);

export const getStepStyles = (step: number, expanded: number) => {
  const isActiveStep = step === expanded;

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    lineHeight: '24px',
    minWidth: '32px',
    height: '32px',
    backgroundColor: isActiveStep ? 'common.white' : 'transparent',
    color: isActiveStep ? colors.info.main : colors.white,
    padding: '4px',
    borderRadius: '100px',
    marginRight: '16px'
  } as SxProps;
};

export const getAccordionSummaryProps = (step: number, expanded: number) => {
  return {
    p: { xs: '11px', md: '11px 23px' },
    m: 0,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    },
    border: '1px solid white',
    borderBottom: expanded === step ? 'none' : '1px solid white',
    borderTopLeftRadius: radius,
    borderTopRightRadius: radius,
    borderBottomLeftRadius: expanded === step ? '0px' : radius,
    borderBottomRightRadius: expanded === step ? '0px' : radius,
    marginBottom: '0px',
    marginTop: '16px',
    '& .MuiAccordionSummary-contentGutters': {
      m: 0
    },
    '& .Mui-expanded': {
      m: '0 !important'
    }
  } as SxProps;
};
