import axios from 'axios';
import { AppConsts } from '../enums/AppConsts';
import { Exchange } from '../models/shop';

const baseUrl = process.env.BASE_SERVER_ADDRESS;
const getToken = () => localStorage.getItem(AppConsts.AccessToken);
const getHeaders = () => ({ headers: { Authorization: `Bearer ${getToken()}` } });

export const GetFreeSpinGames = async () => {
  return await axios.get(`${baseUrl}/shop/FreeSpins`, getHeaders());
};

export const GetMasterLevels = async () => {
  return await axios.get(`${baseUrl}/Challenge/AllBadges`);
};

export const PostExchange = async (model: Exchange) => {
  return await axios.post(`${baseUrl}/shop/Exchange`, model, getHeaders());
};
