import React from 'react';
import CSS from 'csstype';
import { CardGiftcard, Support, Storefront, EmojiEvents, Flag } from '@mui/icons-material';

interface IProps {
  sx: CSS.Properties;
}

export const GradientSupportIcon: React.FunctionComponent<IProps> = ({ sx }: IProps) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
        <stop offset={0} stopColor="#E3AB00" />
        <stop offset={1} stopColor="#FFE539" />
      </linearGradient>
    </svg>
    <Support sx={{ ...sx, fill: 'url(#linearColors)' }} />
  </>
);

export const GradientStorefrontIcon: React.FunctionComponent<IProps> = ({ sx }: IProps) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
        <stop offset={0} stopColor="#E3AB00" />
        <stop offset={1} stopColor="#FFE539" />
      </linearGradient>
    </svg>
    <Storefront sx={{ ...sx, fill: 'url(#linearColors)' }} />
  </>
);

export const GradientCardGiftcardIcon: React.FunctionComponent<IProps> = ({ sx }: IProps) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
        <stop offset={0} stopColor="#E3AB00" />
        <stop offset={1} stopColor="#FFE539" />
      </linearGradient>
    </svg>
    <CardGiftcard sx={{ ...sx, fill: 'url(#linearColors)' }} />
  </>
);

export const GradientCupIcon: React.FunctionComponent<IProps> = ({ sx }: IProps) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
        <stop offset={0} stopColor="#E3AB00" />
        <stop offset={1} stopColor="#FFE539" />
      </linearGradient>
    </svg>
    <EmojiEvents sx={{ ...sx, fill: 'url(#linearColors)' }} />
  </>
);

export const GradientFlagIcon: React.FunctionComponent<IProps> = ({ sx }: IProps) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
        <stop offset={0} stopColor="#E3AB00" />
        <stop offset={1} stopColor="#FFE539" />
      </linearGradient>
    </svg>
    <Flag sx={{ ...sx, fill: 'url(#linearColors)' }} />
  </>
);
