import React /* useState */ from 'react';
import { t } from 'i18next';
//* MUI
import { Box, Button } from '@mui/material';
//* MUI Icons
// import { InfoOutlined } from '@mui/icons-material';
//* Components
// import InfoBox from '../../../Common/Dialogs/InfoBox';
// import InfoPairs from '../../../Common/Fields/InfoPairs';
// import CustomIconButton from '../../../Common/Buttons/CustomIconButton/CustomIconButton';
//* Utils
//* Queries
//* Enums
//* Models
import { IBonus } from '../../../../../models/bonus';
//* Styles
import './SuggestedBonusControls.scss';

interface IProps {
  bonus: IBonus;
  disableClaimBonus: boolean;
  handleClaimBonus: (bonus: IBonus) => void;
}

const SuggestedBonusControls = ({ bonus, disableClaimBonus, handleClaimBonus }: IProps) => {
  // const [infoBoxIsOpen, setInfoBoxIsOpen] = useState(false);

  return (
    <Box className="suggested-bonus-controls">
      <Button
        variant="contained"
        disabled={disableClaimBonus}
        onClick={() => handleClaimBonus(bonus)}
        className="suggested-bonus-controls__button"
      >
        {t('promotionsPage.claim')}
      </Button>
      {/* <CustomIconButton icon={<InfoOutlined />} onClick={() => setInfoBoxIsOpen(true)} />
      <InfoBox title={bonus.code} open={infoBoxIsOpen} onClose={() => setInfoBoxIsOpen(false)}>
        <InfoPairs items={[]} />
      </InfoBox> */}
    </Box>
  );
};

export default SuggestedBonusControls;
