import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
//* MUI
import { Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//* Components
import GameCard from '../../../Games/GameCard';
//* Models
import { UserModel } from '../../../../models/account';
import { IGameDto, IGameKey } from '../../../../models/games';
//* Enums
import { Query } from '../../../../enums/RequestEnums';
import { Actions } from '../../../../enums/ActionEnums';
import { AppConsts } from '../../../../enums/AppConsts';
import { PublicRoutes } from '../../../../enums/RouteEnums';
//* Queries
import {
  AllGamesQuery,
  AddFavoriteGameQuery,
  RemoveFavoriteGameQuery
} from '../../../../queries/games';
//* Utils
import { decodeToken, handleError } from '../../../../utils/ui';
import { AppContext } from '../../../../AppContext';
import { isUserLoggedIn } from '../../../../utils/validators';
//* Styles
import './TournamentCardGames.scss';
import CustomIconButton from '../../../Common/Buttons/CustomIconButton/CustomIconButton';

interface IProps {
  availableGameIds: number[] | undefined;
}

const TournamentCardGames = ({ availableGameIds }: IProps) => {
  const scroll = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { state, dispatch } = useContext(AppContext);

  useQuery(Query.AllGames, AllGamesQuery, {
    onSuccess: ({ data }) => {
      const currentGames = data as IGameDto[];

      dispatch({
        type: Actions.SetGames,
        payload: currentGames
      });
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const updateUserInState = (token: string) => {
    const decoded = decodeToken(token);
    dispatch({
      type: Actions.SetUser,
      payload: {
        data: {
          ...(state.user.data as UserModel),
          favGames:
            decoded.groupsid && decoded.groupsid.length
              ? decoded.groupsid.map((g) => parseInt(g))
              : []
        },
        token: token
      }
    });
    localStorage.setItem(AppConsts.AccessToken, token);
  };

  const slide = (shift: number) => {
    if (scroll.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      scroll.current.scrollLeft += shift;
    }
  };

  const startGame = (game: IGameDto) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const endpoint = `/games/${game.gameId}-${game.name.replaceAll(' ', '')}`;
    if (isUserLoggedIn()) {
      navigate(endpoint, {
        state: { path: PublicRoutes.Tournaments }
      });
    } else {
      dispatch({
        type: Actions.ShowLoginPopup,
        payload: {
          direction: endpoint,
          state: { path: PublicRoutes.Tournaments }
        }
      });
    }
  };

  const addToFavGameQuery = useMutation(AddFavoriteGameQuery, {
    onSuccess: ({ data }) => {
      const token = data.accessToken as string;
      updateUserInState(token);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.data} ${t('gamesPage.addToFavoritesPayloadText')}`
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('AddFavoriteGameQuery');
    }
  });

  const removeFromFavGameQuery = useMutation(RemoveFavoriteGameQuery, {
    onSuccess: ({ data }) => {
      const token = data.accessToken as string;
      updateUserInState(token);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.data} ${t('gamesPage.removeFromFavoritesPayloadText')}`
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('RemoveFavoriteGameQuery');
    }
  });

  const handleFavoriteClick = (id: number, gameId: string, name: string) => {
    const game = { gameId, name } as IGameKey;
    if (state.user.data?.favGames.includes(id)) {
      removeFromFavGameQuery.mutate(game);
    } else {
      addToFavGameQuery.mutate(game);
    }
  };

  return (
    <Box className="tournament-card__games">
      <Box className="tournament-card__games-title">{t('tournaments.card.gamesForTournament')}</Box>
      <Box className="test">
        <Box className="tournament-card__games-list__controls">
          <CustomIconButton
            icon={<ChevronLeftIcon />}
            onClick={() => slide(-120)}
            additionalClassName="tournament-card__games-list__controls-left"
          />
          <CustomIconButton
            icon={<ChevronRightIcon />}
            onClick={() => slide(120)}
            additionalClassName="tournament-card__games-list__controls-right"
          />
        </Box>
        <Box className="tournament-card__games-list" ref={scroll}>
          {state.games.length > 0 &&
            availableGameIds?.map((g, i) => {
              const game = state.games.find((gm) => gm.id === g) as IGameDto;

              return (
                <Box
                  key={i}
                  sx={{
                    width: { xs: '168px', md: '196px' },
                    height: { xs: '127px', md: '156px' },
                    flexShrink: 0
                  }}
                >
                  <GameCard
                    data={game}
                    setGame={startGame}
                    isSpecial={false}
                    isFavorite={state.user.data?.favGames.includes(game.id) ?? false}
                    handleFavorite={() => handleFavoriteClick(game.id, game.gameId, game.name)}
                    sx={{ height: '100%' }}
                  />
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default TournamentCardGames;
