import { Actions } from '../enums/ActionEnums';
import { ActionType, ConfirmationType } from '../models/appcontext';

export const confirmationReducer = (
  state: ConfirmationType,
  action: ActionType
): ConfirmationType => {
  switch (action.type) {
    case Actions.ShowConfirmation:
      return {
        open: true,
        ...action.payload
      };
    case Actions.HideConfirmation:
      return { open: false, title: null, text: null };
    default:
      return state;
  }
};
