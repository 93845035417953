import { useTranslation } from 'react-i18next';
//* Assets
import visaLogo from '../assets/payments/visa.svg';
import mastercardLogo from '../assets/payments/mastercard-new.png';
import { IMyAccountRowElement, IPlayerCard } from '../models/wallet';
import { IBonus } from '../models/bonus';

export const resizePanNumber = (panNumber: string) => {
  return panNumber.substring(8, 16);
};

export const getCardDetails = (cardNumber: string) => {
  const card = { type: '', logo: '' };

  if (cardNumber.startsWith('4')) {
    card.type = 'visa';
    card.logo = visaLogo;
  }

  if (cardNumber.startsWith('5')) {
    card.type = 'mastercard';
    card.logo = mastercardLogo;
  }

  return card;
};

export const getDepositMethodsData = (cards: IPlayerCard[]) => {
  const { t } = useTranslation();
  const depositMethodsData: IMyAccountRowElement[] = [];

  cards.map((card) => {
    const cardDetails = getCardDetails(card.panNumber);
    const cardNumber = resizePanNumber(card.panNumber);

    depositMethodsData.push({
      id: card.id,
      logo: cardDetails.logo,
      title: `${cardDetails.type} ${t('wallet.depositPage.cardForm.card')}`,
      subtitle: `${t('wallet.depositPage.ending')} ${cardNumber}`,
      customClass: `element-row__details-logo--${cardDetails.type}`
    });
  });

  return depositMethodsData;
};

export const getCouponsData = (availableBonuses: IBonus[]) => {
  const { i18n } = useTranslation();
  const couponsData: IMyAccountRowElement[] = [];

  availableBonuses.map((bonus) => {
    const subtitle =
      bonus.promoDetails.find(
        (details) => details.language.toUpperCase() === i18n.language.toUpperCase()
      )?.description ?? bonus.promoDetails[0]?.description;

    couponsData.push({
      id: bonus.id,
      title: bonus.code,
      subtitle: subtitle,
      disableExtraControls: true
    });
  });

  return couponsData;
};
