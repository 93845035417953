import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box } from '@mui/material';
import ShopLevelMeter from './ShopLevelMeter/ShopLevelMeter';
//* Icons
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
//* Models
import { ExchangeLevel } from '../../../../models/shop';
//* Styles
import './ShopInfoBoxContent.scss';

interface IProps {
  levels: ExchangeLevel[];
}

const ShopInfoBoxContent: React.FunctionComponent<IProps> = ({ levels }) => {
  const { t } = useTranslation();
  const [extraInfoIsExpanded, setExtraInfoExpanded] = useState(false);

  return (
    <Box className="shop-page__info-box">
      <Box className="shop-page__info-box__title">{t('challenges.howItWorks')}?</Box>
      <Box
        className={`shop-page__info-box__text--clamped ${
          extraInfoIsExpanded ? 'shop-page__info-box__text--is-expanded' : ''
        }`}
      >
        {t('shop.howItWorksSection.description.howItWorks')}
      </Box>
      <button
        className="shop-page__info-box__read-more-button"
        onClick={() => setExtraInfoExpanded(!extraInfoIsExpanded)}
      >
        {extraInfoIsExpanded
          ? t('shop.howItWorksSection.description.readLess')
          : t('shop.howItWorksSection.description.readMore')}
      </button>
      <Box className="shop-page__info-box__title">{t('challenges.levelMeter')}</Box>
      <Box className={'shop-page__info-box__text'}>
        {t('shop.howItWorksSection.description.levelMeter')}
      </Box>
      <ShopLevelMeter levels={levels} />
      <Box className="shop-page__info-box__title">
        {t('wheel.bonusTypes.wheelSpins')} {t('common.and')} {t('wheel.bonusTypes.bonusMoney')}
      </Box>
      <Box className={'shop-page__info-box__text'}>
        {t('shop.howItWorksSection.description.wheelSpinsBonusMoney')}
      </Box>
      <Box className={'shop-page__info-box__slider'}>
        <Box className={'shop-page__info-box__slider-icon'}>
          <TrendingFlatIcon />
        </Box>
      </Box>
      <Box className="shop-page__info-box__title">{t('wheel.bonusTypes.freeSpins')}</Box>
      <Box className={'shop-page__info-box__text'}>
        {t('shop.howItWorksSection.description.freeSpins')}
      </Box>
      <Box className={'shop-page__info-box__free-spins'}>
        <Box className={'shop-page__info-box__free-spins-box--wide'}>
          {t('shop.titles.selectGame')}
        </Box>
        <Box className={'shop-page__info-box__free-spins-separator'}>
          <TrendingFlatIcon />
        </Box>
        <Box className={'shop-page__info-box__free-spins-box'}>
          {t('shop.titles.selectQuantity')}
        </Box>
      </Box>
    </Box>
  );
};

export default ShopInfoBoxContent;
