import React from 'react';
import { Button, ButtonGroup, SvgIcon } from '@mui/material';
import { GroupButtonOption } from '../../../models/buttons';
import { SvgIconComponent } from '@mui/icons-material';
import './ButtonTabs.scss';

interface IProps {
  selected: number;
  handleChange: (index: number) => void;
  options: GroupButtonOption[];
}

const ButtonTabs: React.FunctionComponent<IProps> = ({
  selected,
  handleChange,
  options
}: IProps) => {
  return (
    <ButtonGroup className="group-container" variant="filledTonal">
      {options.map((item, index) => (
        <Button
          key={`${item}-${index}`}
          className={index === selected ? 'group-button--select' : 'group-button'}
          onClick={() => handleChange(index)}
        >
          {item.icon && <SvgIcon component={item.icon as SvgIconComponent} sx={{ mr: '6px' }} />}
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ButtonTabs;
