import React from 'react';
import { Button } from '@mui/material';
import { SxProps } from '@mui/system';

interface IProps {
  onClick: () => void;
  text: string;
  additionalStyle?: SxProps;
  disabled?: boolean;
}

const SecondaryButton: React.FunctionComponent<IProps> = ({
  onClick,
  text,
  additionalStyle,
  disabled
}: IProps) => {
  return (
    <Button
      fullWidth
      variant="outlined"
      disabled={disabled ?? false}
      sx={{
        color: 'primary.main',
        boxShadow: '0px 0px 5px rgba(37, 117, 252, 0.5)',
        height: '46px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '22px',
        '&:hover': {
          background: (t) => t.palette.common.white,
          boxShadow: '0px 0px 5px rgba(37, 117, 252, 0.5)',
          border: (t) => `2px solid ${t.palette.common.white}`,
          color: 'primary.main'
        },
        border: (t) => `2px solid ${t.palette.info.main}`,
        ...additionalStyle
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default SecondaryButton;
