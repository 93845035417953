import React, { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
//* MUI
import { Box } from '@mui/material';
//* MUI Icons
import { ArrowForwardIos, Done } from '@mui/icons-material';
//* Components
import CustomIconButton from '../../../Common/Buttons/CustomIconButton/CustomIconButton';
//* Models
import { IPlayerFreeSpins } from '../../../../models/shop';
//* Utils
import { getUserCurrencySymbol } from '../../../../utils/ui';
//* Styles
import './FreeSpins.scss';

interface IProps {
  freeSpins: IPlayerFreeSpins[];
  handleSetFreeSpinsData: (enrichedFreeSpins: JSX.Element[]) => void;
  setSelectedBonusesContent: (selectedBonusesContent: number) => void;
}

const FreeSpins = ({ freeSpins, handleSetFreeSpinsData, setSelectedBonusesContent }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  const userCurrency = getUserCurrencySymbol(state.user.data?.currency);

  const handleGameLinkClicked = (gameData: IPlayerFreeSpins) => {
    const game = state.games.find((g) => g.id === gameData.gameId);

    if (game) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      navigate(`/games/${game.gameId}-${game.name.replaceAll(' ', '')}`);
    }
  };

  const renderFreeSpins = () => {
    if (freeSpins.length > 0) {
      const freeSpinsArray = freeSpins.map((fs, index) => (
        <Box
          key={`${fs.gameName}-${index}`}
          className={`free-spins__tile ${fs.spinsLeft === 0 ? 'free-spins__tile--disabled' : ''}`}
        >
          <Box className="free-spins__tile-info" onClick={() => handleGameLinkClicked(fs)}>
            <Box
              className="free-spins__tile-info__image"
              component="img"
              src={`${
                state.games.find((g) => g.id === fs.gameId)?.thumbnailSmall
              }?${new Date().getTime()}`}
            />
            <Box className="free-spins__tile-info__text-container">
              <Box className="free-spins__tile-info__text-container__row--bold">{fs.gameName}</Box>
              <Box className="free-spins__tile-info__text-container__row">
                {fs.spinsLeft}/{fs.initialSpinsAmount}{' '}
                <Box className="free-spins__tile-info__text-container__row--capitalized">
                  {t('wheel.spinsLeft')}
                </Box>
              </Box>
              <Box>
                {t('myAccount.historyPage.betHeader')}: {userCurrency}
                {fs.betValue.toFixed(2)}
              </Box>
            </Box>
          </Box>
          {fs.spinsLeft === 0 ? (
            <CustomIconButton disabled color="gold" icon={<Done />} size="small" />
          ) : (
            <ArrowForwardIos />
          )}
        </Box>
      ));

      handleSetFreeSpinsData(freeSpinsArray);

      return freeSpinsArray.slice(0, 6);
    }
  };

  return (
    <>
      <Box className="free-spins">{renderFreeSpins()}</Box>
      <button className="bonuses__see-all" onClick={() => setSelectedBonusesContent(2)}>
        {t('myAccount.bonusPage.seeAll')}
      </button>
    </>
  );
};

export default FreeSpins;
