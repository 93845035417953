import React from 'react';
import { Modal } from '@mui/material';
import { Box } from '@mui/system';

interface IProps {
  loading: boolean;
  additionalStyle?: React.CSSProperties;
  transperant?: boolean;
}
const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  '&:focus': {
    outline: 'none'
  }
};

const Loader: React.FunctionComponent<IProps> = ({
  loading,
  additionalStyle,
  transperant = true
}: IProps) => {
  return (
    <Modal open={loading} sx={{ background: transperant ? '' : (t) => t.palette.primary.main }}>
      <Box sx={{ ...style, ...additionalStyle }}>
        <Box id="load" sx={{ display: 'flex', gap: '10px' }}>
          <Box
            id="diamonds-loader"
            sx={{
              backgroundImage: `url(${require('../../assets/loader/diamonds.svg')})`,
              color: 'common.white',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '30px',
              height: '40px'
            }}
          />
          <Box
            id="clubs-loader"
            sx={{
              backgroundImage: `url(${require('../../assets/loader/clubs.svg')})`,
              color: 'common.white',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '42px',
              height: '45px'
            }}
          />
          <Box
            id="hearts-loader"
            sx={{
              backgroundImage: `url(${require('../../assets/loader/hearts.svg')})`,
              color: 'common.white',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '42px',
              height: '40px'
            }}
          />
          <Box
            id="spades-loader"
            sx={{
              backgroundImage: `url(${require('../../assets/loader/spades.svg')})`,
              color: 'common.white',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '42px',
              height: '40px'
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default Loader;
