import React from 'react';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box, InputAdornment, TextField } from '@mui/material';
//* MUI icons
import { QrCode } from '@mui/icons-material';
//* Components
import ReceivingDetailsWarningMessage from '../ReceivingDetailsWarningMessage/ReceivingDetailsWarningMessage';
//* Enums
//* Styles
import './ReceivingDetailsBitcoin.scss';

interface IProps {
  error: string | null;
  bitcoinWallet: string;
  handleBitcoinWalletChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ReceivingDetailsBitcoin: React.FunctionComponent<IProps> = ({
  error,
  bitcoinWallet,
  handleBitcoinWalletChange
}) => {
  const { t } = useTranslation();

  const hasError = !!error && bitcoinWallet.length > 0;

  return (
    <Box className="receiving-details__bitcoin">
      <TextField
        fullWidth
        type="text"
        color="info"
        margin="none"
        id="otherAmount"
        error={hasError}
        name="otherAmount"
        value={bitcoinWallet}
        placeholder={t('wallet.withdrawPage.cryptoWalletAddress') as string}
        onChange={(event) =>
          handleBitcoinWalletChange(event as React.ChangeEvent<HTMLInputElement>)
        }
        helperText={
          hasError && (
            <Box className="receiving-details__bitcoin-helper">
              <Box
                className={`receiving-details__bitcoin-helper-text ${
                  hasError ? 'receiving-details__bitcoin-helper-text--error' : ''
                }`}
              >
                {error}
              </Box>
            </Box>
          )
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" className="receiving-details__bitcoin-adornment">
              <QrCode />
            </InputAdornment>
          )
        }}
      />
      <ReceivingDetailsWarningMessage message={t('wallet.withdrawPage.bitcoinWarning')} />
    </Box>
  );
};

export default ReceivingDetailsBitcoin;
