import { Actions } from '../enums/ActionEnums';
import { ActionType, PopupType } from '../models/appcontext';

export const loginPopupReducer = (state: PopupType, action: ActionType): PopupType => {
  switch (action.type) {
    case Actions.ShowLoginPopup:
      return {
        open: true,
        direction: action.payload.direction,
        state: action.payload.state
      };
    case Actions.HideLoginPopup:
      return { ...state, open: false, direction: null, state: null };
    default:
      return state;
  }
};
