import { Actions } from '../enums/ActionEnums';
import { ActionType } from '../models/appcontext';

export const myAccountReducer = (state: number, action: ActionType): number => {
  switch (action.type) {
    case Actions.SetMyAccountMenuIndex:
      return action.payload;
    default:
      return state;
  }
};
