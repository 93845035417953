import React, { useState } from 'react';
import { Box } from '@mui/material';
import DepositPage from '../Profile/Deposit/Deposit';
import HistoryPage from './HistoryPage';
import { useTranslation } from 'react-i18next';
import BalanceOverview from '../Common/BalanceOverview';
import ButtonTabs from '../Common/Buttons/ButtonTabs';
import WithdrawPage from '../Profile/Withdraw/Withdraw';

const WalletOverview: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  return (
    <Box sx={{ py: '112px' }}>
      <Box sx={{ width: { xs: '343px', md: '875px' }, margin: 'auto', textAlign: 'center' }}>
        <Box sx={{ fontSize: '64px', lineHeight: '90px', fontWeight: 700 }}>
          {t('wallet.myWallet')}
        </Box>
        <Box sx={{ marginTop: '32px' }}>
          <ButtonTabs
            selected={page}
            handleChange={setPage}
            options={[
              { label: t('wallet.deposit') },
              { label: t('wallet.withdraw') },
              { label: t('wallet.history') }
            ]}
          />
        </Box>
        <BalanceOverview sx={{ marginTop: '48px' }} />
        <Box sx={{ marginTop: '48px' }}>
          {page === 0 && <DepositPage />}
          {page === 1 && <WithdrawPage />}
          {page === 2 && <HistoryPage />}
        </Box>
      </Box>
    </Box>
  );
};

export default WalletOverview;
