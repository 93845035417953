import { Box, useMediaQuery } from '@mui/material';
import React, { useContext, useState } from 'react';
import './AccountTransactionsPage.scss';
import CustomIconButton from '../../Common/Buttons/CustomIconButton/CustomIconButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AutocompleteField from '../../Common/Fields/AutocompleteField';
import { useTranslation } from 'react-i18next';
import DateField from '../../Common/Fields/DateField';
import { ITransaction } from '../../../models/wallet';
import { GetTransactions } from '../../../queries/wallet';
import { handleError } from '../../../utils/ui';
import { useQuery, useQueryClient } from 'react-query';
import moment, { Moment } from 'moment';
import { AppContext } from '../../../AppContext';
import AccountTransactionsTable from './AccountTransactionsTable';
import Loader from '../../Common/Loader';
import { Query } from '../../../enums/RequestEnums';

interface ITransactionOption {
  id: number;
  label: string;
}

const borderColor = '1px solid rgba(255, 255, 255, 0.15) !important';

const AccountTransactionsPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [transactionOption, setTransactionOption] = useState<ITransactionOption | null>(null);
  const [periodFrom, setPeriodFrom] = useState<moment.Moment | null>(null);
  const [periodTo, setPeriodTo] = useState<moment.Moment | null>(null);

  const transactionsQuery = useQuery({
    queryKey: Query.Transactions,
    queryFn: GetTransactions,
    onSuccess: ({ data }) => {
      const dbTransactions = data as ITransaction[];
      setTransactions(dbTransactions);
      setFilteredData(dbTransactions);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('GetTransactions');
    }
  });
  const [transactions, setTransactions] = useState<ITransaction[] | []>(
    transactionsQuery.data?.data || []
  );
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [filteredData, setFilteredData] = useState<ITransaction[] | []>(
    transactionsQuery.data?.data || []
  );

  const isMobile = useMediaQuery('(max-width:575px)');

  const handleChange = (_: unknown, value: ITransactionOption | null) => {
    setTransactionOption(value);
    applyFilters(value, periodFrom, periodTo);
  };

  const handleClick = () => {
    setShowFilters((prev) => !prev);
  };

  const applyFilters = (
    type: ITransactionOption | null,
    from: moment.Moment | null,
    to: moment.Moment | null
  ) => {
    let result =
      type === null || type.id === 1
        ? transactions
        : type.id === 2
        ? transactions.filter((t) => t.type.toLocaleLowerCase() === 'deposit')
        : transactions.filter((t) => t.type.toLocaleLowerCase() === 'withdrawal');
    if (from && from.isValid()) {
      result = result.filter((r) => moment(r.date) > from);
    }
    if (to && to.isValid()) {
      result = result.filter((r) => moment(r.date) < to);
    }
    setFilteredData(result);
  };

  const handleRemove = (id: number) => {
    setTransactions(transactions.filter((t) => t.id !== id));
    setFilteredData(filteredData.filter((f) => f.id !== id));
  };

  const transactionTypeOptions: ITransactionOption[] = [
    { id: 1, label: t('wallet.historyPage.transactionTypeOptions.all') },
    { id: 2, label: t('wallet.historyPage.transactionTypeOptions.deposit') },
    { id: 3, label: t('wallet.historyPage.transactionTypeOptions.withdraw') }
  ];
  return (
    <Box className="account-transactions-container">
      <Box className="account-transactions-container__title-filter-box">
        <Box className="account-transactions-container__title">
          {t('myAccount.accountPage.transactions')}
        </Box>
        <Box className="account-transactions-container__filters-button">
          <CustomIconButton
            icon={<FilterAltOutlinedIcon color="primary" />}
            onClick={handleClick}
          />
        </Box>
      </Box>
      {(!isMobile || (isMobile && showFilters)) && (
        <Box className="account-transactions-container__filters-box">
          <Box className="account-transactions-container__filters-box--type">
            <AutocompleteField
              id="type"
              label={t('wallet.historyPage.transactionTypeLabel') as string}
              value={transactionOption}
              onChange={handleChange}
              options={transactionTypeOptions}
              optionLabel={(option) => option.label}
              placeholder={t('wallet.historyPage.transactionTypeOptions.all') as string}
              isDarkTheme
              border={borderColor}
            />
          </Box>
          <Box className="account-transactions-container__filters-box--period">
            <DateField
              id="transaction-from-period"
              maxDate={periodTo as Moment}
              label={t(`wallet.historyPage.periodFromPlaceholder`) as string}
              onChange={(value) => {
                if (value && value.isValid()) {
                  value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                }
                setPeriodFrom(value);
                applyFilters(transactionOption, value, periodTo);
              }}
              value={periodFrom || null}
              isDarkTheme
              border={borderColor}
            />
          </Box>
          <Box className="account-transactions-container__filters-box--period">
            <DateField
              id="transaction-to-period"
              label={t(`wallet.historyPage.periodToPlaceholder`) as string}
              onChange={(value) => {
                if (value && value.isValid()) {
                  value.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
                }
                setPeriodTo(value);
                applyFilters(transactionOption, periodFrom, value);
              }}
              value={periodTo || null}
              maxDate={moment()}
              isDarkTheme
              border={borderColor}
            />
          </Box>
        </Box>
      )}
      <Box>
        <AccountTransactionsTable data={filteredData} handleRemove={handleRemove} />
      </Box>
      <Loader loading={transactionsQuery.isLoading} />
    </Box>
  );
};

export default AccountTransactionsPage;
