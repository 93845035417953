import { AccountCircle, CardGiftcard, SettingsOutlined } from '@mui/icons-material';
import { TFunction } from 'i18next';

export const getProfileMenuItems = (
  translateFunc: TFunction<'translation', undefined, 'translation'>
) => {
  return [
    {
      title: translateFunc('navigation.myAccount'),
      navIndex: 0,
      icon: AccountCircle
    },
    {
      title: translateFunc('navigation.bonuses'),
      navIndex: 2,
      icon: CardGiftcard
    },
    {
      title: translateFunc('myAccount.accountPage.securityAndSettings'),
      navIndex: 5,
      icon: SettingsOutlined
    }
  ];
};
