export interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
  id: number;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [
  { code: 'AF', label: 'Afghanistan', phone: '93', id: 1 },
  { code: 'AL', label: 'Albania', phone: '355', id: 2 },
  { code: 'DZ', label: 'Algeria', phone: '213', id: 3 },
  { code: 'AD', label: 'Andorra', phone: '376', id: 4 },
  { code: 'AO', label: 'Angola', phone: '244', id: 5 },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268', id: 6 },
  { code: 'AR', label: 'Argentina', phone: '54', id: 7 },
  { code: 'AM', label: 'Armenia', phone: '374', id: 8 },
  { code: 'AU', label: 'Australia', phone: '61', id: 9 },
  { code: 'AT', label: 'Austria', phone: '43', id: 10 },
  { code: 'AZ', label: 'Azerbaijan', phone: '994', id: 11 },
  { code: 'BS', label: 'Bahamas', phone: '1-242', id: 12 },
  { code: 'BH', label: 'Bahrain', phone: '973', id: 13 },
  { code: 'BD', label: 'Bangladesh', phone: '880', id: 14 },
  { code: 'BB', label: 'Barbados', phone: '1-246', id: 15 },
  { code: 'BY', label: 'Belarus', phone: '375', id: 16 },
  { code: 'BE', label: 'Belgium', phone: '32', id: 17 },
  { code: 'BZ', label: 'Belize', phone: '501', id: 18 },
  { code: 'BJ', label: 'Benin', phone: '229', id: 19 },
  { code: 'BT', label: 'Bhutan', phone: '975', id: 20 },
  { code: 'BO', label: 'Bolivia', phone: '591', id: 21 },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387', id: 22 },
  { code: 'BW', label: 'Botswana', phone: '267', id: 23 },
  { code: 'BR', label: 'Brazil', phone: '55', id: 24 },
  { code: 'BN', label: 'Brunei', phone: '673', id: 25 },
  { code: 'BG', label: 'Bulgaria', phone: '359', id: 26 },
  { code: 'BF', label: 'Burkina Faso', phone: '226', id: 27 },
  { code: 'BI', label: 'Burundi', phone: '257', id: 28 },
  { code: 'CI', label: "Côte d'Ivoire", phone: '225', id: 29 },
  { code: 'CV', label: 'Cabo Verde', phone: '238', id: 30 },
  { code: 'KH', label: 'Cambodia', phone: '855', id: 31 },
  { code: 'CM', label: 'Cameroon', phone: '237', id: 32 },
  { code: 'CA', label: 'Canada', phone: '1', id: 33 },
  { code: 'CF', label: 'Central African Republic', phone: '236', id: 34 },
  { code: 'TD', label: 'Chad', phone: '235', id: 35 },
  { code: 'CL', label: 'Chile', phone: '56', id: 36 },
  { code: 'CN', label: 'China', phone: '86', id: 37 },
  { code: 'CO', label: 'Colombia', phone: '57', id: 38 },
  { code: 'KM', label: 'Comoros', phone: '269', id: 39 },
  { code: 'CG', label: 'Congo', phone: '242', id: 40 },
  { code: 'CR', label: 'Costa Rica', phone: '506', id: 41 },
  { code: 'HR', label: 'Croatia', phone: '385', id: 42 },
  { code: 'CU', label: 'Cuba', phone: '53', id: 43 },
  { code: 'CY', label: 'Cyprus', phone: '357', id: 44 },
  { code: 'CZ', label: 'Czechia', phone: '420', id: 45 },
  { code: 'CD', label: 'Democratic Republic of the Congo', phone: '243', id: 46 },
  { code: 'DK', label: 'Denmark', phone: '45', id: 47 },
  { code: 'DJ', label: 'Djibouti', phone: '253', id: 48 },
  { code: 'DM', label: 'Dominica', phone: '1-767', id: 49 },
  { code: 'DO', label: 'Dominican Republic', phone: '1-809, 1-829, 1-849', id: 50 },
  { code: 'EC', label: 'Ecuador', phone: '593', id: 51 },
  { code: 'EG', label: 'Egypt', phone: '20', id: 52 },
  { code: 'SV', label: 'El Salvador', phone: '503', id: 53 },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240', id: 54 },
  { code: 'ER', label: 'Eritrea', phone: '291', id: 55 },
  { code: 'EE', label: 'Estonia', phone: '372', id: 56 },
  { code: 'SZ', label: 'Eswatini', phone: '268', id: 57 },
  { code: 'ET', label: 'Ethiopia', phone: '251', id: 58 },
  { code: 'FJ', label: 'Fiji', phone: '679', id: 59 },
  { code: 'FI', label: 'Finland', phone: '358', id: 60 },
  { code: 'FR', label: 'France', phone: '33', id: 61 },
  { code: 'GA', label: 'Gabon', phone: '241', id: 62 },
  { code: 'GM', label: 'Gambia', phone: '220', id: 63 },
  { code: 'GE', label: 'Georgia', phone: '995', id: 64 },
  { code: 'DE', label: 'Germany', phone: '49', id: 65 },
  { code: 'GH', label: 'Ghana', phone: '233', id: 66 },
  { code: 'GR', label: 'Greece', phone: '30', id: 67 },
  { code: 'GD', label: 'Grenada', phone: '1-473', id: 68 },
  { code: 'GT', label: 'Guatemala', phone: '502', id: 69 },
  { code: 'GN', label: 'Guinea', phone: '224', id: 70 },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245', id: 71 },
  { code: 'GY', label: 'Guyana', phone: '592', id: 72 },
  { code: 'HT', label: 'Haiti', phone: '509', id: 73 },
  { code: 'VA', label: 'Holy See', phone: '379', id: 74 },
  { code: 'HN', label: 'Honduras', phone: '504', id: 75 },
  { code: 'HU', label: 'Hungary', phone: '36', id: 76 },
  { code: 'IS', label: 'Iceland', phone: '354', id: 77 },
  { code: 'IN', label: 'India', phone: '91', id: 78 },
  { code: 'ID', label: 'Indonesia', phone: '62', id: 79 },
  { code: 'IR', label: 'Iran', phone: '98', id: 80 },
  { code: 'IQ', label: 'Iraq', phone: '964', id: 81 },
  { code: 'IE', label: 'Ireland', phone: '353', id: 82 },
  { code: 'IL', label: 'Israel', phone: '972', id: 83 },
  { code: 'IT', label: 'Italy', phone: '39', id: 84 },
  { code: 'JM', label: 'Jamaica', phone: '1-876', id: 85 },
  { code: 'JP', label: 'Japan', phone: '81', id: 86 },
  { code: 'JO', label: 'Jordan', phone: '962', id: 87 },
  { code: 'KZ', label: 'Kazakhstan', phone: '7', id: 88 },
  { code: 'KE', label: 'Kenya', phone: '254', id: 89 },
  { code: 'KI', label: 'Kiribati', phone: '686', id: 90 },
  { code: 'KW', label: 'Kuwait', phone: '965', id: 91 },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996', id: 92 },
  { code: 'LA', label: 'Laos', phone: '856', id: 93 },
  { code: 'LV', label: 'Latvia', phone: '371', id: 94 },
  { code: 'LB', label: 'Lebanon', phone: '961', id: 95 },
  { code: 'LS', label: 'Lesotho', phone: '266', id: 96 },
  { code: 'LR', label: 'Liberia', phone: '231', id: 97 },
  { code: 'LY', label: 'Libya', phone: '218', id: 98 },
  { code: 'LI', label: 'Liechtenstein', phone: '423', id: 99 },
  { code: 'LT', label: 'Lithuania', phone: '370', id: 100 },
  { code: 'LU', label: 'Luxembourg', phone: '352', id: 101 },
  { code: 'MG', label: 'Madagascar', phone: '261', id: 102 },
  { code: 'MW', label: 'Malawi', phone: '265', id: 103 },
  { code: 'MY', label: 'Malaysia', phone: '60', id: 104 },
  { code: 'MV', label: 'Maldives', phone: '960', id: 105 },
  { code: 'ML', label: 'Mali', phone: '223', id: 106 },
  { code: 'MT', label: 'Malta', phone: '356', id: 107 },
  { code: 'MH', label: 'Marshall Islands', phone: '692', id: 108 },
  { code: 'MR', label: 'Mauritania', phone: '222', id: 109 },
  { code: 'MU', label: 'Mauritius', phone: '230', id: 110 },
  { code: 'MX', label: 'Mexico', phone: '52', id: 111 },
  { code: 'FM', label: 'Micronesia', phone: '691', id: 112 },
  { code: 'MD', label: 'Moldova', phone: '373', id: 113 },
  { code: 'MC', label: 'Monaco', phone: '377', id: 114 },
  { code: 'MN', label: 'Mongolia', phone: '976', id: 115 },  
  { code: 'ME', label: 'Montenegro', phone: '382', id: 116 },
  { code: 'MA', label: 'Morocco', phone: '212', id: 117 },
  { code: 'MZ', label: 'Mozambique', phone: '258', id: 118 },
  { code: 'MM', label: 'Myanmar', phone: '95', id: 119 },
  { code: 'NA', label: 'Namibia', phone: '264', id: 120 },
  { code: 'NR', label: 'Nauru', phone: '674', id: 121 },
  { code: 'NP', label: 'Nepal', phone: '977', id: 122 },
  { code: 'NL', label: 'Netherlands', phone: '31', id: 123 },
  { code: 'NZ', label: 'New Zealand', phone: '64', id: 124 },
  { code: 'NI', label: 'Nicaragua', phone: '505', id: 125 },
  { code: 'NE', label: 'Niger', phone: '227', id: 126 },
  { code: 'NG', label: 'Nigeria', phone: '234', id: 127 },
  { code: 'KP', label: 'North Korea', phone: '850', id: 128 },
  { code: 'MK', label: 'North Macedonia', phone: '389', id: 129 },
  { code: 'NO', label: 'Norway', phone: '47', id: 130 },
  { code: 'OM', label: 'Oman', phone: '968', id: 131 },
  { code: 'PK', label: 'Pakistan', phone: '92', id: 132 },
  { code: 'PW', label: 'Palau', phone: '680', id: 133 },
  { code: 'PS', label: 'Palestine State', phone: '970', id: 134 },
  { code: 'PA', label: 'Panama', phone: '507', id: 135 },
  { code: 'PG', label: 'Papua New Guinea', phone: '675', id: 136 },
  { code: 'PY', label: 'Paraguay', phone: '595', id: 137 },
  { code: 'PE', label: 'Peru', phone: '51', id: 138 },
  { code: 'PH', label: 'Philippines', phone: '63', id: 139 },
  { code: 'PL', label: 'Poland', phone: '48', id: 140 },
  { code: 'PT', label: 'Portugal', phone: '351', id: 141 },
  { code: 'QA', label: 'Qatar', phone: '974', id: 142 },
  { code: 'RO', label: 'Romania', phone: '40', id: 143 },
  { code: 'RU', label: 'Russia', phone: '7', id: 144 },
  { code: 'RW', label: 'Rwanda', phone: '250', id: 145 },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869', id: 146 },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758', id: 147 },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784', id: 148 },
  { code: 'WS', label: 'Samoa', phone: '685', id: 149 },
  { code: 'SM', label: 'San Marino', phone: '378', id: 150 },
  { code: 'ST', label: 'Sao Tome and Principe', phone: '239', id: 151 },
  { code: 'SA', label: 'Saudi Arabia', phone: '966', id: 152 },
  { code: 'SN', label: 'Senegal', phone: '221', id: 153 },
  { code: 'RS', label: 'Serbia', phone: '381', id: 154 },
  { code: 'SC', label: 'Seychelles', phone: '248', id: 155 },
  { code: 'SL', label: 'Sierra Leone', phone: '232', id: 156 },
  { code: 'SG', label: 'Singapore', phone: '65', id: 157 },
  { code: 'SK', label: 'Slovakia', phone: '421', id: 158 },
  { code: 'SI', label: 'Slovenia', phone: '386', id: 159 },
  { code: 'SB', label: 'Solomon Islands', phone: '677', id: 160 },
  { code: 'SO', label: 'Somalia', phone: '252', id: 161 },
  { code: 'ZA', label: 'South Africa', phone: '27', id: 162 },
  { code: 'KR', label: 'South Korea', phone: '82', id: 163 },
  { code: 'SS', label: 'South Sudan', phone: '211', id: 164 },
  { code: 'ES', label: 'Spain', phone: '34', id: 165 },
  { code: 'LK', label: 'Sri Lanka', phone: '94', id: 166 },
  { code: 'SD', label: 'Sudan', phone: '249', id: 167 },
  { code: 'SR', label: 'Suriname', phone: '597', id: 168 },
  { code: 'SE', label: 'Sweden', phone: '46', id: 169 },
  { code: 'CH', label: 'Switzerland', phone: '41', id: 170 },
  { code: 'SY', label: 'Syria', phone: '963', id: 171 },
  { code: 'TJ', label: 'Tajikistan', phone: '992', id: 172 },
  { code: 'TZ', label: 'Tanzania', phone: '255', id: 173 },
  { code: 'TH', label: 'Thailand', phone: '66', id: 174 },
  { code: 'TL', label: 'Timor-Leste', phone: '670', id: 175 },
  { code: 'TG', label: 'Togo', phone: '228', id: 176 },
  { code: 'TO', label: 'Tonga', phone: '676', id: 177 },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868', id: 178 },
  { code: 'TN', label: 'Tunisia', phone: '216', id: 179 },
  { code: 'TR', label: 'Turkey', phone: '90', id: 180 },
  { code: 'TM', label: 'Turkmenistan', phone: '993', id: 181 },
  { code: 'TV', label: 'Tuvalu', phone: '688', id: 182 },
  { code: 'UG', label: 'Uganda', phone: '256', id: 183 },
  { code: 'UA', label: 'Ukraine', phone: '380', id: 184 },
  { code: 'AE', label: 'United Arab Emirates', phone: '971', id: 185 },
  { code: 'GB', label: 'United Kingdom', phone: '44', id: 186 },
  { code: 'US', label: 'United States of America', phone: '1', id: 187 },
  { code: 'UY', label: 'Uruguay', phone: '598', id: 188 },
  { code: 'UZ', label: 'Uzbekistan', phone: '998', id: 189 },
  { code: 'VU', label: 'Vanuatu', phone: '678', id: 190 },
  { code: 'VE', label: 'Venezuela', phone: '58', id: 191 },
  { code: 'VN', label: 'Vietnam', phone: '84', id: 192 },
  { code: 'YE', label: 'Yemen', phone: '967', id: 193 },
  { code: 'ZM', label: 'Zambia', phone: '260', id: 194 },
  { code: 'ZW', label: 'Zimbabwe', phone: '263', id: 195 }];
