import React from 'react';
//* MUI
import { Box } from '@mui/material';
//* Styles
import './CustomChip.scss';

interface IProps {
  type?: string;
  label: string;
  condensed?: boolean;
}

const CustomChip = ({ label, condensed, type = 'default' }: IProps) => {
  return (
    <Box
      className={`custom-chip custom-chip--${type} ${condensed ? 'custom-chip--condensed' : ''}`}
    >
      {label}
    </Box>
  );
};

export default CustomChip;
