import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
//* Images
import guest from '../../../assets/shop/badges/guest.svg';
import master from '../../../assets/shop/badges/master.svg';
import expert from '../../../assets/shop/badges/expert.svg';
import pro from '../../../assets/shop/badges/pro.svg';
import elite from '../../../assets/shop/badges/elite.svg';
import champion from '../../../assets/shop/badges/champion.svg';
import challengesBanana from '../../../assets/challenges/challengesBanana.svg';

//* Icons
import EastIcon from '@mui/icons-material/East';
//* MUI
import { Box } from '@mui/material';
//* Components
import ChallengeCard from '../ChallengeCard/ChallengeCard';
//* Styles
import './ChallengeInfoDialogContent.scss';

const ChallengeInfoDialogContent = () => {
  const { t } = useTranslation();
  const [extraInfoExpanded, setExtraInfoExpanded] = useState(false);

  const badgesArray = [
    { title: t('challenges.levels.guest'), image: guest },
    { title: t('challenges.levels.master'), image: master },
    { title: t('challenges.levels.expert'), image: expert },
    { title: t('challenges.levels.pro'), image: pro },
    { title: t('challenges.levels.elite'), image: elite },
    { title: t('challenges.levels.champion'), image: champion }
  ];

  return (
    <Box className="challenges-info">
      <h5 className="challenges-info__heading">{t('challenges.howItWorks')}?</h5>
      <span
        className={`challenges-info__text ${
          extraInfoExpanded ? 'challenges-info__text--is-expanded' : ''
        }`}
      >
        {t('challenges.infoText')}
      </span>
      <button
        className="challenges-info__text__read-more-button"
        onClick={() => setExtraInfoExpanded(!extraInfoExpanded)}
      >
        {extraInfoExpanded ? 'Read Less' : 'Read More'}
      </button>
      <Box className="challenges-info__tasks">
        <h5>{t('challenges.treasureItems')}</h5>
        <Box className="challenges-info__row">
          <Box className="challenges-info__row-box">
            <ChallengeCard
              isLocked
              isCompact
              image={challengesBanana}
              title={t('challenges.task')}
              subtitle="+50"
            />
            <span className="challenges-info__row-box__title">{t('challenges.lockedTask')}</span>
          </Box>
          <Box className="challenges-info__row-arrow">
            <EastIcon />
          </Box>
          <Box className="challenges-info__row-box">
            <ChallengeCard
              isCompact
              image={challengesBanana}
              title={t('challenges.task')}
              subtitle="+50"
            />
            <span className="challenges-info__row-box__title">
              {t('challenges.availableTreasure')}
            </span>
          </Box>
          <Box className="challenges-info__row-arrow">
            <EastIcon />
          </Box>
          <Box className="challenges-info__row-box">
            <ChallengeCard
              isClaimed
              isCompact
              image={challengesBanana}
              title={t('challenges.task')}
              subtitle="+50"
            />
            <span className="challenges-info__row-box__title">{t('challenges.completedTask')}</span>
          </Box>
        </Box>
      </Box>
      <Box className="challenges-info__tasks">
        <h5>{t('challenges.levelMeter')}</h5>
        <span className="challenges-info__text challenges-info__text--is-expanded">
          {t('challenges.infoTextExpanded')}
        </span>
        <Box className="challenges-info__row">
          {badgesArray.map((badge) => (
            <Box key={badge.title} className="challenges-info__badge">
              <Box className="challenges-info__badge-image" src={badge.image} component="img" />
              <span className="challenges-info__badge-text">{badge.title}</span>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ChallengeInfoDialogContent;
