import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './PasswordRequirements.scss';
import { changePassRequirements } from '../../../utils/validators';

interface PasswordRequirementsProps {
  password: string;
  onValidityChange: (allValid: boolean) => void;
}

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  password,
  onValidityChange
}) => {
  const requirements = changePassRequirements(password);

  const allValid = requirements.every((req) => req.isValid);

  useEffect(() => {
    onValidityChange(allValid);
  }, [password, allValid, onValidityChange]);

  return (
    <Box className="requirements-container">
      {requirements.map((req, index) => (
        <Box key={index} className="requirements-container__icon-label-box">
          {req.isValid ? (
            <CheckCircleIcon className="check-circle-icon" />
          ) : (
            <CancelIcon className="cancel-icon" />
          )}
          <Box className="requirements-container__icon-label-box__label">{req.label}</Box>
        </Box>
      ))}{' '}
    </Box>
  );
};
export default PasswordRequirements;
