import { Actions } from '../enums/ActionEnums';
import { InitialStateType } from '../AppContext';
import { ActionType } from '../models/appcontext';
import { NavigateFunction } from 'react-router-dom';
import { LandingPageModel } from '../models/landingPage';
import { AuthRoutes, PublicRoutes } from '../enums/RouteEnums';

export const handleLandingPageButton = ({
  state,
  navigate,
  dispatch,
  landingPageData
}: {
  state: InitialStateType;
  navigate: NavigateFunction;
  landingPageData: LandingPageModel;
  dispatch: React.Dispatch<ActionType>;
}) => {
  if (landingPageData.link === PublicRoutes.Wheel) {
    if (state.user.data && state.user.data.hasDeposited) {
      dispatch({ type: Actions.ShowWheelPopup });
    } else {
      if (state.user.data === null) {
        dispatch({
          type: Actions.ShowLoginPopup,
          payload: {}
        });
      } else {
        dispatch({
          type: Actions.ShowPopup,
          payload: {
            state: 'You should deposit at least once to be able to play the lucky wheel',
            isCongrat: false,
            redirectUrl: AuthRoutes.Wallet,
            buttonText: 'Deposit Now'
          }
        });
      }
    }
  } else {
    navigate(
      landingPageData.link === PublicRoutes.Signup && state.user.data
        ? AuthRoutes.Wallet
        : landingPageData.link
    );
  }
};
