import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ArchivedTournaments from './ArchivedTournaments';
import ButtonTabs from '../Common/Buttons/ButtonTabs';
import AllTournaments from './AllTournaments';
import UpcomingTournaments from './UpcomingTournaments';

const TournamentsPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  return (
    <Box className="nav-page">
      <Box
        sx={{
          fontWeight: 700,
          fontSize: { xs: '32px', md: '64px' },
          lineHeight: { xs: '45px', md: '90px' }
        }}
      >
        {t('navigation.tournaments')}
      </Box>
      <Box sx={{ marginTop: '32px' }}>
        <ButtonTabs
          selected={page}
          handleChange={setPage}
          options={[
            { label: t('tournaments.navigation.all') },
            { label: t('tournaments.navigation.upcoming') },
            { label: t('tournaments.navigation.archived') }
          ]}
        />
      </Box>
      <Box sx={{ marginTop: '48px' }}>
        {page === 0 && <AllTournaments />}
        {page === 1 && <UpcomingTournaments />}
        {page === 2 && <ArchivedTournaments />}
      </Box>
    </Box>
  );
};

export default TournamentsPage;
