import React from 'react';
import { Box } from '@mui/material';
import './AccountUsername.scss';

interface IProps {
  username: string;
}

const AccountUsername: React.FunctionComponent<IProps> = ({ username }) => {
  return <Box className="username-container">{username}</Box>;
};

export default AccountUsername;
