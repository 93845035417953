import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { AppConsts } from '../../enums/AppConsts';
import { PublicRoutes } from '../../enums/RouteEnums';

interface Props {
  Component: React.FunctionComponent;
  isPublic: boolean;
}

interface decoded {
  exp: number;
  name: string;
  family_name: string;
  given_name: string;
  email: string;
  roles: string[];
}

const AuthRoute = ({ Component, isPublic }: Props) => {
  const { state, dispatch } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const redirectToSignIn = () => (
    <Navigate
      to={{
        pathname: PublicRoutes.Signin
      }}
      replace
      state={{ path: location.pathname }}
    />
  );

  const navigateToLogin = () => {
    if (!isPublic) {
      navigate(PublicRoutes.Signin, { state: { path: location.pathname } });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem(AppConsts.AccessToken);
    if (token) {
      const decoded: decoded = jwtDecode(token);
      const now = new Date().getTime() / 1000;
      if (decoded.exp < now) {
        localStorage.removeItem(AppConsts.AccessToken);
        dispatch({ type: Actions.RemoveUser });
        navigateToLogin();
      }
    }
  }, [location]);

  const isAuthed = isPublic ? true : localStorage.getItem(AppConsts.AccessToken) !== null;
  // const userRoles: (string | null)[] = state.user.data ? state.user.data.roles : [];
  // const accessGranted = requiredRole ? userRoles.includes(requiredRole) : true;
  // return isAuthed ? accessGranted ? <Component /> : redirectToSignIn() : redirectToSignIn();
  return isAuthed ? <Component /> : redirectToSignIn();
};

export default AuthRoute;
