import React from 'react';
import { Box } from '@mui/material';
import { Paragraph } from './FooterPage';

type IProps = {
  content: Paragraph;
};

const FooterParagraph: React.FunctionComponent<IProps> = ({ content }) => {
  return (
    <Box>
      <Box
        sx={{
          fontFamily: 'Poppins',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '22.4px',
          marginTop: '24px'
        }}
        dangerouslySetInnerHTML={{
          __html: content.body
        }}
      />
    </Box>
  );
};

export default FooterParagraph;
