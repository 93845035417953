import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box } from '@mui/material';
//* Components
import BonusShop from './BonusShop';
import WheelShop from './WheelShop';
import ShopBanner from './ShopBanner/ShopBanner';
import ButtonTabs from '../Common/Buttons/ButtonTabs';
import FreeSpinsShop from './FreeSpins/FreeSpinsShop';
import ShopAdditionalInfo from './ShopAdditionalInfo/ShopAdditionalInfo';
//* Models
import { ExchangeLevel } from '../../models/shop';
//* Queries
import { AppContext } from '../../AppContext';
import { GetMasterLevels } from '../../queries/shop';
//* Styles
import './ShopPage.scss';
import BottomBanner from '../Home/BottomBanner';

const ShopPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { state } = useContext(AppContext);
  const [page, setPage] = useState(0);
  const [levels, setLevels] = useState<ExchangeLevel[]>([]);
  const [wheelRate, setWheelRate] = useState(10);
  const [bonusRate, setBonusRate] = useState(0);
  const playerLevel = state.user.data?.challengeLevel?.rank ?? 0;
  const queryClient = useQueryClient();

  const { mutate } = useMutation(GetMasterLevels, {
    onSuccess: ({ data }) => {
      const dbData = data as ExchangeLevel[];
      setLevels(dbData);
      setExchangeRates(dbData);
    },
    onSettled: () => {
      queryClient.invalidateQueries('GetMasterLevels');
    }
  });

  useEffect(() => {
    if (levels.length === 0) {
      mutate();
    }
  }, []);

  useEffect(() => {
    setExchangeRates(levels);
  }, [state.user]);

  const setExchangeRates = (levelsData: ExchangeLevel[]) => {
    const checkRank = state.user.data?.challengeLevel?.rank ?? 0;
    const level = levelsData.find((dd) => dd.rank === checkRank);
    setWheelRate(level?.wheelRate ?? 10);
    setBonusRate(level?.bonusRate ?? 10);
  };

  return (
    <Box className="shop-page-container">
      <Box className="shop-page">
        <ShopBanner page={page} handlePageChange={setPage} />
        <Box className="shop-page__button-tabs">
          <ButtonTabs
            selected={page}
            handleChange={setPage}
            options={[
              { label: t('shop.navigation.wheel') },
              { label: t('shop.navigation.bonus') },
              { label: t('shop.navigation.freeSpins') }
            ]}
          />
        </Box>
        <ShopAdditionalInfo page={page} levels={levels} />
        {page === 0 && <WheelShop rate={wheelRate} />}
        {page === 1 && <BonusShop rate={bonusRate} />}
        {page === 2 && <FreeSpinsShop playerLevel={playerLevel} />}
      </Box>
      <Box>
        <BottomBanner showOnShopPage={true} />
      </Box>
    </Box>
  );
};

export default ShopPage;
