import React from 'react';
import logo from '../../../assets/logo.svg';
import logoClone from '../../../assets/logo_clone.svg';
import bubble from '../../../assets/bubble.svg';
import Bubbles from '../Bubbles';
import './AccessDenied.scss';

const AccessDenied: React.FunctionComponent = () => {
  const isClone = process.env.ENVIRONMENT === 'licence';
  const text = `Sorry, we are not accepting visitors from your country. If you have any questions, please
          contact us ${process.env.SUPPORT_EMAIL}`;
  return (
    <div className="access-container">
      <div className="content-container">
        <img className="content-container__logo" src={isClone ? logoClone : logo} />
        <div className="content-container__code">
          <div className="content-container__code__num">4</div>
          <img className="content-container__code__bubble" src={bubble} />
          <div className="content-container__code__num">3</div>
        </div>
        <div className="content-container__text">{text}</div>
      </div>
      <Bubbles />
    </div>
  );
};

export default AccessDenied;
