import React, { useContext, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import Wrapper from './Wrapper';
import WrapperTitle from './WrapperTitle';
import { PublicRoutes, RouteNames } from '../../enums/RouteEnums';
import { Box } from '@mui/system';
import CSS from 'csstype';
import { getBorderColor, getIconColor, inputPropsStyle } from '../Account/SignupPage';
import { Email } from '@mui/icons-material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import PhotoIcon from '@mui/icons-material/Photo';
import CloseIcon from '@mui/icons-material/Close';
import { isValidEmail } from '../../utils/validators';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { colors } from '../../utils/theme';
import { useMutation } from 'react-query';
import { SendContactUsEmailQuery } from '../../queries/account';
import { Attachment, EmailModel } from '../../models/account';
import Loader from '../Common/Loader';
import { useTranslation } from 'react-i18next';
import { handleError } from '../../utils/ui';

const labelStyle: CSS.Properties = {
  top: '10px',
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Poppins',
  color: 'white'
};

type IErrors = {
  email: string | null;
  topic: string | null;
  message: string | null;
};

const defaultErrorsObj: IErrors = {
  email: null,
  topic: null,
  message: null
};

const textFieldProps = (value: string | null) => ({
  height: '46px',
  '& .MuiOutlinedInput-root': {
    backgroundColor: value ? `${colors.white} !important` : 'default',
    color: 'primary.main',
    '& fieldset': {
      borderColor: getBorderColor(value, 'white')
    },
    '&.Mui-focused': {
      backgroundColor: `${colors.white} !important`
    }
  }
});

const ContactUs = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState<string | null>(null);
  const [emailBody, setEmailBody] = useState('');
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileString, setFileString] = useState<string>('');
  const { mutate, isLoading } = useMutation(SendContactUsEmailQuery, {
    onSuccess: () => {
      clearForm();
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('footer.ContactUs.showMessageText')
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const enableSend = isValidEmail(email) && topic !== null && emailBody.length > 10;
  const options = ['User Account', 'KYC/Verification', 'Bonuses', 'Payment Transaction'];
  const fieldColor = '#282D74';

  const clearForm = () => {
    setEmail('');
    setTopic(null);
    setEmailBody('');
    setFileAttachment(null);
    setFileString('');
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isValidEmail(value)) {
      setFormErrors({ ...formErrors, email: t('errors.invalidEmail') });
    } else {
      setFormErrors({ ...formErrors, email: null });
    }
    setEmail(value);
  };

  const handleEmailBodyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const bodyValueText = value.length < 10 ? t('footer.ContactUs.messageTooShort') : null;
    setFormErrors({ ...formErrors, message: bodyValueText });
    setEmailBody(value);
  };

  const handleTopicChange = (_: unknown, newValue: string | null) => {
    const topicValueText = newValue === null ? t('footer.ContactUs.selectTopic') : null;
    setFormErrors({ ...formErrors, topic: topicValueText });
    setTopic(newValue);
  };

  const handleImgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      processFile(file);
    }
  };

  const processFile = (file: File) => {
    if (file) {
      const size = file.size / 1024 / 1024;
      if (size <= 1) {
        setFileAttachment(file);
        const render = new FileReader();
        render.readAsDataURL(file);
        render.onload = (e) => {
          if (e && e.target) {
            setFileString(e.target.result as string);
          }
        };
      } else {
        dispatch({
          type: Actions.ShowMessage,
          payload: {
            severity: 'error',
            text: t('footer.ContactUs.imageSizeMessage')
          }
        });
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event && event.dataTransfer.files) {
      const file = event.dataTransfer.files[0];
      processFile(file);
      //event.dataTransfer.clearData();
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const removeImg = () => {
    setFileAttachment(null);
    setFileString('');
  };
  const getFileSize = () => {
    const size = fileAttachment !== null ? fileAttachment.size / 1024 / 1024 : 0;
    if (size < 1) {
      return `${(size * 1024).toFixed(2)} KB`;
    }
    return `${size.toFixed(2)} MB`;
  };

  const handleSend = () => {
    const sender = process.env.SENDER_EMAIL || '';
    const support = process.env.SUPPORT_EMAIL || '';
    const content = `from: ${email}, content: ${emailBody}`;
    const model = {
      from: sender,
      to: support,
      content,
      subject: topic
    } as EmailModel;

    if (fileAttachment) {
      const garbage = 'data:image/png;base64,';
      const attachment = fileString.substring(garbage.length);
      model['attachments'] = [
        {
          content: attachment,
          filename: fileAttachment?.name,
          type: fileAttachment?.type
        } as Attachment
      ];
    }
    mutate(model);
  };

  return (
    <Wrapper>
      <WrapperTitle text={t(`footer.ContactUs.title`) as string} />
      <Typography style={{ marginTop: 32 }}>
        {`${t(`footer.ContactUs.text1`)} `}
        <Link
          style={{
            textDecoration: 'none',
            color: 'rgb(227,171,0)'
          }}
          to={PublicRoutes.HelpCenter}
        >
          {t(`footer.ContactUs.title`)}
        </Link>
        ?{` ${t(`footer.ContactUs.text2`)} `}({t(`footer.ContactUs.supportFormats`)}).
      </Typography>
      <Box
        sx={{
          mt: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Grid container style={{ maxWidth: '490px' }}>
          <Grid item xs={12}>
            <InputLabel htmlFor="username" style={labelStyle}>
              {t('account.emailLabel')}
            </InputLabel>
            <TextField
              id="email"
              name="email"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={email}
              onChange={handleEmailChange}
              error={formErrors.email !== null}
              helperText={formErrors.email ? formErrors.email : ''}
              sx={textFieldProps(email)}
              inputProps={{
                sx: inputPropsStyle
              }}
              placeholder={t('account.emailPlaceHolder') as string}
              color="info"
              InputProps={{
                style: {
                  backgroundColor: fieldColor
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Email sx={{ fill: getIconColor(email, formErrors.email) }} />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 2 }}>
            <InputLabel htmlFor="emailTopic" style={labelStyle}>
              {t(`footer.ContactUs.topic`)}
            </InputLabel>
            <Autocomplete
              id="emailTopicAutocomplete"
              fullWidth
              value={topic}
              onChange={handleTopicChange}
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="topicInput"
                  name="topicInput"
                  margin="normal"
                  variant="outlined"
                  error={formErrors.topic !== null}
                  helperText={formErrors.topic ? formErrors.topic : ''}
                  sx={{
                    height: '46px',
                    '& .MuiOutlinedInput-root': {
                      height: '46px',
                      padding: '0 !important',
                      backgroundColor: topic ? `${colors.white} !important` : 'default',
                      color: 'primary.main',
                      '& fieldset': {
                        borderColor: getBorderColor(topic, 'white')
                      },
                      '&.Mui-focused': {
                        backgroundColor: `${colors.white} !important`
                      }
                    }
                  }}
                  placeholder={t(`footer.ContactUs.selectTopic`) as string}
                  color="info"
                  inputProps={{
                    ...params.inputProps,
                    sx: inputPropsStyle
                    // autoComplete: 'new-password' // disable autocomplete and autofill
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      backgroundColor: fieldColor
                    },
                    startAdornment: (
                      <InputAdornment position="start" style={{ paddingLeft: '13px' }}>
                        <ListAltIcon sx={{ fill: getIconColor(topic, formErrors.topic) }} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 2 }}>
            <InputLabel htmlFor="username" style={labelStyle}>
              {t(`footer.ContactUs.message`)}
            </InputLabel>
            <TextField
              id="email-body"
              name="email-body"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              multiline={true}
              rows={6}
              value={emailBody}
              onChange={handleEmailBodyChange}
              error={formErrors.message !== null}
              helperText={formErrors.message ? formErrors.message : ''}
              sx={{ ...textFieldProps(emailBody), height: 'auto' }}
              // sx={{
              //   height: '46px',
              //   '& .MuiOutlinedInput-root': {
              //     '& fieldset': {
              //       borderColor: getBorderColor(emailBody, 'white')
              //     }
              //   }
              // }}
              inputProps={{
                sx: { padding: 0 }
              }}
              placeholder={t(`footer.ContactUs.enterMessage`) as string}
              color="info"
              InputProps={{
                style: {
                  borderRadius: '8px',
                  backgroundColor: fieldColor
                }
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: '24px' }}>
            {fileAttachment && (
              <Grid container>
                <Grid item xs={1} style={{ paddingTop: 27 }}>
                  <PhotoIcon sx={{ color: (t) => t.palette.info.main }} />
                </Grid>
                <Grid item xs={10}>
                  <p
                    style={{
                      marginBottom: 0,
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400
                    }}
                  >
                    {fileAttachment.name}
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: colors.grey.placeholder
                    }}
                  >
                    {getFileSize()}
                  </p>
                </Grid>
                <Grid item xs={1} style={{ paddingTop: 27 }}>
                  <CloseIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={removeImg} />
                </Grid>
              </Grid>
            )}
            <Box
              sx={{
                border: '1px dashed white',
                borderRadius: '100px',
                height: {
                  xs: '68px',
                  sm: '68px',
                  md: '46px'
                },
                // mt: '32px',
                cursor: 'pointer',
                backgroundColor: fieldColor
              }}
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDragEnter={(e) => handleDragEnter(e)}
              onDragLeave={(e) => handleDragLeave(e)}
            >
              <input
                id="email-attachment"
                accept="image/*"
                type="file"
                hidden
                onChange={handleImgChange}
              />
              <label htmlFor="email-attachment">
                <Grid
                  container
                  sx={{
                    padding: '11px',
                    display: 'flex',
                    justifyContent: 'space-evenly'
                  }}
                >
                  <Grid item xs={12} sm={12} md={9}>
                    <Box
                      sx={{
                        color: (theme) => theme.palette.grey[300],
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        fontSize: {
                          xs: '12px',
                          sm: '12px',
                          md: '15px'
                        },
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {t(`footer.ContactUs.supportFormats`)}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Box
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        fontSize: {
                          xs: '12px',
                          sm: '12px',
                          md: '16px'
                        },
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <UploadOutlinedIcon />
                      <div style={{ display: 'inline' }}>{t(`footer.ContactUs.upload`)}</div>
                    </Box>
                  </Grid>
                </Grid>
              </label>
            </Box>
          </Grid>
          <Button
            className="custom-button"
            variant="contained"
            disabled={!enableSend}
            onClick={handleSend}
            fullWidth
            sx={{
              mt: '32px',
              background: 'linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%)',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              '&:hover': {
                background: (t) => t.palette.common.white,
                boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
              },
              color: 'primary.main'
            }}
          >
            {t('account.send')}
          </Button>
        </Grid>
      </Box>
      <Typography style={{ marginTop: 32 }}>
        {t(`footer.ContactUs.chat`)}:
        <p
          style={{
            display: 'inline',
            color: 'rgb(227,171,0)'
          }}
        >
          {' 24/7'}
        </p>
      </Typography>
      <Loader loading={isLoading} />
    </Wrapper>
  );
};

export default ContactUs;
