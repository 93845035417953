import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box } from '@mui/material';
//* Models
import { ITournament } from '../../../../models/tournaments';
//* Components
import TournamentCardClockBox from '../TournamentCardClockBox/TournamentCardClockBox';
//* Utils
import { calculateTimeLeft, getPrettyNumber } from '../../../../utils/tournaments';
//* Images
import doubleCoin from '../../../../assets/tournaments/doubleCoin.svg';
//* Styles
import './TournamentCardExtraInfo.scss';

interface IProps {
  winType: string;
  isActive: boolean;
  minBet: number | undefined;
  endDate: string | undefined;
  duration: number | undefined;
  startDate: string | undefined;
  prizePool: string | undefined;
}

const TournamentCardExtraInfo = ({
  minBet,
  winType,
  endDate,
  duration,
  isActive,
  startDate,
  prizePool
}: IProps) => {
  const { t, i18n } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft({
      isActive,
      endDate,
      startDate
    })
  );

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(
        calculateTimeLeft({
          isActive,
          endDate,
          startDate
        })
      );
    }, 1000);
  });

  return (
    <>
      <Box className="tournament-card__extra-info">
        <Box className="tournament-card__extra-info__prize">
          <section>{t('tournaments.card.poolPrize')}</section>
          <section className="tournament-card__extra-info__prize-title--extra">{winType}</section>
          <section className="tournament-card__extra-info__prize-value">
            <Box
              component="img"
              src={doubleCoin}
              className="tournament-card__extra-info__prize-value__img"
            />
            {prizePool}
          </section>
          <section className="tournament-card__extra-info__prize-title">{winType}</section>
        </Box>
        <Box className="tournament-card__extra-info__clock">
          <section>
            {isActive ? t('tournaments.card.timer.endsIn') : t('tournaments.card.timer.startsIn')}
          </section>
          <section>
            <TournamentCardClockBox
              title={t('tournaments.card.timer.days')}
              value={getPrettyNumber(timeLeft.days)}
            />
            <TournamentCardClockBox
              title={t('tournaments.card.timer.hours')}
              value={getPrettyNumber(timeLeft.hours)}
            />
            <TournamentCardClockBox
              title={t('tournaments.card.timer.min')}
              value={getPrettyNumber(timeLeft.minutes)}
            />
            <TournamentCardClockBox
              title={t('tournaments.card.timer.sec')}
              value={getPrettyNumber(timeLeft.seconds)}
            />
          </section>
        </Box>
      </Box>
      <Box className="tournament-card__details">
        <section className="tournament-card__details-bet">
          {t('tournaments.card.minBet')}: <strong>&euro; {minBet?.toFixed(2)}</strong>
        </section>
        <section className="tournament-card__details-duration">
          {t('tournaments.card.duration')}:{' '}
          <strong>{`${duration} ${t('tournaments.card.days')}`}</strong>
        </section>
      </Box>
    </>
  );
};

export default TournamentCardExtraInfo;
