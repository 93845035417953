import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../enums/RouteEnums';
import CancelButton from './Buttons/CancelButton';

const NotFound: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        paddingTop: '112px',
        textAlign: 'center',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box sx={{ display: 'flex', flexFlow: 'column', justifyContent: 'center' }}>
        <Box sx={{ fontWeight: 700, fontSize: '64px', lineHeight: '90px' }}>
          {t('notFound.ops')}
        </Box>
        <Box sx={{ fontWeight: 600, fontSize: '40px', lineHeight: '56px' }}>
          {t('notFound.wentWrong')}
        </Box>
        <Box sx={{ marginTop: '12px' }}>
          <CancelButton
            text={t('notFound.goBackButton')}
            onClick={() => navigate(PublicRoutes.Home)}
            additionalStyle={{ width: 'auto', color: 'white' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
