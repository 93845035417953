export enum RouteNames {
  Home = 'Home',
  Signin = 'Signin',
  Signup = 'Signup',
  ResetPass = 'ResetPassword',
  ConfirmEmail = 'ConfirmEmail',
  PrivacyPolicy = 'PrivacyPolicy',
  BonusTerms = 'BonusTerms',
  ResponsibleGaming = 'ResponsibleGaming',
  TermsAndCoditions = 'TermsAndConditions',
  HelpCenter = 'HelpCenter',
  ContactUs = 'ContactUs',
  AboutUs = 'AboutUs',
  Affiliates = 'Affiliates',
  Wallet = 'Wallet',
  MyAccount = 'MyAccount',
  Games = 'Games',
  Game = 'Game',
  NotFound = 'NotFound',
  Promotions = 'Promotions',
  Tournaments = 'Tournaments',
  Challenges = 'Challenges',
  Shop = 'Shop',
  Bonuses = 'Bonuses',
  LandingPage = 'Landing-Page',
  LandingPageNotFound = 'Landing-Page-Not-Found',
  KYC = 'KYC'
}

export enum PublicRoutes {
  Home = '/',
  Signin = '/signin',
  Signup = '/signup',
  ResetPass = '/reset-password',
  ConfirmEmail = '/confirm-email',
  PrivacyPolicy = '/privacy-policy',
  BonusTerms = '/bonus-terms',
  ResponsibleGaming = '/responsible-gaming',
  TermsAndCoditions = '/terms-and-coditions',
  HelpCenter = '/help-center',
  ContactUs = '/contact-us',
  AboutUs = '/about-us',
  Affiliates = '/affiliates',
  NotFound = '/not-found',
  Games = '/games',
  Shop = '/shop',
  Promotions = '/promotions',
  Tournaments = '/tournaments',
  Challenges = '/challenges',
  Wheel = '/lucky-wheel',
  LandingPage = '/landing-page/:externalId',
  LandingPageNotFound = '/landing-page-not-found',
  KYC = '/kyc-policy'
}

export enum AuthRoutes {
  Wallet = '/wallet',
  MyAccount = '/my-account',
  Bonuses = '/bonuses',
  Game = '/games/:id'
}
