import { Actions } from '../enums/ActionEnums';
import { ActionType } from '../models/appContext';
import { IGameDto } from '../models/games';

export const gamesReducer = (state: IGameDto[], action: ActionType): IGameDto[] => {
  switch (action.type) {
    case Actions.SetGames:
      return action.payload;
    default:
      return state;
  }
};
