import axios from 'axios';
import { IGameKey } from '../models/games';
import { getHeaders } from '../utils/http';

const baseUrl = process.env.BASE_SERVER_ADDRESS;

export const AllGamesQuery = async () => {
  return await axios.get(`${baseUrl}/game/all`, getHeaders());
};

export const GameQuery = async (id: string) => {
  return await axios.get(`${baseUrl}/game/GetGame/${id}`, getHeaders());
};

export const StartDemoQuery = async (gameId: string) => {
  return await axios.post(`${baseUrl}/game/startdemo`, { gameId });
};

export const StartGameQuery = async (gameId: string) => {
  return await axios.post(`${baseUrl}/game/StartGame`, { gameId }, getHeaders());
};

export const AddFavoriteGameQuery = async ({ gameId, name }: IGameKey) => {
  return await axios.post(`${baseUrl}/game/AddToFavorite`, { gameId, name }, getHeaders());
};

export const RemoveFavoriteGameQuery = async ({ gameId, name }: IGameKey) => {
  return await axios.post(`${baseUrl}/game/RemoveFromFavorite`, { gameId, name }, getHeaders());
};
