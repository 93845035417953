import React, { useState } from 'react';
import { Dialog, Slide, SxProps } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

interface IProps {
  handleClose?: () => void;
  children: React.ReactNode;
  sx?: SxProps;
  fullscreen?: boolean;
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogBox: React.FunctionComponent<IProps> = ({
  children,
  handleClose,
  sx,
  fullscreen = false
}) => {
  const [open, setOpen] = useState(true);
  return (
    <Dialog
      open={handleClose ? open : true}
      {...(handleClose && {
        onClose: () => {
          setOpen(false);
          handleClose();
        }
      })}
      fullScreen={fullscreen}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-container': {
          display: 'flex',
          alignItems: 'start',
          marginTop: 'auto',
          marginBottom: 'auto'
        }
      }}
      PaperProps={{
        sx: {
          ...sx
        }
      }}
    >
      {children}
    </Dialog>
  );
};

export default DialogBox;
