import React, { useContext, useEffect } from 'react';
//import './KycWidget.scss';
import DialogBox from '../Common/Dialogs/DialogBox';
import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import { handleError } from '../../utils/ui';
import { Query } from '../../enums/RequestEnums';
import { GetKycTokenQuery } from '../../queries/profile';
import { AppContext } from '../../AppContext';
import InnerLoader from '../Common/InnerLoader';

interface IProps {
  handleClose: () => void;
}

const KycWidget: React.FunctionComponent<IProps> = ({ handleClose }: IProps) => {
  const { dispatch, state } = useContext(AppContext);

  const tokenQuery = useQuery({
    queryKey: [Query.KycToken, state.user.data?.email],
    queryFn: GetKycTokenQuery,
    onError: (error: unknown) => {
      handleError(error, dispatch);
    }
  });
  const kycData = tokenQuery.data?.data;

  useEffect(() => {
    if (kycData) {
      const script = document.createElement('script');
      script.src = process.env.GLOBALPASS_WIDGET_ADDRESS as string;
      script.onload = () => {
        window.GPScreeningWidget.init({
          elementId: 'widgetScreening',
          token: kycData.token,
          externalId: kycData.externalId,
          redirectUri: process.env.BASE_CLIENT_ADDRESS,
          language: 'en'
        });
      };
      document.head.appendChild(script);
    }
    return () => {
      const widgetContainer = document.getElementById('widgetScreening');
      if (widgetContainer) {
        widgetContainer.innerHTML = '';
      }
      if (window.GPScreeningWidget && window.GPScreeningWidget.destroy) {
        window.GPScreeningWidget.destroy();
      }
    };
  }, [kycData]);

  return (
    <DialogBox handleClose={handleClose}>
      <Box>
        {tokenQuery.isLoading && <InnerLoader />}
        <Box sx={{ width: '100%', height: '100%' }} id="widgetScreening"></Box>
      </Box>
    </DialogBox>
  );
};

export default KycWidget;
