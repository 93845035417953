import React from 'react';
import { PulseLoader } from 'react-spinners';
import './InlineLoader.scss';

const InlineLoader: React.FunctionComponent = () => {
  return (
    <div className="inline-loader">
      <PulseLoader color="white" size={15} />
    </div>
  );
};

export default InlineLoader;
