import { Actions } from '../enums/ActionEnums';
import { UserModel } from '../models/account';
import { ActionType, UserType } from '../models/appcontext';

export const userReducer = (state: UserType, action: ActionType): UserType => {
  switch (action.type) {
    case Actions.SetUser:
      return {
        data: {
          ...action.payload.data,
          challengeLevel: action.payload.data.challengeLevel,
          wheelSpins: action.payload.data.wheelSpins || 0
        },
        token: action.payload.token
      };
    case Actions.AddUserWheelSpins:
      const data = state.data as UserModel;
      const res = {
        ...state,
        data: {
          ...data,
          wheelSpins: data.wheelSpins + action.payload.amount
        }
      };
      return res;
    case Actions.UpdateBalance:
    case Actions.UpdateBonusBalance:
    case Actions.UpdateUserBadge:
    case Actions.UpdateUserLoyaltyPoints:
    case Actions.UpdateUserWheelSpins:
      const userData = state.data as UserModel;
      return {
        ...state,
        data: {
          ...userData,
          ...action.payload
        }
      };
    case Actions.DepositAmount:
      const userDataObj = state.data as UserModel;
      return {
        ...state,
        data: {
          ...userDataObj,
          realBalance: state.data?.realBalance ?? 0 + action.payload.amount
        }
      };
    case Actions.RemoveUser:
      return { data: null, token: null };
    default:
      return state;
  }
};
