import React, { useContext, useEffect, useState } from 'react';
import { Box, Slide } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { BannersMainQuery } from '../../queries/banner';
import { BannerModel } from '../../models/banner';
import { AppContext } from '../../AppContext';
import Bubbles from '../Common/Bubbles';
import { colors } from '../../utils/theme';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../enums/RouteEnums';
import { GameTypesEnum } from '../../enums/GamesEnums';
import TournamentBanner from './TournamentBanner';
import { IGameDto } from '../../models/games';
import HomeGameSection from './HomeGameSection';
import BottomBanner from './BottomBanner';
import Loader from '../Common/Loader';
import PromoBannerContainer from './PromoBannerContainer';
import { handleError } from '../../utils/ui';
import { isGameLoaded } from '../Navigation/Navigation';
import BannerContainerNew from '../Common/Banner/BannerContainerNew';
import { isUserLoggedIn } from '../../utils/validators';

const GameTypeChip = React.forwardRef(function GameTypeChipFun(
  {
    isActive = false,
    title,
    count,
    handleClick
  }: {
    isActive?: boolean;
    title: string;
    count: number;
    handleClick?: () => void;
  },
  ref
) {
  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        cursor: 'pointer',
        padding: '6px 10px',
        borderRadius: '60px',
        backgroundColor: isActive ? colors.info.dark : 'transparent',
        '&:hover': {
          backgroundColor: colors.info.dark
        },
        flexShrink: 0
      }}
      onClick={handleClick}
    >
      <Box sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>{title}</Box>
      <Box
        sx={{
          padding: '2px 3px',
          borderRadius: '100px',
          backgroundColor: 'common.white',
          color: colors.info.dark,
          fontSize: '12px',
          fontWeight: 700,
          lineHeight: '12px'
        }}
      >
        {count}
      </Box>
    </Box>
  );
});

const HomePage: React.FunctionComponent = () => {
  const { dispatch, state } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [banners, setBanners] = useState<BannerModel[]>([]);
  const [itemsPerSection, setItemsPerSection] = useState(17);
  const [itemsContainerClass, setItemsContainerClass] = useState('');
  const { mutate, isLoading } = useMutation(BannersMainQuery, {
    onSuccess: ({ data }) => {
      const currency = state.user.data?.currency?.toUpperCase() ?? 'EUR';
      const bannersData = (data as BannerModel[]).map((b) => ({
        ...b,
        userCurrency: b.currencies.find((c) => c.name.toUpperCase() === currency)
      }));
      setBanners(bannersData as BannerModel[]);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('BannersMainQuery');
    }
  });

  const setItemsBasedOnScreenWidth = () => {
    let newValue = 6;
    let newClass = '';
    const screenWidth = document.body.clientWidth;

    if (screenWidth <= 570) {
      newValue = 16;
      newClass = 'multiline-container--570';
    } else if (screenWidth <= 710) {
      newValue = 19;
      newClass = 'multiline-container--710';
    } else if (screenWidth <= 900) {
      newValue = 19;
      newClass = 'multiline-container--900';
    } else if (screenWidth <= 1099) {
      newValue = 17;
    } else if (screenWidth <= 1438) {
      newValue = 19;
    } else if (screenWidth <= 1655) {
      newValue = 24;
    } else if (screenWidth <= 1933) {
      newValue = 23;
    } else if (screenWidth <= 2211) {
      newValue = 27;
    } else if (screenWidth <= 2488) {
      newValue = 31;
    } else {
      newValue = 26;
    }

    setItemsPerSection(newValue);
    setItemsContainerClass(newClass);
  };

  useEffect(() => {
    mutate();
    // Initial height calculation
    setItemsBasedOnScreenWidth();

    // Listen for window resize events and update height
    window.addEventListener('resize', setItemsBasedOnScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setItemsBasedOnScreenWidth);
    };
  }, []);

  const navigateToAllGames = (type?: GameTypesEnum) =>
    navigate(PublicRoutes.Games, type ? { state: { type } } : {});

  const gameChipsDto = [
    {
      title: t('homePage.gameContainerTitles.all'),
      count: state.games.length,
      isActive: true,
      handleClick: () => navigateToAllGames()
    },
    {
      title: t('gamesPage.gameTypes.slots'),
      count: state.games.filter((game) => game.gameType === 'Slot').length,
      handleClick: () => navigateToAllGames(GameTypesEnum.Slots)
    },
    {
      title: t('gamesPage.gameTypes.live'),
      count: state.games.filter((game) => game.gameType === 'Live').length,
      handleClick: () => navigateToAllGames(GameTypesEnum.Live)
    },
    {
      title: t('gamesPage.gameTypes.table'),
      count: state.games.filter((game) => game.gameType === 'Table').length,
      handleClick: () => navigateToAllGames(GameTypesEnum.Table)
    },
    {
      title: t('gamesPage.gameTypes.favorite'),
      count: state.user?.data
        ? state.games.filter((g) => state.user?.data?.favGames.includes(g.id)).length
        : 0,
      handleClick: () => navigateToAllGames(GameTypesEnum.Favorite)
    }
  ];

  const sortAndSlice = (games: IGameDto[]) =>
    games.sort((a, b) => a.weight - b.weight).slice(0, itemsPerSection);

  const topRatedGames = sortAndSlice(state.games.filter((g) => g.isTop));
  const slotGames = sortAndSlice(state.games.filter((g) => g.showOnHome && g.type === 'slots'));
  const liveGames = sortAndSlice(state.games.filter((g) => g.sectionName === 'live'));
  const tableGames = sortAndSlice(state.games.filter((g) => g.type !== 'slots'));

  return (
    <Box>
      <BannerContainerNew
        banners={
          isUserLoggedIn() ? banners.filter((b) => !b.isPublic) : banners.filter((b) => b.isPublic)
        }
      />
      <Box
        sx={{
          backgroundColor: colors.background.blue,
          backdropFilter: 'blur(5px)',
          marginBottom: { xs: '24px', md: '32px' },
          padding: '24px 16px',
          overflowX: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            flexWrap: 'nowrap',
            width: 'max-content',
            margin: 'auto'
          }}
        >
          {gameChipsDto.map((chip, i) => (
            <Slide in timeout={200 + i * 100} key={`transition-${i}`}>
              <GameTypeChip
                key={i}
                title={chip.title}
                count={chip.count}
                isActive={chip.isActive ?? false}
                handleClick={chip.handleClick ?? undefined}
              />
            </Slide>
          ))}
        </Box>
      </Box>
      {topRatedGames.length > 0 && (
        <HomeGameSection
          isSingleRow
          title={t('homePage.gameContainerTitles.topRated')}
          gameChipsLength={gameChipsDto.length}
          games={topRatedGames}
        />
      )}
      <HomeGameSection
        title={t('homePage.gameContainerTitles.slot')}
        buttonProps={{
          title: `${t('homePage.gameContainerTitles.all')} >`,
          handleClick: () => navigateToAllGames(GameTypesEnum.Slots)
        }}
        gameChipsLength={gameChipsDto.length}
        games={slotGames}
        itemsContainerClass={itemsContainerClass}
      />
      {liveGames.length > 0 && (
        <HomeGameSection
          isSingleRow
          title={t('homePage.gameContainerTitles.liveDealer')}
          buttonProps={{
            title: `${t('homePage.gameContainerTitles.all')} >`,
            handleClick: () => navigateToAllGames(GameTypesEnum.Live)
          }}
          gameChipsLength={gameChipsDto.length}
          games={liveGames}
        />
      )}
      {tableGames.length > 0 && (
        <HomeGameSection
          title={t('homePage.gameContainerTitles.table')}
          buttonProps={{
            title: `${t('homePage.gameContainerTitles.all')} >`,
            handleClick: () => navigateToAllGames(GameTypesEnum.Table)
          }}
          gameChipsLength={gameChipsDto.length}
          games={tableGames}
        />
      )}
      <PromoBannerContainer />
      <TournamentBanner />
      <BottomBanner />
      <Bubbles />
      <Loader loading={!isGameLoaded || isLoading} transperant={false} />
    </Box>
  );
};

export default HomePage;
