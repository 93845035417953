import { Box, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { colors } from '../../../utils/theme';
import { getBorderColor } from '../../Account/SignupPage';
import Label from './Label';

interface IProps {
  id: string;
  value: string | number | moment.Moment | null;
  placeholder?: string;
  label?: string;
  isLabelMainColor?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMsg?: string | null;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  type?: 'text' | 'number' | 'password';
  disabled?: boolean;
  isSecondaryStyle?: boolean;
  className?: string;
  isDarkTheme?: boolean;
}

const InputField: React.FunctionComponent<IProps> = ({
  id,
  value,
  label,
  isLabelMainColor,
  onChange,
  errorMsg,
  startIcon,
  endIcon,
  placeholder,
  type,
  disabled,
  isSecondaryStyle,
  className,
  isDarkTheme
}: IProps) => {
  const disabledDarkColor = 'rgba(255, 255, 255, 0.50)';
  return (
    <Box sx={{ width: '100%' }}>
      {label && (
        <Label
          id={id}
          label={label}
          isMainColor={isLabelMainColor}
          sx={{
            color: disabled && isDarkTheme ? disabledDarkColor : isDarkTheme ? '#FFFFFF' : '#FFFFFF'
          }}
        />
      )}
      <TextField
        className={className}
        id={id}
        name={id}
        type={type ?? 'text'}
        fullWidth
        value={value}
        onChange={onChange}
        color="info"
        disabled={disabled ?? false}
        {...(errorMsg !== undefined && {
          required: true,
          error: errorMsg !== null,
          helperText: errorMsg ?? ''
        })}
        sx={{
          height: '46px',
          '& .MuiOutlinedInput-root': {
            ...(isSecondaryStyle && {
              backgroundColor: value ? `${colors.white} !important` : 'default'
            }),
            '& fieldset': {
              background: isDarkTheme ? '#25274A' : colors.white,
              borderColor: isDarkTheme ? '#25274A' : getBorderColor(value),
              ...(isSecondaryStyle && {
                background: 'rgba(255, 255, 255, 0.1)',
                borderColor: 'rgb(227,171,0) !important'
              })
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor:
                disabled && isDarkTheme ? disabledDarkColor : isDarkTheme ? '#FFFFFF' : ''
            },
            ...(isSecondaryStyle && {
              color: 'common.black',
              '&.Mui-focused': {
                backgroundColor: `${colors.white} !important`
              }
            })
          }
        }}
        inputProps={{
          sx: {
            color: isDarkTheme ? '#FFFFFF' : '',
            height: '22px',
            fontSize: '16px',
            lineHeight: '22px',
            padding: '12px 16px',
            paddingLeft: startIcon ? '0px' : '16px',
            zIndex: 2,
            '&:-webkit-autofill': {
              transition: 'background-color 5000s ease-in-out 0s',
              WebkitTextFillColor: colors.info.main
            }
          }
        }}
        {...(placeholder && { placeholder })}
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : null,
          endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null
        }}
      />
    </Box>
  );
};

export default InputField;
