import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box, Fade } from '@mui/material';
//* Icons
import StarsIcon from '@mui/icons-material/Stars';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//* Models
import { ExchangeLevel } from '../../../../../models/shop';
//* Utils
import { getBadge, getUserCurrencySymbol } from '../../../../../utils/ui';
//* Styles
import './ShopLevelMeter.scss';

interface IProps {
  levels: ExchangeLevel[];
}

const ShopLevelMeter: React.FunctionComponent<IProps> = ({ levels }) => {
  const { t } = useTranslation();
  const { state } = useContext(AppContext);
  const [selectedLevelRank, setSelectedLevelRank] = useState(
    state.user.data?.challengeLevel?.rank || 0
  );
  const [availableLevels, setAvailableLevels] = useState<ExchangeLevel[]>([]);

  useEffect(() => {
    const sortedAvailableLevels: ExchangeLevel[] = levels.sort((a, b) =>
      a.rank > b.rank ? 1 : -1
    );

    setAvailableLevels(sortedAvailableLevels);
  }, []);

  const renderAvailableLevels = () => {
    return availableLevels.map((level, index) => {
      const levelName = level.name.replace('Bubble ', '');

      return (
        <Box
          key={index}
          className={`level-meter__level ${
            selectedLevelRank === level.rank ? 'level-meter__level--is-selected' : ''
          }`}
          onClick={() => setSelectedLevelRank(level.rank)}
        >
          {state.user.data?.challengeLevel?.rank === level.rank && (
            <Box className="level-meter__level-current">
              <CheckCircleIcon />
            </Box>
          )}
          <Box
            id="badge-img"
            component="img"
            src={getBadge(index)}
            className="level-meter__level-image"
          />
          <Box className="level-meter__level-text">{levelName}</Box>
        </Box>
      );
    });
  };

  const getSelectedLevelRates = () => {
    const selectedLevel = levels.find((level) => level.rank === selectedLevelRank);

    return selectedLevel;
  };

  const selectedLevelRates = getSelectedLevelRates();
  const userCurrency = getUserCurrencySymbol(state.user.data?.currency);

  return (
    <>
      <Box className="level-meter">{renderAvailableLevels()}</Box>
      <Box className="level-meter__info">
        <Box className="level-meter__info-row">
          <span>1 {t('wheel.bonusTypes.wheelSpin')}</span>
          <span>=</span>
          <Fade in key={`${selectedLevelRank}-${selectedLevelRates?.wheelRate}`} timeout={750}>
            <span>
              {selectedLevelRates?.wheelRate}
              <StarsIcon className="level-meter__info-row-icon" />
            </span>
          </Fade>
        </Box>
        <Box className="level-meter__info-row">
          <span>
            1{userCurrency} {t('wheel.bonusTypes.bonusMoney')}
          </span>
          <span>=</span>
          <Fade in key={`${selectedLevelRank}-${selectedLevelRates?.bonusRate}`} timeout={750}>
            <span>
              {selectedLevelRates?.bonusRate}
              <StarsIcon className="level-meter__info-row-icon" />
            </span>
          </Fade>
        </Box>
      </Box>
    </>
  );
};

export default ShopLevelMeter;
