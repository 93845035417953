import React from 'react';
//* MUI
import { Box, Pagination, PaginationItem, Stack } from '@mui/material';
//* MUI Icons
import { ArrowBack, ArrowForward } from '@mui/icons-material';
//* Styles
import './CustomPagination.scss';

interface IProps {
  page: number;
  totalItems: number;
  itemsPerPage: number;
  setPage: (value: number) => void;
}

const CustomPagination: React.FunctionComponent<IProps> = ({
  page,
  setPage,
  totalItems,
  itemsPerPage
}: IProps) => {
  const numberOfPages = Math.trunc((totalItems - 1) / itemsPerPage) + 1;

  const getPaginationItemComponent = (itemType = 'previous') => {
    let paginationItemComponent = <ArrowBack />;

    if (itemType === 'next') {
      paginationItemComponent = <ArrowForward />;
    }

    return <Box className="pagination-item-nav">{paginationItemComponent}</Box>;
  };

  return (
    <Stack spacing={2} className="pagination-container">
      <Pagination
        page={page}
        defaultPage={0}
        siblingCount={0}
        count={numberOfPages}
        className="pagination"
        onChange={(_, value) => setPage(value)}
        renderItem={(item) => {
          return (
            <PaginationItem
              className={`pagination-item ${item.selected ? 'pagination-item--is-selected' : ''}`}
              components={{
                previous: () => getPaginationItemComponent(),
                next: () => getPaginationItemComponent('next')
              }}
              {...item}
            />
          );
        }}
      />
    </Stack>
  );
};

export default CustomPagination;
