import React from 'react';
import { Box } from '@mui/material';
import './BannerChildTextBox.scss';

interface IProps {
  text: string;
}

const BannerChildTextBox = ({ text }: IProps) => {
  return <Box className="banner-child-text-box-container">{text}</Box>;
};

export default BannerChildTextBox;
