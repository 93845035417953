import React from 'react';
import { Box } from '@mui/material';
import './AccountDetailsBalanceBox.scss';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';
import './AccountPersonalDetailsEditMessageBox.scss';

const AccountPersonalDetailsEditMessageBox: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Box className="edit-message-container">
      <ErrorIcon sx={{ width: '24px', height: '24px' }} />
      <Box className="edit-message-container__text-box">
        {t('myAccount.accountPage.editDetailsMessage')}
      </Box>
    </Box>
  );
};

export default AccountPersonalDetailsEditMessageBox;
