import React from 'react';
import { SavedSearch } from '@mui/icons-material';
import './EmptySection.scss';

interface IProps {
  text: string;
}

const EmptySection: React.FunctionComponent<IProps> = ({ text }: IProps) => {
  return (
    <div className="empty-bonus-section">
      <div className="empty-bonus-section__save">
        <SavedSearch />
      </div>
      <div className="empty-bonus-section__text">{text}</div>
    </div>
  );
};

export default EmptySection;
