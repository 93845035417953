import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITransaction } from '../../../models/wallet';
import { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { useMutation, useQueryClient } from 'react-query';
import { CancelTransaction } from '../../../queries/wallet';
import { Actions } from '../../../enums/ActionEnums';
import { handleError, getUserCurrencySymbol } from '../../../utils/ui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  useMediaQuery
} from '@mui/material';
import CustomPagination from '../../Common/CustomPagination/CustomPagination';
import Loader from '../../Common/Loader';
import './AccountTransactionsTable.scss';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { colors } from '../../../utils/theme';

interface IProps {
  data: ITransaction[];
  handleRemove: (id: number) => void;
}

const itemsPerPage = 10;

const showDeleteIcon = (status: string, type: string) => {
  return status.toLowerCase() === 'pending' && type.toLowerCase() === 'withdrawal';
};

const AccountTransactionsTable: React.FunctionComponent<IProps> = ({
  data,
  handleRemove
}: IProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { dispatch, state } = useContext(AppContext);
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(CancelTransaction, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.UpdateBalance,
        payload: data.balance
      });
      handleRemove(data.id);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('wallet.historyPage.cancelWithdrawMessage')
        }
      });
      dispatch({
        type: Actions.HideConfirmation
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('CancelTransaction');
    }
  });
  const showAccorionLayout = useMediaQuery('(max-width:767px)');
  const getVisibleData = () =>
    data.slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage);

  const handleRemovePendingWithdraw = (id: number) => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `${t('wallet.historyPage.cancelConfirmationMessage')} ?`,
        agreeAction: () => mutate(id)
      }
    });
  };

  return (
    <Box className="transactions-table-container">
      <Box className="transactions-table-container__headers-container">
        <Box className="transactions-table-container__headers-container__fixed-box">
          {t('wallet.historyPage.transactionHeader')}
        </Box>
        <Box className="transactions-table-container__headers-container__auto-box">
          {t('wallet.historyPage.typeHeader')}
        </Box>
        <Box className="transactions-table-container__headers-container__auto-box">
          {t('wallet.historyPage.statusHeader')}
        </Box>
        <Box className="transactions-table-container__headers-container__last-box">
          {t('wallet.historyPage.amountHeader')}
        </Box>
      </Box>
      {getVisibleData().map((t, index) => {
        const transactionRow = (
          <Box
            key={index}
            className={`transactions-table-container__row-cointainer ${
              index % 2 === 0 && !showAccorionLayout ? 'background' : ''
            }`}
          >
            <Box className="transactions-table-container__row-cointainer__fixed-box">
              <Box className="transactions-table-container__row-cointainer__fixed-box__icon-box">
                {t.type.toLowerCase() === 'deposit' ? <SouthWestIcon /> : <NorthEastIcon />}
              </Box>
              <Box className="transactions-table-container__row-cointainer__fixed-box__id-date-box">
                <Box className="transactions-table-container__row-cointainer__fixed-box__id-date-box__id-box">
                  {`#${t.id}`}
                </Box>
                <Box className="transactions-table-container__row-cointainer__fixed-box__id-date-box__type-box">
                  {`${t.type}`}
                </Box>
                <Box className="transactions-table-container__row-cointainer__fixed-box__id-date-box__date-box">
                  {moment(t.date).format('DD/MM/YYYY HH:mm')}
                </Box>
              </Box>
            </Box>
            <Box className="transactions-table-container__row-cointainer__auto-box__type">
              {t.type}
            </Box>
            <Box className="transactions-table-container__row-cointainer__auto-box">
              <Box
                className={`transactions-table-container__row-cointainer__auto-box__status--${t.status.toLowerCase()}`}
              ></Box>
              <Box className="transactions-table-container__row-cointainer__auto-box__status__box">
                {t.status}
              </Box>
            </Box>
            <Box
              className={`transactions-table-container__row-cointainer__last-box } ${
                t.status.toLowerCase() === 'completed' ? '' : 'disabled'
              }`}
            >
              {`${t.type.toLowerCase() === 'withdrawal' ? '-' : '+'}${getUserCurrencySymbol(
                state.user.data?.currency
              )}${t.amount.toString().replace('-', '')}`}{' '}
              {showDeleteIcon(t.status, t.type) && (
                <Box className="transactions-table-container__row-cointainer__last-box__icon">
                  <IconButton
                    aria-label="remove"
                    onClick={() => handleRemovePendingWithdraw(t.id)}
                    sx={{ color: colors.white }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        );
        return showAccorionLayout ? (
          <Accordion
            key={index}
            sx={{
              backgroundColor: index % 2 === 0 ? 'rgba(255, 255, 255, 0.05)' : 'transparent',
              boxShadow: 'none',
              margin: 0,
              padding: 0,
              width: '100%',
              borderRadius: '8px'
            }}
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIosIcon
                  sx={{
                    color: colors.white,
                    width: '20px',
                    height: '20px'
                  }}
                />
              }
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                padding: 0,
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: 'transparent',
                  width: '100%',
                  height: '80%',
                  color: colors.white
                },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  color: colors.white,
                  mr: '10px'
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(90deg)'
                },
                backgroundColor: 'transparent',
                width: '100%',
                borderRadius: '8px'
              }}
            >
              {transactionRow}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: 0,
                width: '100%'
              }}
            />
            <Box className="accordion-container">
              <Box className="accordion-container__id-type-box">
                <Box className="accordion-container__id-type-box__id-box">{`#${t.id}`}</Box>
                <Box className="accordion-container__id-type-box__type-box">{`${t.type}`}</Box>
              </Box>
              {showDeleteIcon(t.status, t.type) && (
                <Box className="accordion-container__delete-icon">
                  <IconButton
                    aria-label="remove"
                    onClick={() => handleRemovePendingWithdraw(t.id)}
                    sx={{ color: colors.white }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Accordion>
        ) : (
          transactionRow
        );
      })}
      <CustomPagination itemsPerPage={10} totalItems={data.length} page={page} setPage={setPage} />
      <Loader loading={isLoading} />
    </Box>
  );
};

export default AccountTransactionsTable;
