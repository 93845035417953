import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
import moment from 'moment';
//* Mui
import { DatePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Autocomplete, Box, InputAdornment, InputLabel, TextField } from '@mui/material';
//* MUI Icons
import { ArrowDropDown, Remove } from '@mui/icons-material';
//* Components
import Loader from '../Common/Loader';
import TransactionTable from './TransactionTable';
import { labelStyle } from '../Account/SigninPage';
import { getBorderColor, inputPropsStyle } from '../Account/SignupPage';
//* Queries
import { GetTransactions } from '../../queries/wallet';
//* Models
import { ITransaction, ITransactionOption } from '../../models/wallet';
//* Utils
import { colors } from '../../utils/theme';
import { handleError } from '../../utils/ui';
import { containerProps, fieldColor, transactionTypeOptions } from '../../utils/wallet';

const HistoryPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [transactionOption, setTransactionOption] = useState<ITransactionOption | null>(null);
  const [openPickerFrom, setOpenPickerFrom] = useState(false);
  const [openPickerTo, setOpenPickerTo] = useState(false);
  const [periodFrom, setPeriodFrom] = useState<moment.Moment | null>(null);
  const [periodTo, setPeriodTo] = useState<moment.Moment | null>(null);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [filteredData, setFilteredData] = useState<ITransaction[]>([]);
  const { mutate, isLoading } = useMutation(GetTransactions, {
    onSuccess: ({ data }) => {
      const dbTransactions = data as ITransaction[];
      setTransactions(dbTransactions);
      setFilteredData(dbTransactions);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('GetTransactions');
    }
  });

  useEffect(() => {
    mutate();
  }, []);

  const handleChange = (_: unknown, value: ITransactionOption | null) => {
    setTransactionOption(value);
    applyFilters(value, periodFrom, periodTo);
  };

  const applyFilters = (
    type: ITransactionOption | null,
    from: moment.Moment | null,
    to: moment.Moment | null
  ) => {
    let result =
      type === null || type.id === 1
        ? transactions
        : type.id === 2
        ? transactions.filter((t) => t.type.toLocaleLowerCase() === 'deposit')
        : transactions.filter((t) => t.type.toLocaleLowerCase() === 'withdrawal');
    if (from) {
      result = result.filter((r) => moment(r.date) > from);
    }
    if (to) {
      result = result.filter((r) => moment(r.date) < to);
    }
    setFilteredData(result);
  };

  const handleRemove = (id: number) => {
    setTransactions(transactions.filter((t) => t.id !== id));
    setFilteredData(filteredData.filter((f) => f.id !== id));
  };

  return (
    <Box sx={containerProps}>
      <Box
        sx={{
          display: 'flex',
          alignItems: { md: 'center', xs: 'left' },
          flexFlow: { md: 'row', xs: 'column' },
          gap: '16px'
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '34px',
            textAlign: 'left'
          }}
        >
          {t('wallet.historyPage.title')}
        </Box>
        <Box
          sx={{
            flexGrow: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            flexFlow: { md: 'row', xs: 'column' }
          }}
        >
          <Box>
            <InputLabel
              htmlFor="transactionType"
              style={{ ...labelStyle, color: colors.white, textAlign: 'left', top: 0 }}
            >
              {t('wallet.historyPage.transactionTypeLabel')}
            </InputLabel>
            <Autocomplete
              id="transactionTypeAutocomplete"
              fullWidth
              value={transactionOption}
              onChange={handleChange}
              options={transactionTypeOptions}
              getOptionLabel={(option) => t(option.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="transactionType"
                  name="transactionType"
                  margin="normal"
                  variant="outlined"
                  sx={{
                    height: '34px',
                    width: { md: '160px', xs: '295px' },
                    margin: 0,
                    '& .MuiOutlinedInput-root': {
                      height: '34px',
                      padding: '0 !important',
                      backgroundColor: transactionOption ? `${colors.white} !important` : 'default',
                      color: 'primary.main',
                      '& fieldset': {
                        borderColor: getBorderColor(transactionOption as unknown as string, 'white')
                      },
                      '&.Mui-focused': {
                        backgroundColor: `${colors.white} !important`
                      }
                    }
                  }}
                  placeholder={t(`wallet.historyPage.transactionTypePlaceholder`) as string}
                  color="info"
                  inputProps={{
                    ...params.inputProps,
                    sx: { ...inputPropsStyle, padding: '6px 11px !important' }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      backgroundColor: fieldColor
                    }
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <InputLabel style={{ ...labelStyle, color: colors.white, textAlign: 'left', top: 0 }}>
                {t('wallet.historyPage.periodLabel')}
              </InputLabel>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <DatePicker
                  open={openPickerFrom}
                  onOpen={() => setOpenPickerFrom(true)}
                  onClose={() => setOpenPickerFrom(false)}
                  value={periodFrom}
                  onChange={(value) => {
                    if (value && value.isValid()) {
                      value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                    }
                    setPeriodFrom(value);
                    applyFilters(transactionOption, value, periodTo);
                  }}
                  inputFormat="DD/MM/YYYY"
                  maxDate={periodTo}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="periodFrom"
                      name="periodFrom"
                      onClick={() => setOpenPickerFrom(true)}
                      variant="outlined"
                      color="info"
                      sx={{
                        marginTop: '16px',
                        marginBottom: '8px',
                        height: '34px',
                        width: { md: '120px', xs: '139.5px' },
                        margin: 0,
                        '& .MuiOutlinedInput-root': {
                          height: '34px',
                          padding: '0 !important',
                          backgroundColor: periodFrom ? `${colors.white} !important` : 'default',
                          color: 'primary.main',
                          '& fieldset': {
                            borderColor: getBorderColor(periodFrom?.toISOString(), 'white')
                          },
                          '&.Mui-focused': {
                            backgroundColor: `${colors.white} !important`
                          }
                        }
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: t(`wallet.historyPage.periodFromPlaceholder`) as string,
                        sx: { ...inputPropsStyle, padding: '6px 11px !important' }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          backgroundColor: fieldColor
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <ArrowDropDown
                              sx={{
                                fill: getBorderColor(periodFrom?.toISOString(), 'white'),
                                marginRight: '5px',
                                marginLeft: '-20px',
                                cursor: 'pointer'
                              }}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <Box
                  component="span"
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Remove sx={{ color: 'common.white', width: '16px', height: '16px' }} />
                </Box>
                <DatePicker
                  open={openPickerTo}
                  onOpen={() => setOpenPickerTo(true)}
                  onClose={() => setOpenPickerTo(false)}
                  value={periodTo}
                  onChange={(value) => {
                    if (value && value.isValid()) {
                      value.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
                    }
                    setPeriodTo(value);
                    applyFilters(transactionOption, periodFrom, value);
                  }}
                  inputFormat="DD/MM/YYYY"
                  minDate={periodFrom}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="periodTo"
                      name="periodTo"
                      onClick={() => setOpenPickerTo(true)}
                      variant="outlined"
                      color="info"
                      sx={{
                        marginTop: '16px',
                        marginBottom: '8px',
                        height: '34px',
                        width: { md: '120px', xs: '139.5px' },
                        margin: 0,
                        '& .MuiOutlinedInput-root': {
                          height: '34px',
                          padding: '0 !important',
                          backgroundColor: periodTo ? `${colors.white} !important` : 'default',
                          color: 'primary.main',
                          '& fieldset': {
                            borderColor: getBorderColor(periodTo?.toISOString(), 'white')
                          },
                          '&.Mui-focused': {
                            backgroundColor: `${colors.white} !important`
                          }
                        }
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: t(`wallet.historyPage.periodToPlaceholder`) as string,
                        sx: { ...inputPropsStyle, padding: '6px 11px !important' }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          backgroundColor: fieldColor
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <ArrowDropDown
                              sx={{
                                fill: getBorderColor(periodTo?.toISOString(), 'white'),
                                marginRight: '5px',
                                marginLeft: '-20px',
                                cursor: 'pointer'
                              }}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: '36px' }}>
        <TransactionTable data={filteredData} handleRemove={handleRemove} />
      </Box>
      <Loader loading={isLoading} />
    </Box>
  );
};

export default HistoryPage;
