import React from 'react';
//* MUI
import { Box } from '@mui/material';
//* MUI Icons
import { DeleteOutlined } from '@mui/icons-material';
//* Components
import CustomSwitch from '../Switch/CustomSwitch';
import CustomIconButton from '../Buttons/CustomIconButton/CustomIconButton';
//* Models
import { IMyAccountRowElement } from '../../../models/wallet';
//* Styles
import './MyAccountRowElement.scss';

interface IProps {
  disabled?: boolean;
  element: IMyAccountRowElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange?: (element: any) => void;
  selectedElementId?: string | number | undefined;
  handleConfirmation?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const MyAccountRowElement = ({
  element,
  disabled,
  handleChange,
  selectedElementId,
  handleConfirmation
}: IProps) => {
  return (
    <Box
      className={`element-row ${
        selectedElementId === element.id ? 'element-row--is-selected' : ''
      } ${element.highlighted ? 'element-row--is-highlighted' : ''} ${
        disabled ? 'element-row--is-disabled' : ''
      } ${element.customControls ? 'element-row--with-custom-controls' : ''}`}
      onClick={() => {
        if (handleChange) {
          handleChange(element);
        }
      }}
    >
      <Box className="element-row__details">
        {element.logo && (
          <Box className="element-row__details-logo">
            <Box component="img" src={element.logo} className={element.customClass} />
          </Box>
        )}
        <Box className="element-row__details-text">
          <Box className="element-row__details-text-main">
            {element.title}
            {element.titleChips}
          </Box>
          <Box className="element-row__details-text-additional">{element.subtitle}</Box>
        </Box>
      </Box>
      <Box className="element-row__controls">
        {!element.disableExtraControls && (
          <CustomIconButton
            color="transparent"
            icon={<DeleteOutlined />}
            onClick={(event) => {
              if (handleConfirmation) {
                handleConfirmation(element, event);
              }
            }}
            iconColor={selectedElementId === element.id ? 'darkblue' : 'white'}
          />
        )}
        {element.customControls && (
          <Box className="element-row__controls-custom">{element.customControls}</Box>
        )}
        {!element.disableToggle && <CustomSwitch checked={selectedElementId === element.id} />}
      </Box>
    </Box>
  );
};

export default MyAccountRowElement;
