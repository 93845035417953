export enum GameTypesEnum {
  All = 'All Games',
  Popular = 'Popular',
  Classic = 'Classic',
  Slots = 'Slots',
  Provider = 'Provider',
  Casino = 'casino',
  Roulette = 'roulette',
  Poker = 'casinoholdem',
  Blackjack = 'blackjack',
  Baccarat = 'baccarat',
  DragonTiger = 'dragontiger',
  TeenPatti = 'teen-patti',
  Favorite = 'Favorite',
  Live = 'Live',
  Table = 'Table',
  Jackpots = 'Jackpots'
}
