import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import { BannerType } from '../../enums/BannerEnums';
import { AppContext } from '../../AppContext';
import BannerChildTextBox from './BannerChildTextBox';
import { BonusPromotions } from '../../queries/bonus';
import { Promotion } from '../../models/bonus';
import { handleError } from '../../utils/ui';
import { useTranslation } from 'react-i18next';
import ShopBalanceBox from '../Common/ShopBalanceBox';
import StarsIcon from '@mui/icons-material/Stars';
import { isUserLoggedIn } from '../../utils/validators';
import { Query } from '../../enums/RequestEnums';
import { AllTournamentsQuery } from '../../queries/tournaments';
import { ITournament } from '../../models/tournaments';
import BannerTimer from './BannerTimer';
import { AllChallengesQuery } from '../../queries/challenges';
import LevelInformation from '../Navigation/Mobile/LevelInformation/LevelInformation';
import { calculateCompletedChallengesPercentage } from '../../utils/accountDetails';

type EarnMoreBannerChildProps = { type: string };

const EarnMoreBannerChild: React.FunctionComponent<EarnMoreBannerChildProps> = ({ type }) => {
  const threeMinutes = 3 * 60 * 1000;
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AppContext);
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const promotionsQuery = useQuery({
    queryKey: ['Promotions'],
    queryFn: BonusPromotions,
    onSuccess: ({ data }) => {
      setPromotions(data as Promotion[]);
    },
    refetchInterval: threeMinutes,
    onError: ({ response }) => handleError(dispatch, response)
  });

  const tournamentsQuery = useQuery(Query.AllTournaments, AllTournamentsQuery, {
    onSuccess: ({ data }) => {
      const tournamentsData = data as ITournament[];

      return tournamentsData;
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const tournamentsData = tournamentsQuery.data?.data.tournaments
    ? [...tournamentsQuery?.data?.data.tournaments]
    : [];
  const filteredTournamentsData = tournamentsData.filter((tournament) => !tournament.isActive);
  const sortedTournamentsData = filteredTournamentsData.sort((a, b) => {
    return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
  });

  const challengesQuery = useQuery(Query.AllChallenges, AllChallengesQuery, {
    onSuccess: ({ data }) => {
      setGroupData(data?.groupData);
      setPlaterData(data?.playerData);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const [groupData, setGroupData] = useState(challengesQuery.data?.data.groupData || []);
  const [playerData, setPlaterData] = useState(challengesQuery.data?.data.playerData || []);
  const [challengeLevelId, setChallengeLevelId] = useState(
    state.user.data?.challengeLevel?.id ?? 1
  );

  const percentage = calculateCompletedChallengesPercentage(
    groupData,
    challengeLevelId,
    playerData
  );

  useEffect(() => {
    if (promotionsQuery.data) {
      setPromotions(promotionsQuery.data.data);
    }
  }, [promotionsQuery.data]);

  const renderChildComponent = () => {
    switch (type) {
      case BannerType.Promotion:
        return (
          <BannerChildTextBox
            text={t('homePage.earnMoreBanners.promotions.youHaveCoupons', {
              coupons: isUserLoggedIn() ? promotions.length : 0
            })}
          />
        );
      case BannerType.Shop:
        return (
          <ShopBalanceBox
            hideCurrency
            loading={state.loadingBalance}
            title={t('shop.titles.pointsBalance')}
            className="shop-balance-box-earn-more-banner-container"
            showOnEarnMoreBanner={true}
            value={state.user.data?.loyaltyPoints ?? 0}
            icon={<StarsIcon className="summary__price-icon" />}
          />
        );
      case BannerType.Challenges:
        return (
          isUserLoggedIn() && (
            <LevelInformation
              loyaltyPoints={state.user.data?.loyaltyPoints ?? 0}
              onLinkClick={() => true}
              completedChallengesPercentage={percentage}
              showViewMap={false}
            />
          )
        );
      case BannerType.Tournaments:
        return (
          sortedTournamentsData.length > 0 && (
            <BannerTimer
              startDate={sortedTournamentsData[0]?.startDate}
              endDate={sortedTournamentsData[0]?.endDate}
              text={`${t('tournaments.card.timer.nextTournamentIn')}:`}
            />
          )
        );
      case BannerType.LuckyWheel:
        return (
          <BannerTimer text={`${t('wheel.freeWheelSpinIn')}:`} showTimeLeftTillMidnight={true} />
        );
      default:
        return null;
    }
  };
  return <Box>{renderChildComponent()}</Box>;
};

export default EarnMoreBannerChild;
