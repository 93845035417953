import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box } from '@mui/material';
//* Models
import { IFreeSpinExchange } from '../../../models/shop';
//* Styles
import './FreeSpinItem.scss';

interface IProps {
  freeSpin: IFreeSpinExchange;
  prize: null | number | undefined;
  selected: boolean;
  setSelected: (input: IFreeSpinExchange | null) => void;
}

const FreeSpinItem: React.FunctionComponent<IProps> = forwardRef(function FreeSpinItem(props, ref) {
  const { t } = useTranslation();
  // eslint-disable-next-line react/prop-types
  const { selected, setSelected, freeSpin } = props as IProps;

  // eslint-disable-next-line react/prop-types
  const getAmount = () => freeSpin.amount;

  return (
    <Box
      {...props}
      ref={ref}
      className={`free-spins-item ${selected && 'free-spins-item--is-selected'}`}
      onClick={() => setSelected(selected ? null : freeSpin)}
    >
      <Box>{getAmount()}</Box>
    </Box>
  );
});

export default FreeSpinItem;
