import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box, Fade } from '@mui/material';
//* Components
import ShopBalanceBox from '../../Common/ShopBalanceBox';
import InfoBox from '../../Common/Dialogs/InfoBox';
import ShopInfoBoxContent from './ShopInfoBoxContent/ShopInfoBoxContent';
//* Icons
import StarsIcon from '@mui/icons-material/Stars';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
//* Queries
import { AppContext } from '../../../AppContext';
//* Models
import { ExchangeLevel } from '../../../models/shop';

interface IProps {
  page: number;
  levels: ExchangeLevel[];
}

const ShopAdditionalInfo: React.FunctionComponent<IProps> = ({ page, levels }) => {
  const { t } = useTranslation();
  const { state } = useContext(AppContext);
  const [howItWorksIsVisible, setHowItWorksIsVisible] = useState(false);

  const shopPageTitle: { [index: number]: string } = {
    0: t('shop.navigation.wheel'),
    1: t('shop.navigation.bonus'),
    2: t('shop.navigation.freeSpins')
  };

  const infoBoxActionData = {
    text: 'OK',
    onClick: () => setHowItWorksIsVisible(false)
  };

  return (
    <Box className="shop-page__row">
      <Box className="shop-page__row-item--wide">
        <Fade in key={page} timeout={1000}>
          <span className="shop-page__title-text">{shopPageTitle[page]}</span>
        </Fade>
        <button className="custom-text-icon-button" onClick={() => setHowItWorksIsVisible(true)}>
          {t('challenges.howItWorks')} <HelpOutlineIcon />
        </button>
      </Box>
      <InfoBox
        extraHeight
        open={howItWorksIsVisible}
        title={t('navigation.shop')}
        actionData={infoBoxActionData}
        onClose={() => setHowItWorksIsVisible(false)}
      >
        <ShopInfoBoxContent levels={levels} />
      </InfoBox>
      <ShopBalanceBox
        hideCurrency
        loading={state.loadingBalance}
        title={t('shop.titles.pointsBalance')}
        className="shop-page__row-item--narrow"
        value={state.user.data?.loyaltyPoints ?? 0}
        icon={<StarsIcon className="summary__price-icon" />}
      />
    </Box>
  );
};

export default ShopAdditionalInfo;
