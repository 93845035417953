import React from 'react';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box } from '@mui/material';
//* Styles
import './WithdrawalSummary.scss';

interface IProps {
  amount: number | null;
  userCurrency: string;
}

const WithdrawalSummary: React.FunctionComponent<IProps> = ({ amount, userCurrency }) => {
  const { t } = useTranslation();

  return (
    <Box className="withdrawal-summary">
      <Box className="withdrawal-summary__title">{t('wallet.withdrawPage.withdrawalSummary')}</Box>
      <Box className="withdrawal-summary__additional">
        <Box>{t('wallet.withdrawPage.amountLabel')}</Box>
        <Box>
          {userCurrency}
          {amount || 0}
        </Box>
      </Box>
      <Box className="withdrawal-summary__additional">
        <Box>{t('wallet.withdrawPage.fees')}</Box>
        <Box>0%</Box>
      </Box>
      <Box className="withdrawal-summary__total">
        <Box>{t('wallet.depositPage.total')}</Box>
        <Box>
          {userCurrency}
          {amount || 0}
        </Box>
      </Box>
    </Box>
  );
};

export default WithdrawalSummary;
