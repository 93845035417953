import React, { useContext } from 'react';
import { CircleLoader } from 'react-spinners';
import { AppContext } from '../../AppContext';
//* MUI
import { Box } from '@mui/material';
//* Components
import Badge from '../Challenges/Badge';
//* Utils
import { getUserCurrencySymbol } from '../../utils/ui';
//* Styles
import './BalanceBox.scss';

interface IProps {
  title: string;
  value: number | string;
  icon?: JSX.Element;
  hideCurrency?: boolean;
  loading?: boolean;
  isNav?: boolean;
  className?: string;
  showOnEarnMoreBanner?: boolean;
}

const BalanceBox: React.FunctionComponent<IProps> = ({
  title,
  value,
  icon,
  hideCurrency = false,
  loading = false,
  className,
  showOnEarnMoreBanner = false
}: IProps) => {
  const { state } = useContext(AppContext);

  return (
    <Box className={`balance-box ${className}`}>
      {!showOnEarnMoreBanner && (
        <Badge
          showName
          withBackground
          name={state.user.data?.challengeLevel?.name}
          rank={state.user.data?.challengeLevel?.rank ?? 0}
        />
      )}
      <Box>
        {loading ? (
          <CircleLoader loading color="white" size={35} />
        ) : (
          <Box className="balance-box__title">
            <span
              className={
                showOnEarnMoreBanner
                  ? 'balance-box__title-text-earn-more'
                  : 'balance-box__title-text'
              }
            >
              {title}
            </span>
            <span
              className={
                showOnEarnMoreBanner
                  ? 'balance-box__title-currency-earn-more'
                  : 'balance-box__title-currency'
              }
            >
              {!hideCurrency ? getUserCurrencySymbol(state.user.data?.currency) : ''}
              {value}
              {icon}
            </span>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BalanceBox;
