import axios from 'axios';
import { AppConsts } from '../enums/AppConsts';

const baseUrl = process.env.BASE_SERVER_ADDRESS;
const getToken = () => localStorage.getItem(AppConsts.AccessToken);
const getHeaders = () => ({ headers: { Authorization: `Bearer ${getToken()}` } });

export const GetPlayerWheelSpins = async () => {
  return await axios.get(`${baseUrl}/wheel/GetPlayerSpins`, getHeaders());
};

export const GetWheelSpin = async () => {
  return await axios.get(`${baseUrl}/wheel/Spin`, getHeaders());
};

export const WheelElementsQuery = async () => {
  return await axios.get(`${baseUrl}/wheel/GetElements`);
};
