import React, { useContext } from 'react';
import { Box } from '@mui/material';

import './EarnMoreBannerBox.scss';
import PrimaryButton from '../Common/Buttons/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes, PublicRoutes } from '../../enums/RouteEnums';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';

type EarnMoreBannexBoxProps = {
  type: string;
  buttonText: string;
  title: string;
  body: string;
  children: React.ReactNode;
};

type RouteType = 'Promotion' | 'Shop' | 'Challenges' | 'Tournaments' | 'LuckyWheel';

const routeMappings: Record<RouteType, keyof typeof PublicRoutes> = {
  Promotion: 'Promotions',
  Shop: 'Shop',
  Challenges: 'Challenges',
  Tournaments: 'Tournaments',
  LuckyWheel: 'Wheel'
};

const EarnMoreBannerBox: React.FunctionComponent<EarnMoreBannexBoxProps> = ({
  type,
  buttonText,
  title,
  body,
  children
}) => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const routKey = routeMappings[type as keyof typeof routeMappings];
  const route = routKey ? PublicRoutes[routKey] : PublicRoutes.Home;

  const handleButtonClick = (route: string) => {
    if (route === PublicRoutes.Wheel) {
      if (state.user.data && state.user.data.hasDeposited) {
        dispatch({ type: Actions.ShowWheelPopup });
      } else {
        if (state.user.data === null) {
          dispatch({
            type: Actions.ShowLoginPopup,
            payload: {}
          });
        } else {
          dispatch({
            type: Actions.ShowPopup,
            payload: {
              state: 'You should deposit at least once to be able to play the lucky wheel',
              isCongrat: false,
              redirectUrl: AuthRoutes.Wallet,
              buttonText: 'Deposit Now'
            }
          });
        }
      }
    } else {
      navigate(route === PublicRoutes.Signup && state.user.data ? AuthRoutes.Wallet : route);
    }
  };
  return (
    <Box className={`earn-more-banner-container earn-more-banner-container--${type}`}>
      <Box className="earn-more-banner-container__child">{children}</Box>
      <Box className="earn-more-banner-container__banner">
        <Box className="earn-more-banner-container__banner__title">{title}</Box>
        <Box className="earn-more-banner-container__banner__body">{body}</Box>
        <PrimaryButton
          text={buttonText}
          className="earn-more-banner-container__banner__button"
          onClick={() => handleButtonClick(route)}
        />
      </Box>
    </Box>
  );
};

export default EarnMoreBannerBox;
