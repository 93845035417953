import React, { useContext, useEffect, useState } from 'react';
import { ArrowForwardIosSharp, ConfirmationNumber } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  SxProps
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { AuthRoutes } from '../../enums/RouteEnums';
import { IActiveBonus, IBonus, IHistoryBonus } from '../../models/bonus';
import {
  ClaimBonus,
  DeclineBonus,
  PlayerActiveBonus,
  PlayerPastBonuses,
  PlayerSuggestedBonuses
} from '../../queries/bonus';
import { colors } from '../../utils/theme';
import CancelButton from '../Common/Buttons/CancelButton';
import PrimaryButton from '../Common/Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../Common/Buttons/SecondaryButton';
import InputField from '../Common/Fields/InputField';
import Loader from '../Common/Loader';
import { handleError } from '../../utils/ui';
import InlineLoader from '../Common/InlineLoader/InlineLoader';
import EmptySection from './EmptySection/EmptySection';
import SuggestedBonus from './SuggestedBonus/SuggestedBonus';
import {
  accordionDetailsProps,
  accordionProps,
  dropDownIconProps,
  getAccordionSummaryProps
} from '../../utils/wallet';

const boxStyles: SxProps = {
  background: colors.background.blue,
  padding: { xs: '24px', md: '32px' },
  borderRadius: '8px',
  display: 'flex',
  flexFlow: 'column',
  gap: '32px'
};

const headerStyles: SxProps = {
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '34px',
  color: 'common.white'
};

const activeBonusDetailsProps: SxProps = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px'
};

const itemBoxStyle: SxProps = {
  background: colors.background.grey,
  border: `1px solid ${colors.white}`,
  borderRadius: '8px',
  color: 'common.white',
  padding: { xs: '10px', md: '14px' },
  display: 'flex',
  flexFlow: 'column',
  gap: '4px'
};

const pastBonusPerView = 8;
const depositNeededBonusTypes = ['Deposit', 'Registration', 'Turnover', 'Verification', 'Birthday'];

const AccountBonusPage: React.FunctionComponent = () => {
  const threeMinutes = 3 * 60 * 1000;

  const suggestedBonusesQuery = useQuery({
    queryKey: ['SuggestedBonuses'],
    queryFn: PlayerSuggestedBonuses,
    onSuccess: ({ data }) => {
      const res = data as IBonus[];
      setSuggestedBonus(res);
    },
    refetchInterval: threeMinutes,
    onError: ({ response }) => handleError(dispatch, response)
  });

  const pastBonusesQuery = useQuery({
    queryKey: ['PassedBonuses'],
    queryFn: PlayerPastBonuses,
    onSuccess: ({ data }) => {
      setPastBonuses(data);
    },
    refetchInterval: threeMinutes,
    onError: ({ response }) => handleError(dispatch, response)
  });

  const activeBonusQuery = useQuery({
    queryKey: ['PlayerActiveBonus'],
    queryFn: PlayerActiveBonus,
    onSuccess: ({ data }) => {
      setActiveBonus(data);
    },
    refetchInterval: threeMinutes,
    onError: ({ response }) => handleError(dispatch, response)
  });

  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [activeBonusExpand, setActiveBonusExpand] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [pastBonusesPage, setPastBonusesPage] = useState(1);
  const [suggestedBonus, setSuggestedBonus] = useState<IBonus[]>(
    suggestedBonusesQuery.data?.data ?? []
  );
  const [activeBonus, setActiveBonus] = useState<IActiveBonus | null>(
    activeBonusQuery.data?.data ?? null
  );
  const [pastBonuses, setPastBonuses] = useState<IHistoryBonus[]>(
    pastBonusesQuery.data?.data ?? []
  );

  const { mutate, isLoading } = useMutation(ClaimBonus, {
    onSuccess: ({ data }) => {
      const typedData = data as IActiveBonus;
      setSuggestedBonus(suggestedBonus.filter((sb) => sb.code !== typedData.name));
      setActiveBonus(typedData);
      dispatch({
        type: Actions.UpdateBonusBalance,
        payload: {
          bonusBalance: typedData.playerBonusBalance as number
        }
      });
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Bonus ${typedData.name} ${t('myAccount.bonusPage.claimed')}`
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('ClaimBonus');
    }
  });

  const declineBonusQuery = useMutation(DeclineBonus, {
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('DeclineBonus');
    }
  });

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries('SuggestedBonuses');
      queryClient.invalidateQueries('PassedBonuses');
      queryClient.invalidateQueries('PlayerActiveBonus');
    };
  }, []);

  const handleCouponChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setCouponCode(value);
  };

  const handleActivateCoupon = (code: string) => {
    mutate(code);
  };

  const handleClaimBonus = (sb: IBonus) => {
    if (depositNeededBonusTypes.includes(sb.type)) {
      navigate(AuthRoutes.Wallet, { state: { bonus: sb } });
    } else {
      handleActivateCoupon(sb.code);
    }
  };

  const handleDeclineBonus = (sb: IBonus) => {
    setSuggestedBonus(suggestedBonus.filter((b) => b.id !== sb.id));
    declineBonusQuery.mutate(sb.code);
  };

  const getActiveBonusProgress = () => {
    let result = 0;
    if (activeBonus && activeBonus.wagered && activeBonus.toBeWagered) {
      result = Math.round((activeBonus.wagered / activeBonus.toBeWagered) * 100);
    }

    return result;
  };

  return (
    <Box sx={{ py: '112px' }}>
      <Box sx={{ width: { xs: '91%', md: '875px' }, margin: 'auto', textAlign: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexFlow: { xs: 'column', md: 'row' },
            gap: { xs: '32px', md: '16px' },
            textAlign: 'left'
          }}
        >
          <Box
            sx={{
              width: { xs: 'auto', md: '540px' },
              display: 'flex',
              flexFlow: 'column',
              gap: { xs: '32px', md: '16px' }
            }}
          >
            <Box sx={boxStyles}>
              <Box sx={headerStyles}>{t('myAccount.bonusPage.activeTitle')}</Box>
              {activeBonusQuery.isLoading ? (
                <InlineLoader />
              ) : (
                <>
                  {activeBonus ? (
                    <Accordion
                      expanded={activeBonusExpand}
                      onChange={() => setActiveBonusExpand(!activeBonusExpand)}
                      sx={{ ...accordionProps, marginTop: '0 !important' }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowForwardIosSharp sx={dropDownIconProps} />}
                        aria-controls={`panel-payment-summary-content`}
                        id={`panel-payment-summary-header`}
                        sx={{
                          ...getAccordionSummaryProps(1, activeBonusExpand ? 1 : 0),
                          marginTop: 0
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexFlow: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <Box sx={{ display: 'flex', flexFlow: 'column', flexGrow: 1 }}>
                            <Box sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '22px' }}>
                              {activeBonus.name}
                            </Box>
                            <Box sx={{ fontSize: '12px', lineHeight: '17px', marginTop: '4px' }}>
                              `${t('myAccount.bonusPage.expires')}:`{' '}
                              {activeBonus.expires
                                ? moment(activeBonus.expires).format('DD/MM/YYYY HH:mm:ss')
                                : ''}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              flexGrow: 0,
                              marginRight: '20px',
                              display: 'flex',
                              flexFlow: 'row',
                              alignItems: 'center',
                              gap: '8px'
                            }}
                          >
                            <Box
                              sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
                            >
                              <CircularProgress
                                variant="determinate"
                                value={100}
                                sx={{
                                  color: 'common.white',
                                  height: '36px !important',
                                  width: '36px !important'
                                }}
                                thickness={6}
                              />
                              <CircularProgress
                                variant="determinate"
                                value={getActiveBonusProgress()}
                                sx={{
                                  position: 'absolute',
                                  color: colors.gradients.yellowRgb,
                                  height: '36px !important',
                                  width: '36px !important'
                                }}
                                thickness={6}
                              />
                            </Box>
                            <Box>{getActiveBonusProgress()}%</Box>
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails sx={{ ...accordionDetailsProps, textAlign: 'left' }}>
                        <Divider
                          sx={{ background: 'white', marginTop: '-8px', marginBottom: '12px' }}
                        />
                        <Box
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                            gap: '4px 8px'
                          }}
                        >
                          <Box sx={{ ...activeBonusDetailsProps, color: colors.grey.main }}>
                            `${t('myAccount.bonusPage.toBeWagered')}:`
                          </Box>
                          <Box sx={activeBonusDetailsProps}>
                            {activeBonus.toBeWagered.toFixed(2)}
                          </Box>
                          <Box sx={{ ...activeBonusDetailsProps, color: colors.grey.main }}>
                            `${t('myAccount.bonusPage.wagered')}:`
                          </Box>
                          <Box sx={activeBonusDetailsProps}>{activeBonus.wagered.toFixed(2)}</Box>
                          <Box sx={{ ...activeBonusDetailsProps, color: colors.grey.main }}>
                            `${t('myAccount.bonusPage.freeSpins')}:`
                          </Box>
                          <Box sx={activeBonusDetailsProps}>{activeBonus.freeSpins?.amount}</Box>
                          <Box sx={{ ...activeBonusDetailsProps, color: colors.grey.main }}>
                            `${t('myAccount.bonusPage.betValuePerSpinInCent')}:`
                          </Box>
                          <Box sx={activeBonusDetailsProps}>
                            {activeBonus.freeSpins?.betValue.toFixed(2)}
                          </Box>
                          <Box sx={{ ...activeBonusDetailsProps, color: colors.grey.main }}>
                            `${t('myAccount.bonusPage.game')}:`
                          </Box>
                          <Box sx={activeBonusDetailsProps}>{activeBonus.freeSpins?.gameName}</Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <EmptySection text={t('myAccount.bonusPage.activeEmpty')} />
                  )}
                </>
              )}
            </Box>
            <Box sx={boxStyles}>
              <Box sx={headerStyles}>{t('myAccount.bonusPage.suggestedTitle')}</Box>
              {suggestedBonusesQuery.isLoading ? (
                <InlineLoader />
              ) : (
                <>
                  {suggestedBonus && suggestedBonus.length ? (
                    suggestedBonus.map((suggestedBonus, index) => (
                      <SuggestedBonus
                        key={index}
                        bonus={suggestedBonus}
                        disableClaim={activeBonus !== null}
                        handleClaim={handleClaimBonus}
                        handleDecline={handleDeclineBonus}
                      />
                    ))
                  ) : (
                    <EmptySection text={t('myAccount.bonusPage.suggestedEmpty')} />
                  )}
                </>
              )}
            </Box>
            <Box sx={boxStyles}>
              <Box sx={headerStyles}>{t('myAccount.bonusPage.couponTitle')}</Box>
              <Box>
                <InputField
                  id="coupon"
                  value={couponCode}
                  onChange={handleCouponChange}
                  label={t('wallet.depositPage.yourCoupon') as string}
                  placeholder={t('wallet.depositPage.couponPlaceholder') as string}
                  isSecondaryStyle={true}
                  startIcon={
                    <ConfirmationNumber sx={{ color: couponCode ? 'info.main' : 'common.white' }} />
                  }
                />
                <PrimaryButton
                  onClick={() => handleActivateCoupon(couponCode)}
                  text={t('myAccount.bonusPage.couponActivateButton')}
                  additionalStyle={{ mt: '16px' }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: 'auto', md: '318px' }
            }}
          >
            <Box sx={boxStyles}>
              <Box sx={headerStyles}>{t('myAccount.bonusPage.pastTitle')}</Box>
              {pastBonusesQuery.isLoading ? (
                <InlineLoader />
              ) : (
                <>
                  {pastBonuses && pastBonuses.length ? (
                    <Box sx={{ display: 'flex', flexFlow: 'column', gap: '16px' }}>
                      {pastBonuses
                        .slice(
                          (pastBonusesPage - 1) * pastBonusPerView,
                          (pastBonusesPage - 1) * pastBonusPerView + pastBonusPerView
                        )
                        .map((p, i) => (
                          <Box key={i} sx={itemBoxStyle}>
                            <Box sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '22px' }}>
                              {p.bonus.code}
                            </Box>
                            <Box sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>
                              Expires:{' '}
                              {p.dateDeactivated
                                ? moment(p.dateDeactivated).format('DD/MM/YYYY HH:mm:ss')
                                : ''}
                            </Box>
                          </Box>
                        ))}
                      {pastBonuses.length > pastBonusPerView * pastBonusesPage && (
                        <SecondaryButton
                          onClick={() => setPastBonusesPage(pastBonusesPage + 1)}
                          text={t('gamesPage.viewMoreButton')}
                          additionalStyle={{ color: 'common.white' }}
                        />
                      )}
                      {pastBonusesPage > 1 && (
                        <CancelButton
                          text={t('gamesPage.viewLessButton') as string}
                          onClick={() => setPastBonusesPage(pastBonusesPage - 1)}
                          additionalStyle={{ color: 'common.white' }}
                        />
                      )}
                    </Box>
                  ) : (
                    <EmptySection text={t('myAccount.bonusPage.pastEmpty')} />
                  )}
                </>
              )}
            </Box>
          </Box>
          <Loader loading={isLoading} />
        </Box>
      </Box>
    </Box>
  );
};

export default AccountBonusPage;
