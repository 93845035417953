import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import './SecurityAndSettings.scss';
import AccountStatus from '../AccountDetails/AccountStatus';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
import KycWidget from '../KycWidget';
import { useTranslation } from 'react-i18next';
import CustomSwitch from '../../Common/Switch/CustomSwitch';
import CancelButton from '../../Common/Buttons/CancelButton';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SetPlayermarketingPropQuery } from '../../../queries/player';
import { AppContext, InitialStateType } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import { handleError } from '../../../utils/ui';
import { AccountDetailsStatuses, UserModel } from '../../../models/account';
import { AppConsts } from '../../../enums/AppConsts';
import { ActionType } from '../../../models/appcontext';
import UpdatePasswordForm from './UpdatePasswordForm';
import { Query } from '../../../enums/RequestEnums';
import { GetAccountDetailsQuery } from '../../../queries/profile';
import { AccountDetailsModel } from '../../../models/profile';

const SecurityAndSettings: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { dispatch, state } = useContext(AppContext);
  const getAccountDetails = useQuery({
    queryKey: Query.GetPlayerDetails,
    queryFn: GetAccountDetailsQuery,
    onSuccess: ({ data }) => {
      setVerificationStatus((data as AccountDetailsModel).verificationStatus);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const [verificationStatus, setVerificationStatus] = useState(
    getAccountDetails.data?.data.verificationStatus || ''
  );
  const [openKycWidget, setOpenKycWidget] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [enableEmailNotifications, setEnableEmailNotifications] = useState<boolean>(() => {
    const storedValue = localStorage.getItem('enableEmailNotifications');
    return storedValue ? JSON.parse(storedValue) : state.user.data?.agreedEmailPromotions || false;
  });
  const [userId] = useState<string>(() => {
    return localStorage.getItem('userid') || state.user.data?.id || '';
  });
  useEffect(() => {
    if (state.user.data && state.user.data?.id !== undefined) {
      localStorage.setItem('enableEmailNotifications', JSON.stringify(enableEmailNotifications));
      localStorage.setItem('userid', state.user.data?.id);
    }
  }, []);

  const playerMarketingPrefQuery = useMutation(SetPlayermarketingPropQuery, {
    onSuccess: ({ data }) => {
      const token = data.accessToken as string;
      const newEmailNotificationsPreference = !enableEmailNotifications;
      updateUserInState(state, dispatch, token, {
        agreedEmailPromotions: newEmailNotificationsPreference
      });
      setEnableEmailNotifications(newEmailNotificationsPreference);
      localStorage.setItem(
        'enableEmailNotifications',
        JSON.stringify(newEmailNotificationsPreference)
      );
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${
            newEmailNotificationsPreference
              ? t('myAccount.accountPage.emailNotificationsEnabledMessage')
              : t('myAccount.accountPage.emailNotificationsDisabledMessage')
          }`
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('SetPlayermarketingPropQuery');
    }
  });

  const updateUserInState = (
    state: InitialStateType,
    dispatch: React.Dispatch<ActionType>,
    token: string,
    updatedFields: Partial<UserModel>
  ) => {
    const currentUserData = state.user.data as UserModel;
    const newUserData = { ...currentUserData, ...updatedFields };
    dispatch({
      type: Actions.SetUser,
      payload: {
        data: newUserData,
        token: token
      }
    });
    localStorage.setItem(AppConsts.AccessToken, token);
  };

  const handleMarketingPreferenceChange = () => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `${t('myAccount.accountPage.emailNotificationsConfirmationMessage')} ?`,
        agreeAction: marketingPreferenceConfirmed
      }
    });
  };

  const marketingPreferenceConfirmed = () => {
    dispatch({
      type: Actions.HideConfirmation
    });
    playerMarketingPrefQuery.mutate({
      playerId: userId,
      propertyName: 'agreedEmailPromotions',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      value: !enableEmailNotifications
    });
  };
  return (
    <Box className="settings-container">
      <Box className="settings-container__title">{'Security & Settings'}</Box>
      <Box className="settings-container__row-container">
        <Box className="settings-container__row-container__text-box">{'Verification'}</Box>
        <Box className="settings-container__row-container__status-button-box">
          <Box>
            <AccountStatus />
          </Box>
          {verificationStatus !== AccountDetailsStatuses.Verified && (
            <Box className="settings-container__row-container--verify-button-box-row">
              <PrimaryButton
                onClick={() => setOpenKycWidget(true)}
                text={t('wallet.withdrawPage.verifyAccountButton') as string}
                className="verify-button"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box className="settings-container__row-container--verify-button-box-column">
        <PrimaryButton
          onClick={() => setOpenKycWidget(true)}
          text={t('wallet.withdrawPage.verifyAccountButton') as string}
          className="verify-button"
        />
      </Box>
      <Box className="settings-container__horizontal-line" />
      <Box className="settings-container__row-container">
        <Box className="settings-container__row-container__text-box">{'Email Notifications'}</Box>
        <Box>
          <CustomSwitch
            checked={enableEmailNotifications as boolean}
            handleChecked={() => handleMarketingPreferenceChange()}
          />
        </Box>
      </Box>
      <Box className="settings-container__horizontal-line" />
      <Box className="settings-container__row-container-password">
        <Box className="settings-container__row-container-password__text-box">{'Password'}</Box>
        <Box className="settings-container__row-container-password__cancel-button-box">
          <CancelButton
            text={t('myAccount.accountPage.passwordPage.actionButton') as string}
            onClick={() => setOpenForm(true)}
            additionalStyle={{
              color: 'common.white',
              height: '42px !important',
              padding: '10px 32px !important',
              border: '2px solid #FFF !important'
            }}
          />
        </Box>
      </Box>
      {openKycWidget && <KycWidget handleClose={() => setOpenKycWidget(false)} />}
      {openForm && <UpdatePasswordForm handleClose={() => setOpenForm(false)} />}
    </Box>
  );
};

export default SecurityAndSettings;
