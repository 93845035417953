import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { countries } from '../../utils/countries';
import AccessDenied from './AccessDenied/AccessDenied';

interface IProps {
  children: React.ReactNode;
}

const restrictedCountriesBubbles = ['Bulgaria'];
const restrictedCountriesLicense = [
  'Bulgaria',
  'United Kingdom',
  'United States of America',
  'Australia',
  'Austria',
  'France',
  'Germany',
  'Netherlands',
  'Spain'
];
const FATFBlacklist = [
  'KP', // North Korea
  'IR', // Iran,
  'MM' // Myanmar
];
const Locator: React.FunctionComponent<IProps> = ({ children }: IProps) => {
  const [userCountry, setUserCountry] = useState('');
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const isDev = process.env.ENVIRONMENT === 'develop';
  const isClone = process.env.ENVIRONMENT === 'licence';
  const restrictedCountries = isClone ? restrictedCountriesLicense : restrictedCountriesBubbles;

  useEffect(() => {
    const checkAndRedirect = async () => {
      try {
        if (!userCountry || userCountry.length === 0) {
          const response = await axios.get('https://ipapi.co/json/');
          const { data } = response;
          const country = countries.find((c) => c.code === data.country)?.label ?? '';

          const blacklisted = FATFBlacklist.includes(data.country);
          setIsBlacklisted(blacklisted);
          setUserCountry(country);
        }
      } catch (error) {
        console.error('Error fetching geo info:', error);
      }
    };

    checkAndRedirect();
  }, []);

  const shouldRestrict = () => {
    return (
      !isDev &&
      userCountry.length > 0 &&
      (restrictedCountries.includes(userCountry) || isBlacklisted)
    );
  };
  return <Fragment>{shouldRestrict() ? <AccessDenied /> : children}</Fragment>;
};

export default Locator;
