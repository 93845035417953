import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Link, Paper, Step, StepLabel, Stepper } from '@mui/material';
import { PublicRoutes } from '../../enums/RouteEnums';
import backGround from '../../assets/register.svg';
import logo from '../../assets/logo_light.svg';
import logoClone from '../../assets/logo_clone_light.svg';
import { useTranslation } from 'react-i18next';
import { colors } from '../../utils/theme';
import Step1 from './SignupSteps/Step1';
import Step2 from './SignupSteps/Step2';
import Step3 from './SignupSteps/Step3';
import { isPasswordValid, isValidEmail } from '../../utils/validators';
import { countries, CountryType } from '../../utils/countries';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { currencies, CurrencyType } from '../../utils/currencies';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import { RegisterQuery } from '../../queries/account';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { RegisterModel } from '../../models/account';
import Loader from '../Common/Loader';
import { handleError } from '../../utils/ui';
import axios from 'axios';

export type IErrors = {
  firstName: string | null;
  lastName: string | null;
  birthday: string | null;
  email: string | null;
  country: string | null;
  phone: string | null;
  phoneCode: string | null;
  address: string | null;
  city: string | null;
  postCode: string | null;
  username: string | null;
  password: string | null;
  confirmPassword: string | null;
  currency: string | null;
};

const defaultErrorsObj: IErrors = {
  firstName: null,
  lastName: null,
  birthday: null,
  email: null,
  country: null,
  phone: null,
  phoneCode: null,
  address: null,
  city: null,
  postCode: null,
  username: null,
  password: null,
  confirmPassword: null,
  currency: null
};

const steps = [0, 1, 2];

const getCompletedDto = () => {
  const result: {
    [k: number]: boolean;
  } = {};
  steps.forEach((s) => (result[s] = false));
  return result;
};

export const getIconColor = (value: string | undefined | null, formErorrValue: string | null) => {
  return formErorrValue ? colors.error.main : value ? colors.info.main : colors.grey.main;
};

export const getBorderColor = (
  value: string | number | moment.Moment | null | undefined | unknown,
  defaultColor = 'default'
) => {
  return value ? colors.info.main : defaultColor;
};

export const inputPropsStyle = {
  height: '22px !important',
  padding: '12px 0 !important',
  '&:-webkit-autofill': {
    WebkitTextFillColor: colors.info.main
  }
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-45% + 10px)',
    right: 'calc(45% + 14px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.info.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.info.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.grey.main,
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

const getMinBirthdayDate = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  now.setFullYear(now.getFullYear() - 18);
  return moment(now);
};

const SignupPage: React.FunctionComponent = () => {
  const isClone = process.env.ENVIRONMENT === 'licence';

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AppContext);
  const { affid, cxd } = state.affiliateData;

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(getCompletedDto());
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [birthday, setBirthday] = useState<moment.Moment | null>(null);
  const [email, setEmail] = useState<string>('');

  const [country, setCountry] = useState<CountryType | null>(null);
  const [phone, setPhone] = useState<string>('');
  const [phoneCountry, setPhoneCountry] = useState<CountryType | null>(null);
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [postCode, setPostCode] = useState<string>('');

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [currency, setCurrency] = useState<CurrencyType | null>(currencies[0]);
  const [termsAndCond, setTermsAndCond] = useState<boolean>(false);
  const [smsPromo, setSmsPromo] = useState<boolean>(true);
  const [emailPromo, setEmailPromo] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(RegisterQuery, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowPopup,
        payload: {
          isCongrat: true,
          state: t('account.confirmationRegistration'),
          buttonText: 'OK'
        }
      });
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('account.successfullRegistration')
        }
      });
      navigate(PublicRoutes.Home);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('signup');
    }
  });

  if (state.user.data) {
    navigate(PublicRoutes.Games);
  }

  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        const userCountry = countries.find((c) => c.code === data.country) as CountryType;
        setCountry(userCountry);
        setPhoneCountry(userCountry);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, firstName: t('errors.signup.firstName') });
    } else {
      setFormErrors({ ...formErrors, firstName: null });
    }
    setFirstName(value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, lastName: t('errors.signup.lastName') });
    } else {
      setFormErrors({ ...formErrors, lastName: null });
    }
    setLastName(value);
  };

  const handleBirthdayChange = (value: moment.Moment | null) => {
    if (value && value.isValid()) {
      value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      if (value > getMinBirthdayDate()) {
        setFormErrors({ ...formErrors, birthday: t('errors.signup.under18') });
      } else {
        setFormErrors({ ...formErrors, birthday: null });
      }
    } else {
      setFormErrors({ ...formErrors, birthday: t('errors.signup.birthday') });
    }
    setBirthday(value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isValidEmail(value)) {
      setFormErrors({ ...formErrors, email: t('errors.signup.email') });
    } else {
      setFormErrors({ ...formErrors, email: null });
    }
    setEmail(value);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, city: t('errors.signup.city') });
    } else {
      setFormErrors({ ...formErrors, city: null });
    }
    setCity(value);
  };

  const handlePostCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, postCode: t('errors.signup.postCode') });
    } else {
      setFormErrors({ ...formErrors, postCode: null });
    }
    setPostCode(value);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, address: t('errors.signup.address') });
    } else {
      setFormErrors({ ...formErrors, address: null });
    }
    setAddress(value);
  };

  const handleCountryChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, country: t('errors.signup.country') });
    } else {
      setFormErrors({ ...formErrors, country: null });
    }
    setCountry(value);
  };

  const handlePhoneCodeChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, phoneCode: t('errors.signup.phoneCode') });
    } else {
      setFormErrors({ ...formErrors, phoneCode: null });
    }
    setPhoneCountry(value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    //TODO Adds better phone validation
    if (value.length < 4) {
      setFormErrors({ ...formErrors, phone: t('errors.signup.phone') });
    } else {
      setFormErrors({ ...formErrors, phone: null });
    }
    setPhone(value);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, username: t('errors.signup.username') });
    } else {
      setFormErrors({ ...formErrors, username: null });
    }
    setUsername(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({ ...formErrors, password: t('errors.signup.password') });
    } else {
      let errors = { ...formErrors };
      errors = { ...errors, password: null };
      if (confirmPassword) {
        if (value !== confirmPassword) {
          errors = { ...errors, confirmPassword: t('errors.signup.passwordNotTheSame') };
        } else {
          errors = { ...errors, confirmPassword: null };
        }
      }
      setFormErrors(errors);
    }
    setPassword(value);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({ ...formErrors, confirmPassword: t('errors.signup.confirmPassword') });
    } else {
      if (value !== password) {
        setFormErrors({ ...formErrors, confirmPassword: t('errors.signup.passwordNotTheSame') });
      } else {
        setFormErrors({ ...formErrors, confirmPassword: null });
      }
    }
    setConfirmPassword(value);
  };

  const handleCurrencyChange = (_: unknown, value: CurrencyType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, currency: t('errors.signup.currency') });
    } else {
      setFormErrors({ ...formErrors, currency: null });
    }
    setCurrency(value);
  };

  const handleTermsAndCondChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setTermsAndCond(value);
  };

  const handleSmsPromoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setSmsPromo(value);
  };

  const handleEmailPromoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setEmailPromo(value);
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      const res = await axios.get('https://api.ipify.org/?format=json');
      const model: RegisterModel = {
        firstName,
        lastName,
        birthDate: birthday?.format('YYYY-MM-DD') as string,
        email,
        country: (country?.label as string).replace(/\s/g, ''),
        phoneNumber: `+${phoneCountry?.phone as string}${phone}`,
        address,
        city,
        postcode: postCode,
        username,
        password,
        confirmPassword,
        currency: currency?.code as string,
        agreedEmailPromotions: emailPromo,
        agreedSmsPromotions: smsPromo,
        affiliateToken: cxd as string | null,
        affiliateId: affid as string | null,
        affiliate: cxd ? 'Cellxpert' : null,
        ipAddress: res.data.ip
      };
      mutate(model);
    } else {
      setActiveStep(activeStep + 1);
      setCompleted({ ...completed, [activeStep]: true });
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    if (activeStep === 2) {
      setCompleted({ ...completed, [activeStep]: false });
    } else {
      setCompleted({ ...completed, 0: false, 1: false });
    }
  };

  const isValidState = () => {
    if (activeStep === 0) {
      return isStep1Valid();
    } else if (activeStep === 1) {
      return isStep1Valid() && isStep2Valid();
    } else if (activeStep === 2) {
      return isStep1Valid() && isStep2Valid() && isStep3Valid();
    }
  };

  const isStep1Valid = () => {
    return (
      firstName.length >= 2 &&
      lastName.length >= 2 &&
      birthday !== null &&
      birthday.isValid() &&
      birthday <= getMinBirthdayDate() &&
      isValidEmail(email)
    );
  };

  const isStep2Valid = () => {
    return (
      // country &&
      phone.length >= 4 && address.length >= 2 && city.length >= 2 && postCode.length >= 2
    );
  };

  const isStep3Valid = () => {
    return (
      username.length > 4 &&
      currency !== null &&
      isPasswordValid(password) &&
      isPasswordValid(confirmPassword) &&
      password === confirmPassword &&
      termsAndCond
    );
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        component={Paper}
        square
        sx={{ display: 'flex', flexFlow: 'column' }}
      >
        <Box
          sx={{
            mt: {
              xs: '16px',
              sm: '16px',
              md: '45px'
            },
            ml: {
              xs: '16px',
              sm: '16px',
              md: '45px'
            },
            fontFamily: 'Poppins'
          }}
        >
          <img
            src={isClone ? logoClone : logo}
            alt="logo"
            onClick={() => navigate(PublicRoutes.Home)}
            style={{ cursor: 'pointer', width: '184px' }}
          />
        </Box>
        <Box sx={{ alignItems: 'center', flexGrow: 1, display: 'flex', flexFlow: 'column' }}>
          <Box sx={{ flexGrow: 1, maxWidth: '616px' }}>
            <Box sx={{ display: 'flex', flexFlow: 'row' }}>
              <Box
                sx={{
                  mx: {
                    xs: 2,
                    sm: 2,
                    md: 4
                  },
                  color: 'primary.main',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontFamily: 'Montserrat',
                  fontSize: {
                    xs: '28px',
                    sm: '28px',
                    md: '40px'
                  },
                  flexGrow: 1
                }}
              >
                {t('account.signUp')}
              </Box>
              <Box>
                <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index] as boolean}>
                      <StepLabel />
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Box>
            <Box
              sx={{
                mx: {
                  xs: 2,
                  sm: 2,
                  md: 4
                },
                color: 'primary.main',
                fontWeight: 400,
                fontStyle: 'normal',
                fontFamily: 'Poppins',
                fontSize: {
                  xs: '16px',
                  sm: '16px',
                  md: '18px'
                }
              }}
            >
              {t('account.signUpSubtitle')}
            </Box>
            <Box
              sx={{
                mx: {
                  xs: 2,
                  sm: 2,
                  md: 6
                }
              }}
            >
              <Box sx={{ mb: '32px' }}>
                {activeStep === 0 && (
                  <Step1
                    formErrors={formErrors}
                    firstName={firstName}
                    handleFirstNameChange={handleFirstNameChange}
                    lastName={lastName}
                    handleLastNameChange={handleLastNameChange}
                    birthday={birthday}
                    handleBirthdayChange={handleBirthdayChange}
                    email={email}
                    handleEmailChange={handleEmailChange}
                    minBirthday={getMinBirthdayDate()}
                  />
                )}
                {activeStep === 1 && (
                  <Step2
                    formErrors={formErrors}
                    address={address}
                    handleAddressChange={handleAddressChange}
                    city={city}
                    handleCityChange={handleCityChange}
                    postCode={postCode}
                    handlePostCodeChange={handlePostCodeChange}
                    country={country}
                    handleCountryChange={handleCountryChange}
                    phone={phone}
                    phoneCountry={phoneCountry}
                    handlePhoneCodeChange={handlePhoneCodeChange}
                    handlePhoneChange={handlePhoneChange}
                  />
                )}
                {activeStep === 2 && (
                  <Step3
                    formErrors={formErrors}
                    username={username}
                    handleUsernameChange={handleUsernameChange}
                    password={password}
                    handlePasswordChange={handlePasswordChange}
                    confirmPassword={confirmPassword}
                    handleConfirmPasswordChange={handleConfirmPasswordChange}
                    currency={currency}
                    handleCurrencyChange={handleCurrencyChange}
                    termsAndCond={termsAndCond}
                    handleTermsAndCondChange={handleTermsAndCondChange}
                    smsPromo={smsPromo}
                    handleSmsPromoChange={handleSmsPromoChange}
                    emailPromo={emailPromo}
                    handleEmailPromoChange={handleEmailPromoChange}
                  />
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexFlow: 'column' }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mb: '8px',
                    background: colors.gradients.yellow,
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    '&:hover': {
                      background: (t) => t.palette.common.white,
                      boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
                    },
                    color: 'primary.main'
                  }}
                  onClick={handleNext}
                  disabled={!isValidState() || isLoading}
                >
                  {activeStep === steps.length - 1
                    ? t('account.signupButtonText')
                    : t('account.nextButtonText')}
                </Button>
                {activeStep !== 0 && (
                  <Button
                    onClick={handleBack}
                    fullWidth
                    sx={{ color: 'info.main' }}
                    disabled={isLoading}
                  >
                    {t('account.backButtonText')}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              color: (t) => t.palette.grey[50],
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '14px',
              marginBottom: { xs: '0px', sm: '0px', md: '32px' }
            }}
          >
            {`${t('account.alreadyHaveAnAccount')}? `}
            <Link
              onClick={() => navigate(PublicRoutes.Signin)}
              variant="body2"
              color="info.main"
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              {t('account.signIn')}
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={4}
        sx={{
          backgroundImage: `url(${backGround})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => t.palette.primary.main,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />

      <Loader loading={isLoading} />
    </Grid>
  );
};

export default SignupPage;
