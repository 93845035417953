import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { Box, Dialog, SvgIcon } from '@mui/material';
import { Actions } from '../../../enums/ActionEnums';
import { Transition } from '../Dialogs/DialogBox';
import CustomIconButton from '../Buttons/CustomIconButton/CustomIconButton';
import CloseIcon from '@mui/icons-material/Close';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import CancelButton from '../Buttons/CancelButton';
import { SvgIconComponent } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import './Confirmation.scss';

const Confirmation: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);

  const handleAgree = () => {
    if (state.confirmation.agreeAction) {
      state.confirmation.agreeAction(state.confirmation.params);
    }
  };

  const handleClose = () => {
    dispatch({ type: Actions.HideConfirmation });
  };

  return (
    <Dialog
      open={state.confirmation.open}
      onClose={handleClose}
      classes={{ paper: 'confirmation-box' }}
      TransitionComponent={Transition}
    >
      <Box className="confirmation-box__close-button">
        <CustomIconButton icon={<CloseIcon />} onClick={handleClose} />
      </Box>
      <Box className="confirmation-box__content">
        {state.confirmation.icon && (
          <SvgIcon
            className="confirmation-box__content__icon"
            component={state.confirmation.icon as SvgIconComponent}
          />
        )}
        <Box>
          <Box className="confirmation-box__content__title">{state.confirmation.title}</Box>
          <Box className="confirmation-box__content__text">{state.confirmation.text}</Box>
        </Box>
      </Box>
      <Box className="confirmation-box__button-container">
        <CancelButton onClick={handleClose} text={t('common.button.cancel') as string} />
        <PrimaryButton onClick={handleAgree} text={t('common.button.approve') as string} />
      </Box>
    </Dialog>
  );
};

export default Confirmation;
