import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Theme
} from '@mui/material';
import logo from '../../assets/logo.svg';
import backGround from '../../assets/login.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { PublicRoutes } from '../../enums/RouteEnums';
import queryString from 'query-string';
import { useMutation, useQueryClient } from 'react-query';
import { ConfirmEmailQuery, NewEmailConfirmQuery } from '../../queries/account';
import { EmailConfirmModel } from '../../models/account';
import { AppContext } from '../../AppContext';
import { useTranslation } from 'react-i18next';
import { Actions } from '../../enums/ActionEnums';
import { isValidEmail } from '../../utils/validators';
import CSS from 'csstype';
import { colors } from '../../utils/theme';
import { Email } from '@mui/icons-material';
import { handleError } from '../../utils/ui';

const labelStyle: CSS.Properties = {
  top: '10px',
  color: colors.primary.main,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Poppins'
};

const ConfirmEmail = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const queryClient = useQueryClient();
  const confirmEmail = useMutation(ConfirmEmailQuery, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('account.emailConfirmed')
        }
      });
      navigate(PublicRoutes.Signin);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('ResetPasswordQuery');
    }
  });

  const newEmailConfirm = useMutation(NewEmailConfirmQuery, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('account.checkEmail')
        }
      });
      navigate(PublicRoutes.Signin);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('ResetPasswordQuery');
    }
  });

  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    const { email, token } = parsed;
    const model = {
      email,
      token
    } as EmailConfirmModel;
    confirmEmail.mutate(model);
  }, []);

  const handleSend = () => {
    newEmailConfirm.mutate(email);
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={8} component={Paper} square>
        <Box
          sx={{
            mt: {
              xs: '16px',
              sm: '16px',
              md: '45px'
            },
            ml: {
              xs: '16px',
              sm: '16px',
              md: '45px'
            },
            fontFamily: 'Poppins'
          }}
        >
          <img
            src={logo}
            alt="logo"
            onClick={() => navigate(PublicRoutes.Home)}
            style={{ cursor: 'pointer' }}
          />
        </Box>
        <Box
          sx={{
            my: {
              xs: 4,
              sm: 4,
              md: 8
            },
            mx: {
              xs: 2,
              sm: 2,
              md: 4
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              color: 'primary.main',
              fontWeight: 700,
              fontStyle: 'normal',
              fontFamily: 'Poppins',
              fontSize: {
                xs: '28px',
                sm: '28px',
                md: '40px'
              }
            }}
          >
            {t('account.confirmEmailTitle')}
          </Box>
          <Box
            sx={{
              mx: {
                xs: 2,
                sm: 2,
                md: 4
              },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                maxWidth: 548
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: '16px',
                    height: 66
                  }}
                >
                  <InputLabel htmlFor="email" style={labelStyle}>
                    {t('account.emailLabel')}
                  </InputLabel>
                  <TextField
                    margin="normal"
                    fullWidth
                    name="email"
                    type="text"
                    id="email"
                    color="info"
                    placeholder={t('account.passPlaceholder') as string}
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: '26px'
                  }}
                >
                  <Button
                    className="custom-button"
                    fullWidth
                    variant="contained"
                    disabled={
                      !isValidEmail(email) || newEmailConfirm.isLoading || confirmEmail.isLoading
                    }
                    onClick={handleSend}
                    sx={{
                      mt: { xs: 1, sm: 2, md: 3 },
                      mb: 2,
                      background: 'linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%)',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      '&:hover': {
                        background: (t) => t.palette.common.white,
                        boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
                      },
                      color: 'primary.main'
                    }}
                  >
                    {t('account.confirmEmailButtonText')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={4}
        sx={{
          backgroundImage: `url(${backGround})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t: Theme) => t.palette.primary.main,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
    </Grid>
  );
};

export default ConfirmEmail;
