import React from 'react';
import { t } from 'i18next';
//* MUI
import { Box } from '@mui/material';
//* Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
//* Components
import CustomChip from '../../Common/CustomChip/CustomChip';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
//* Styles
import './ChallengeIslandInfoBox.scss';
import { ChallengeGroup } from '../../../models/challenges';

interface IProps {
  islandIndex: number;
  currentLevel: number;
  islandGroupBadge: JSX.Element;
  islandGroupInfo: ChallengeGroup;
  handleClick: (islandGroupId: number) => void;
}

const ChallengeIslandInfoBox = ({
  islandIndex,
  handleClick,
  currentLevel,
  islandGroupInfo,
  islandGroupBadge
}: IProps) => {
  return (
    <Box
      className={`challenge-island-info ${
        islandIndex === 1 || islandIndex === 3 ? 'challenge-island-info--adjusted-position' : ''
      }`}
    >
      <Box className="challenge-island-info__row">
        <CustomChip label={`Level ${islandIndex + 1}`} />
        {currentLevel > islandIndex && (
          <CheckCircleIcon className="challenge-island-info__icon--is-completed" />
        )}
        {currentLevel < islandIndex && <LockOutlinedIcon className="challenge-island-info__icon" />}
      </Box>
      <Box className="challenge-island-info__row challenge-island-info__name">
        {islandGroupInfo?.name}
      </Box>
      <Box className="challenge-island-info__row">{islandGroupBadge}</Box>
      <Box className="challenge-island-info__row">
        <PrimaryButton
          text={`${t('challenges.viewTasks')}`}
          onClick={() => handleClick(islandIndex)}
          className="challenge-island-info__button"
        />
      </Box>
    </Box>
  );
};

export default ChallengeIslandInfoBox;
