import React, { useEffect, useRef, useState, useContext } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { containerPaddingX } from './HomeGameSection';
import { handleError } from '../../utils/ui';
import { useMutation } from 'react-query';
import { AppContext } from '../../AppContext';
import { HomeBannersQuery } from '../../queries/banner';
import { HomeBanner } from '../../models/banner';
import SingleLineArrowContainer from '../Common/SingleLineCointainer';
import EarnMoreBannerBox from './EarnMoreBannerBox';
import '../../components/Shop/ShopPage.scss';
import EarnMoreBannerChild from './EarnMoreBannerChild';

type BottomBannerProps = {
  showOnShopPage?: boolean;
};

const BottomBanner: React.FunctionComponent<BottomBannerProps> = ({ showOnShopPage = false }) => {
  const { i18n, t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const [banners, setBanners] = useState<HomeBanner[]>([]);
  const isClone = process.env.ENVIRONMENT === 'licence';

  useEffect(() => {
    if (!isClone) {
      bannersQuery.mutate();
    }
  }, []);

  const bannersQuery = useMutation(HomeBannersQuery, {
    onSuccess: ({ data }) => {
      if (data.length) {
        setBanners(data);
      }
    },
    onError: ({ response }) => handleError(response, dispatch)
  });

  const ref = useRef(null);

  return !bannersQuery.isLoading && banners.length > 0 ? (
    <>
      <Box
        sx={{
          pl: showOnShopPage
            ? { xs: '15px', sm: '70px', md: '70px' }
            : { xs: '15px', sm: '5%', md: '40px' },
          pr: { xs: '15px', md: '30px' }
        }}
      >
        <Box sx={{ display: 'flex', flexFlow: 'column', gap: '32px', position: 'relative' }}>
          {!showOnShopPage && (
            <Box
              sx={{
                ...containerPaddingX,
                fontSize: { xs: '24px', md: '64px' },
                fontWeight: { xs: 700, md: 600 },
                lineHeight: { xs: '34px', md: '90px' }
              }}
            >
              {t('homePage.bannerContainerTitles.earnMore')}
            </Box>
          )}
          <Box>
            <Box
              sx={{
                overflow: 'visible',
                position: 'relative',
                zIndex: 1
                //   '&::before,&::after': {
                //     content: '"*"',
                //     position: 'absolute',
                //     zIndex: 9,
                //     width: { xs: '90px', md: '180px' },
                //     height: '100%'
                //   },
                //   '&::before': {
                //     top: 0,
                //     left: 0,
                //     background:
                //       'linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
                //   },
                //   '&::after': {
                //     top: 0,
                //     right: 0,
                //     background:
                //       'linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
                //   }
              }}
            >
              <Box
                sx={{
                  overflow: 'hidden',
                  display: 'flex',
                  gap: '16px',
                  flexWrap: 'nowrap',
                  scrollBehavior: 'smooth',
                  scrollbarWidth: 'thin'
                  // '&::-webkit-scrollbar': {
                  //   display: 'none'
                  // },
                  // '@keyframes translateInfiniteLarge': {
                  //   '100%': {
                  //     transform: 'translateX(calc(-606px * 5))'
                  //   }
                  // },
                  // '@keyframes translateInfiniteSmall': {
                  //   '100%': {
                  //     transform: 'translateX(calc(-359px * 5))'
                  //   }
                  // },
                  // '&:hover': {
                  //   'div.promotionBanner': {
                  //     animationPlayState: 'paused'
                  //   }
                  // },
                  // 'div.promotionBanner': {
                  //   animation: `${
                  //     isMobileScreen ? 'translateInfiniteSmall' : 'translateInfiniteLarge'
                  //   } 25s linear infinite`
                  // }
                }}
                ref={ref}
              >
                <SingleLineArrowContainer
                  additionalStyle={{
                    gap: { xs: '30px', md: '30px' }
                  }}
                  showOnEarnMoreBanners={true}
                >
                  {banners
                    .filter((b) => b.type !== 'Bottom' && (!showOnShopPage || b.type !== 'Shop'))
                    .map((b, i) => (
                      <EarnMoreBannerBox
                        key={`banner-${i}`}
                        type={b.type}
                        title={
                          b.translations.find(
                            (t) => t.language.toUpperCase() === i18n.language.toUpperCase()
                          )?.title as string
                        }
                        body={
                          b.translations.find(
                            (t) => t.language.toUpperCase() === i18n.language.toUpperCase()
                          )?.body as string
                        }
                        buttonText={
                          b.translations.find(
                            (t) => t.language.toUpperCase() === i18n.language.toUpperCase()
                          )?.buttonText as string
                        }
                      >
                        <EarnMoreBannerChild type={b.type} />
                      </EarnMoreBannerBox>
                    ))}
                </SingleLineArrowContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          background:
            'linear-gradient(89.97deg, rgba(13, 19, 101, 0.5) 0.02%, rgba(36, 115, 249, 0.5) 52.22%, rgba(13, 19, 101, 0.5) 99.5%), rgba(37, 117, 252, 0.4)',
          backdropFilter: 'blur(5px)',
          paddingY: '80px',
          textAlign: 'left',
          ...containerPaddingX
        }}
      >
        <Box sx={{ fontSize: '40px', fontWeight: 600, lineHeight: '56px' }}>
          {
            banners
              .find((b) => b.type === BannerType.Bottom)
              ?.translations.find((tr) => tr.language.toUpperCase() === i18n.language.toUpperCase())
              ?.title
          }
        </Box>
        <Box sx={{ lineHeight: '22px' }}>
          {
            banners
              .find((b) => b.type === BannerType.Bottom)
              ?.translations.find((tr) => tr.language.toUpperCase() === i18n.language.toUpperCase())
              ?.body
          }
        </Box>
      </Box> */}
    </>
  ) : null;
};

export default BottomBanner;
