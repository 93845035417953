import React from 'react';
//* MUI
import { Box } from '@mui/material';
//* Styles
import './DepositAmountBox.scss';

interface IProps {
  userCurrency: string;
  paymentAmountOption: number;
  selectedPaymentAmount: number | null;
  handlePaymentAmountChange: (paymentAmount: number) => void;
}

const DepositAmountBox = ({
  userCurrency,
  paymentAmountOption,
  selectedPaymentAmount,
  handlePaymentAmountChange
}: IProps) => {
  const isSelected = selectedPaymentAmount === paymentAmountOption;

  return (
    <Box
      className={`amount-box ${isSelected ? 'amount-box--is-selected' : ''}`}
      onClick={() => {
        handlePaymentAmountChange(paymentAmountOption);
      }}
    >
      <Box component="span">
        {userCurrency}
        {paymentAmountOption}
      </Box>
    </Box>
  );
};

export default DepositAmountBox;
