import axios from 'axios';
import { AppConsts } from '../enums/AppConsts';

const baseUrl = process.env.BASE_SERVER_ADDRESS;
const getToken = () => localStorage.getItem(AppConsts.AccessToken);

const headers = { headers: { Authorization: `Bearer ${getToken()}` } };

export const BannersMainQuery = async () => {
  return await axios.get(`${baseUrl}/banner/GetAllActiveMainPage`, headers);
};

export const HomeBannersQuery = async () => {
  return await axios.get(`${baseUrl}/banner/GetAllHomePage`);
};
