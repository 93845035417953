import i18n from 'i18next';
import enTranslation from './en/translation.json';
import deTranslation from './de/translation.json';
import { initReactI18next } from 'react-i18next';
import { Page } from '../models/footerPage';
import { useQuery } from 'react-query';
import { handleError } from '../utils/ui';

// const footerContent = {
//   translations: [
//     {
//       language: 'en',
//       pageName: 'TermsAndConditions',
//       translation: {
//         title: 'Terms And Conditions',
//         content:
//           '1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum sit tellus ornare purus facilisis. Hac tortor vulputate integer elementum dictumst condimentum laoreet leo eget. Ac cursus enim, dignissim adipiscing convallis. Sit dui, ante fermentum, purus sit. Ullamcorper malesuada in ullamcorper ac diam venenatis. Ornare consectetur non aliquet natoque eget turpis ut. Feugiat sapien arcu at diam et sit eget at in. Neque ligula nulla nibh eros, at velit malesuada. Scelerisque auctor est donec diam nibh volutpat urna. Id integer a molestie consectetur. Vitae purus, ante consequat, urna bibendum mauris. Etiam sed vel, eu quis ullamcorper amet eu. 1.2. Malesuada posuere volutpat risus nulla cras. Ultrices tortor feugiat at id at fames at. Nunc lorem consectetur metus in nisl, sed parturient eu et. At risus lectus pulvinar ultricies. Malesuada viverra sed mattis diam pretium diam gravida morbi lectus. 1.3. Tortor praesent lacus, faucibus sagittis sollicitudin suspendisse dapibus scelerisque ultrices. Bibendum quis at in sit integer diam justo integer congue. Sit cursus vestibulum sed purus. Sodales lobortis tincidunt iaculis scelerisque at vel. Volutpat sed volutpat, nibh consectetur. Lacus pulvinar dolor interdum facilisis dui bibendum magna diam et. Luctus orci, lectus ipsum tristique nascetur amet. 1.4. Quis duis viverra et scelerisque mauris convallis eget. Cras at enim turpis facilisis vivamus. Pretium lectus libero, sapien, libero. Quis enim eu orci rhoncus diam donec nulla. '
//       }
//     },
//     {
//       language: 'de',
//       pageName: 'TermsAndConditions',
//       translation: {
//         title: 'Geschäftsbedingungen',
//         content:
//           '1.1. Deutsches Ipsum Dolor sit amet, Wurst adipiscing elit, sed do eiusmod Hochzeit incididunt ut labore et dolore Die Toten Hosen aliqua. Ut enim ad minim Apfelstrudel quis nostrud exercitation ullamco laboris Currywurst ut aliquip ex ea commodo Reise Duis aute irure dolor in Kartoffelkopf in voluptate velit esse cillum über eu fugiat nulla pariatur. Excepteur Deutschland occaecat cupidatat non proident, sunt Angela Merkel culpa qui officia deserunt mollit Deutschland id est laborum'
//       }
//     },
//     {
//       language: 'en',
//       pageName: 'PrivacyPolicy',
//       translation: {
//         title: 'Privacy Policy',
//         content:
//           '1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum sit tellus ornare purus facilisis. Hac tortor vulputate integer elementum dictumst condimentum laoreet leo eget. Ac cursus enim, dignissim adipiscing convallis. Sit dui, ante fermentum, purus sit. Ullamcorper malesuada in ullamcorper ac diam venenatis. Ornare consectetur non aliquet natoque eget turpis ut. Feugiat sapien arcu at diam et sit eget at in. Neque ligula nulla nibh eros, at velit malesuada. Scelerisque auctor est donec diam nibh volutpat urna. Id integer a molestie consectetur. Vitae purus, ante consequat, urna bibendum mauris. Etiam sed vel, eu quis ullamcorper amet eu. 1.2. Malesuada posuere volutpat risus nulla cras. Ultrices tortor feugiat at id at fames at. Nunc lorem consectetur metus in nisl, sed parturient eu et. At risus lectus pulvinar ultricies. Malesuada viverra sed mattis diam pretium diam gravida morbi lectus. 1.3. Tortor praesent lacus, faucibus sagittis sollicitudin suspendisse dapibus scelerisque ultrices. Bibendum quis at in sit integer diam justo integer congue. Sit cursus vestibulum sed purus. Sodales lobortis tincidunt iaculis scelerisque at vel. Volutpat sed volutpat, nibh consectetur. Lacus pulvinar dolor interdum facilisis dui bibendum magna diam et. Luctus orci, lectus ipsum tristique nascetur amet. 1.4. Quis duis viverra et scelerisque mauris convallis eget. Cras at enim turpis facilisis vivamus. Pretium lectus libero, sapien, libero. Quis enim eu orci rhoncus diam donec nulla. '
//       }
//     },
//     {
//       language: 'de',
//       pageName: 'PrivacyPolicy',
//       translation: {
//         title: 'Datenschutz-Bestimmungen',
//         content:
//           '1.1. Deutsches Ipsum Dolor sit amet, Wurst adipiscing elit, sed do eiusmod Hochzeit incididunt ut labore et dolore Die Toten Hosen aliqua. Ut enim ad minim Apfelstrudel quis nostrud exercitation ullamco laboris Currywurst ut aliquip ex ea commodo Reise Duis aute irure dolor in Kartoffelkopf in voluptate velit esse cillum über eu fugiat nulla pariatur. Excepteur Deutschland occaecat cupidatat non proident, sunt Angela Merkel culpa qui officia deserunt mollit Deutschland id est laborum'
//       }
//     },
//     {
//       language: 'en',
//       pageName: 'BonusTerms',
//       translation: {
//         title: 'Bonus Terms',
//         content:
//           '1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum sit tellus ornare purus facilisis. Hac tortor vulputate integer elementum dictumst condimentum laoreet leo eget. Ac cursus enim, dignissim adipiscing convallis. Sit dui, ante fermentum, purus sit. Ullamcorper malesuada in ullamcorper ac diam venenatis. Ornare consectetur non aliquet natoque eget turpis ut. Feugiat sapien arcu at diam et sit eget at in. Neque ligula nulla nibh eros, at velit malesuada. Scelerisque auctor est donec diam nibh volutpat urna. Id integer a molestie consectetur. Vitae purus, ante consequat, urna bibendum mauris. Etiam sed vel, eu quis ullamcorper amet eu. 1.2. Malesuada posuere volutpat risus nulla cras. Ultrices tortor feugiat at id at fames at. Nunc lorem consectetur metus in nisl, sed parturient eu et. At risus lectus pulvinar ultricies. Malesuada viverra sed mattis diam pretium diam gravida morbi lectus. 1.3. Tortor praesent lacus, faucibus sagittis sollicitudin suspendisse dapibus scelerisque ultrices. Bibendum quis at in sit integer diam justo integer congue. Sit cursus vestibulum sed purus. Sodales lobortis tincidunt iaculis scelerisque at vel. Volutpat sed volutpat, nibh consectetur. Lacus pulvinar dolor interdum facilisis dui bibendum magna diam et. Luctus orci, lectus ipsum tristique nascetur amet. 1.4. Quis duis viverra et scelerisque mauris convallis eget. Cras at enim turpis facilisis vivamus. Pretium lectus libero, sapien, libero. Quis enim eu orci rhoncus diam donec nulla. '
//       }
//     },
//     {
//       language: 'de',
//       pageName: 'BonusTerms',
//       translation: {
//         title: 'Bonusbedingungen',
//         content:
//           '1.1. Deutsches Ipsum Dolor sit amet, Wurst adipiscing elit, sed do eiusmod Hochzeit incididunt ut labore et dolore Die Toten Hosen aliqua. Ut enim ad minim Apfelstrudel quis nostrud exercitation ullamco laboris Currywurst ut aliquip ex ea commodo Reise Duis aute irure dolor in Kartoffelkopf in voluptate velit esse cillum über eu fugiat nulla pariatur. Excepteur Deutschland occaecat cupidatat non proident, sunt Angela Merkel culpa qui officia deserunt mollit Deutschland id est laborum'
//       }
//     },
//     {
//       language: 'en',
//       pageName: 'ResponsibleGaming',
//       translation: {
//         title: 'Responsible Gaming',
//         content:
//           '1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum sit tellus ornare purus facilisis. Hac tortor vulputate integer elementum dictumst condimentum laoreet leo eget. Ac cursus enim, dignissim adipiscing convallis. Sit dui, ante fermentum, purus sit. Ullamcorper malesuada in ullamcorper ac diam venenatis. Ornare consectetur non aliquet natoque eget turpis ut. Feugiat sapien arcu at diam et sit eget at in. Neque ligula nulla nibh eros, at velit malesuada. Scelerisque auctor est donec diam nibh volutpat urna. Id integer a molestie consectetur. Vitae purus, ante consequat, urna bibendum mauris. Etiam sed vel, eu quis ullamcorper amet eu. 1.2. Malesuada posuere volutpat risus nulla cras. Ultrices tortor feugiat at id at fames at. Nunc lorem consectetur metus in nisl, sed parturient eu et. At risus lectus pulvinar ultricies. Malesuada viverra sed mattis diam pretium diam gravida morbi lectus. 1.3. Tortor praesent lacus, faucibus sagittis sollicitudin suspendisse dapibus scelerisque ultrices. Bibendum quis at in sit integer diam justo integer congue. Sit cursus vestibulum sed purus. Sodales lobortis tincidunt iaculis scelerisque at vel. Volutpat sed volutpat, nibh consectetur. Lacus pulvinar dolor interdum facilisis dui bibendum magna diam et. Luctus orci, lectus ipsum tristique nascetur amet. 1.4. Quis duis viverra et scelerisque mauris convallis eget. Cras at enim turpis facilisis vivamus. Pretium lectus libero, sapien, libero. Quis enim eu orci rhoncus diam donec nulla. '
//       }
//     },
//     {
//       language: 'de',
//       pageName: 'ResponsibleGaming',
//       translation: {
//         title: 'Verantwortungsbewusstes Spielen',
//         content:
//           '1.1. Deutsches Ipsum Dolor sit amet, Wurst adipiscing elit, sed do eiusmod Hochzeit incididunt ut labore et dolore Die Toten Hosen aliqua. Ut enim ad minim Apfelstrudel quis nostrud exercitation ullamco laboris Currywurst ut aliquip ex ea commodo Reise Duis aute irure dolor in Kartoffelkopf in voluptate velit esse cillum über eu fugiat nulla pariatur. Excepteur Deutschland occaecat cupidatat non proident, sunt Angela Merkel culpa qui officia deserunt mollit Deutschland id est laborum'
//       }
//     },
//     {
//       language: 'en',
//       pageName: 'AboutUs',
//       translation: {
//         title: 'About Us',
//         content:
//           '1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum sit tellus ornare purus facilisis. Hac tortor vulputate integer elementum dictumst condimentum laoreet leo eget. Ac cursus enim, dignissim adipiscing convallis. Sit dui, ante fermentum, purus sit. Ullamcorper malesuada in ullamcorper ac diam venenatis. Ornare consectetur non aliquet natoque eget turpis ut. Feugiat sapien arcu at diam et sit eget at in. Neque ligula nulla nibh eros, at velit malesuada. Scelerisque auctor est donec diam nibh volutpat urna. Id integer a molestie consectetur. Vitae purus, ante consequat, urna bibendum mauris. Etiam sed vel, eu quis ullamcorper amet eu. 1.2. Malesuada posuere volutpat risus nulla cras. Ultrices tortor feugiat at id at fames at. Nunc lorem consectetur metus in nisl, sed parturient eu et. At risus lectus pulvinar ultricies. Malesuada viverra sed mattis diam pretium diam gravida morbi lectus. 1.3. Tortor praesent lacus, faucibus sagittis sollicitudin suspendisse dapibus scelerisque ultrices. Bibendum quis at in sit integer diam justo integer congue. Sit cursus vestibulum sed purus. Sodales lobortis tincidunt iaculis scelerisque at vel. Volutpat sed volutpat, nibh consectetur. Lacus pulvinar dolor interdum facilisis dui bibendum magna diam et. Luctus orci, lectus ipsum tristique nascetur amet. 1.4. Quis duis viverra et scelerisque mauris convallis eget. Cras at enim turpis facilisis vivamus. Pretium lectus libero, sapien, libero. Quis enim eu orci rhoncus diam donec nulla. '
//       }
//     },
//     {
//       language: 'de',
//       pageName: 'AboutUs',
//       translation: {
//         title: 'Über uns',
//         content:
//           '1.1. Deutsches Ipsum Dolor sit amet, Wurst adipiscing elit, sed do eiusmod Hochzeit incididunt ut labore et dolore Die Toten Hosen aliqua. Ut enim ad minim Apfelstrudel quis nostrud exercitation ullamco laboris Currywurst ut aliquip ex ea commodo Reise Duis aute irure dolor in Kartoffelkopf in voluptate velit esse cillum über eu fugiat nulla pariatur. Excepteur Deutschland occaecat cupidatat non proident, sunt Angela Merkel culpa qui officia deserunt mollit Deutschland id est laborum'
//       }
//     },
//     {
//       language: 'en',
//       pageName: 'ContactUs',
//       translation: {
//         title: 'Contact Us',
//         topic: 'topic',
//         selectTopic: 'Select topic',
//         content:
//           '1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum sit tellus ornare purus facilisis. Hac tortor vulputate integer elementum dictumst condimentum laoreet leo eget. Ac cursus enim, dignissim adipiscing convallis. Sit dui, ante fermentum, purus sit. Ullamcorper malesuada in ullamcorper ac diam venenatis. Ornare consectetur non aliquet natoque eget turpis ut. Feugiat sapien arcu at diam et sit eget at in. Neque ligula nulla nibh eros, at velit malesuada. Scelerisque auctor est donec diam nibh volutpat urna. Id integer a molestie consectetur. Vitae purus, ante consequat, urna bibendum mauris. Etiam sed vel, eu quis ullamcorper amet eu. 1.2. Malesuada posuere volutpat risus nulla cras. Ultrices tortor feugiat at id at fames at. Nunc lorem consectetur metus in nisl, sed parturient eu et. At risus lectus pulvinar ultricies. Malesuada viverra sed mattis diam pretium diam gravida morbi lectus. 1.3. Tortor praesent lacus, faucibus sagittis sollicitudin suspendisse dapibus scelerisque ultrices. Bibendum quis at in sit integer diam justo integer congue. Sit cursus vestibulum sed purus. Sodales lobortis tincidunt iaculis scelerisque at vel. Volutpat sed volutpat, nibh consectetur. Lacus pulvinar dolor interdum facilisis dui bibendum magna diam et. Luctus orci, lectus ipsum tristique nascetur amet. 1.4. Quis duis viverra et scelerisque mauris convallis eget. Cras at enim turpis facilisis vivamus. Pretium lectus libero, sapien, libero. Quis enim eu orci rhoncus diam donec nulla. ',
//         message: 'message',
//         enterMessage: 'Enter $t(message)',
//         supportFormats: 'support file formats .jpg .png up to 1 MB',
//         phone: 'Phone',
//         chat: 'Live Chat',
//         upload: 'Upload',
//         text1: 'Couldn’t find an answer in our',
//         text2:
//           'Share with us your questions or issues with our site. You may also add a screenshot or other file'
//       }
//     },
//     {
//       language: 'de',
//       pageName: 'ContactUs',
//       translation: {
//         title: 'Kontaktiere uns',
//         topic: 'Thema',
//         selectTopic: 'Thema auswählen',
//         message: 'Nachricht',
//         enterMessage: 'Nachricht eingeben',
//         upload: 'Hochladen',
//         phone: 'Telefon',
//         chat: 'Live-Chat',
//         text1: 'Konnte in unserem keine Antwort finden',
//         text2:
//           'Teilen Sie uns Ihre Fragen oder Probleme mit unserer Website mit. Sie können auch einen Screenshot oder eine andere Datei hinzufügen',
//         supportFormats: 'unterstützt Dateiformate .jpg .png bis zu 1 MB',
//         content:
//           '1.1. Deutsches Ipsum Dolor sit amet, Wurst adipiscing elit, sed do eiusmod Hochzeit incididunt ut labore et dolore Die Toten Hosen aliqua. Ut enim ad minim Apfelstrudel quis nostrud exercitation ullamco laboris Currywurst ut aliquip ex ea commodo Reise Duis aute irure dolor in Kartoffelkopf in voluptate velit esse cillum über eu fugiat nulla pariatur. Excepteur Deutschland occaecat cupidatat non proident, sunt Angela Merkel culpa qui officia deserunt mollit Deutschland id est laborum'
//       }
//     },
//     {
//       language: 'en',
//       pageName: 'HelpCenter',
//       translation: {
//         title: 'Help Center',
//         content:
//           '1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum sit tellus ornare purus facilisis. Hac tortor vulputate integer elementum dictumst condimentum laoreet leo eget. Ac cursus enim, dignissim adipiscing convallis. Sit dui, ante fermentum, purus sit. Ullamcorper malesuada in ullamcorper ac diam venenatis. Ornare consectetur non aliquet natoque eget turpis ut. Feugiat sapien arcu at diam et sit eget at in. Neque ligula nulla nibh eros, at velit malesuada. Scelerisque auctor est donec diam nibh volutpat urna. Id integer a molestie consectetur. Vitae purus, ante consequat, urna bibendum mauris. Etiam sed vel, eu quis ullamcorper amet eu. 1.2. Malesuada posuere volutpat risus nulla cras. Ultrices tortor feugiat at id at fames at. Nunc lorem consectetur metus in nisl, sed parturient eu et. At risus lectus pulvinar ultricies. Malesuada viverra sed mattis diam pretium diam gravida morbi lectus. 1.3. Tortor praesent lacus, faucibus sagittis sollicitudin suspendisse dapibus scelerisque ultrices. Bibendum quis at in sit integer diam justo integer congue. Sit cursus vestibulum sed purus. Sodales lobortis tincidunt iaculis scelerisque at vel. Volutpat sed volutpat, nibh consectetur. Lacus pulvinar dolor interdum facilisis dui bibendum magna diam et. Luctus orci, lectus ipsum tristique nascetur amet. 1.4. Quis duis viverra et scelerisque mauris convallis eget. Cras at enim turpis facilisis vivamus. Pretium lectus libero, sapien, libero. Quis enim eu orci rhoncus diam donec nulla. '
//       }
//     },
//     {
//       language: 'de',
//       pageName: 'HelpCenter',
//       translation: {
//         title: 'Hilfezentrum',
//         content:
//           '1.1. Deutsches Ipsum Dolor sit amet, Wurst adipiscing elit, sed do eiusmod Hochzeit incididunt ut labore et dolore Die Toten Hosen aliqua. Ut enim ad minim Apfelstrudel quis nostrud exercitation ullamco laboris Currywurst ut aliquip ex ea commodo Reise Duis aute irure dolor in Kartoffelkopf in voluptate velit esse cillum über eu fugiat nulla pariatur. Excepteur Deutschland occaecat cupidatat non proident, sunt Angela Merkel culpa qui officia deserunt mollit Deutschland id est laborum'
//       }
//     },
//     {
//       language: 'en',
//       pageName: 'Affiliates',
//       translation: {
//         title: 'Affiliates',
//         content:
//           '1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fermentum sit tellus ornare purus facilisis. Hac tortor vulputate integer elementum dictumst condimentum laoreet leo eget. Ac cursus enim, dignissim adipiscing convallis. Sit dui, ante fermentum, purus sit. Ullamcorper malesuada in ullamcorper ac diam venenatis. Ornare consectetur non aliquet natoque eget turpis ut. Feugiat sapien arcu at diam et sit eget at in. Neque ligula nulla nibh eros, at velit malesuada. Scelerisque auctor est donec diam nibh volutpat urna. Id integer a molestie consectetur. Vitae purus, ante consequat, urna bibendum mauris. Etiam sed vel, eu quis ullamcorper amet eu. 1.2. Malesuada posuere volutpat risus nulla cras. Ultrices tortor feugiat at id at fames at. Nunc lorem consectetur metus in nisl, sed parturient eu et. At risus lectus pulvinar ultricies. Malesuada viverra sed mattis diam pretium diam gravida morbi lectus. 1.3. Tortor praesent lacus, faucibus sagittis sollicitudin suspendisse dapibus scelerisque ultrices. Bibendum quis at in sit integer diam justo integer congue. Sit cursus vestibulum sed purus. Sodales lobortis tincidunt iaculis scelerisque at vel. Volutpat sed volutpat, nibh consectetur. Lacus pulvinar dolor interdum facilisis dui bibendum magna diam et. Luctus orci, lectus ipsum tristique nascetur amet. 1.4. Quis duis viverra et scelerisque mauris convallis eget. Cras at enim turpis facilisis vivamus. Pretium lectus libero, sapien, libero. Quis enim eu orci rhoncus diam donec nulla. '
//       }
//     },
//     {
//       language: 'de',
//       pageName: 'Affiliates',
//       translation: {
//         title: 'Mitgliedsorganisationen',
//         content:
//           '1.1. Deutsches Ipsum Dolor sit amet, Wurst adipiscing elit, sed do eiusmod Hochzeit incididunt ut labore et dolore Die Toten Hosen aliqua. Ut enim ad minim Apfelstrudel quis nostrud exercitation ullamco laboris Currywurst ut aliquip ex ea commodo Reise Duis aute irure dolor in Kartoffelkopf in voluptate velit esse cillum über eu fugiat nulla pariatur. Excepteur Deutschland occaecat cupidatat non proident, sunt Angela Merkel culpa qui officia deserunt mollit Deutschland id est laborum'
//       }
//     }
//   ]
// } as Page;

// export const resources = {
//   en: {
//     translation: enTranslation
//   },
//   de: {
//     translation: deTranslation
//   }
// } as const;
const isDev = process.env.ENVIRONMENT === 'develop';

export const addPageTranslation = (page: Page) => {
  page.translations.map((t) => {
    i18n.addResourceBundle(t.language, 'translation', { [t.pageName]: { ...t.translation } });
  });
};

i18n.use(initReactI18next).init({
  lng: 'en',
  debug: isDev,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
});

// footerContent.translations.map(t => {
//   i18n.addResourceBundle(t.language, "translation", { [t.pageName]: {...t.translation}})
// })
