import React, { useState } from 'react';
import { Box } from '@mui/material';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
import './AccountDetailsVerifyBox.scss';
import { useTranslation } from 'react-i18next';
import KycWidget from '../KycWidget';

const AccountDetailsVerifyBox: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [openKycWidget, setOpenKycWidget] = useState(false);
  return (
    <Box className="verify-box-container">
      <Box className="verify-box-container__image-wrapper">
        <Box className="verify-box-container__img-box"></Box>
        <Box className="verify-box-container__img"></Box>
      </Box>
      <Box className="verify-box-container__text-box">
        {t('myAccount.verificationPage.description')}
      </Box>
      <Box>
        <PrimaryButton
          onClick={() => setOpenKycWidget(true)}
          text={t('wallet.withdrawPage.verifyAccountButton') as string}
          className="verify-button"
        />
      </Box>
      {openKycWidget && <KycWidget handleClose={() => setOpenKycWidget(false)} />}
    </Box>
  );
};

export default AccountDetailsVerifyBox;
