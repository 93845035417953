import React, { useContext, useState } from 'react';
import { Box, FilterOptionsState, Paper } from '@mui/material';
import { AppContext } from '../../../AppContext';
import AccountStatus from './AccountStatus';
import InputField from '../../Common/Fields/InputField';
import { useTranslation } from 'react-i18next';
import AutocompleteField from '../../Common/Fields/AutocompleteField';
import { countries, CountryType } from '../../../utils/countries';
import DateField from '../../Common/Fields/DateField';
import moment from 'moment';
import { currencies, CurrencyType } from '../../../utils/currencies';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
import AccountPersonalDetailsEditMessageBox from './AccountPersonalDetailsEditMessageBox';
import { AccountDetailsModel } from '../../../models/profile';
import { isValidEmail } from '../../../utils/validators';
import { AppConsts } from '../../../enums/AppConsts';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GetAccountDetailsQuery, UpdateAccountDetailsQuery } from '../../../queries/profile';
import { Actions } from '../../../enums/ActionEnums';
import { handleError } from '../../../utils/ui';
import { UserModel } from '../../../models/account';
import Loader from '../../Common/Loader';
import './AccountPersonalDetailsBox.scss';
import { Query } from '../../../enums/RequestEnums';

type IErrors = {
  username: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneCode: string | null;
  phone: string | null;
  birthday: string | null;
  currency: string | null;
  country: string | null;
  address: string | null;
  city: string | null;
  postCode: string | null;
};

const defaultErrorsObj: IErrors = {
  username: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneCode: null,
  phone: null,
  birthday: null,
  currency: null,
  country: null,
  address: null,
  city: null,
  postCode: null
};

const getMinBirthdayDate = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  now.setFullYear(now.getFullYear() - 18);
  return moment(now);
};

const AccountPersonalDetailsBox: React.FunctionComponent = ({}) => {
  const { state, dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const getAccountDetails = useQuery({
    queryKey: Query.GetPlayerDetails,
    queryFn: GetAccountDetailsQuery,
    onSuccess: ({ data }) => {
      updateFields(data as AccountDetailsModel);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const [username, setUsername] = useState(getAccountDetails.data?.data.username || '');
  const [email, setEmail] = useState(getAccountDetails.data?.data.email || '');
  const [firstName, setFirstName] = useState(getAccountDetails.data?.data.firstName || '');
  const [lastName, setLastName] = useState(getAccountDetails.data?.data.lastName || '');
  const [phoneCountry, setPhoneCountry] = useState<CountryType | null>(
    countries.find(
      (c) =>
        c.label.toLocaleLowerCase().replace(/\s/g, '') ===
        getAccountDetails.data?.data.country.toLocaleLowerCase()
    ) ?? null
  );
  const [phone, setPhone] = useState(
    phoneCountry && getAccountDetails.data?.data.phoneNumber
      ? getAccountDetails.data?.data.phoneNumber.replace(`+${phoneCountry.phone}`, '')
      : getAccountDetails.data?.data.phoneNumber ?? ''
  );

  const [birthday, setBirthday] = useState<moment.Moment | null>(
    getAccountDetails.data?.data.birthDate ? moment(getAccountDetails.data?.data.birthDate) : null
  );
  const [currency, setCurrency] = useState<CurrencyType | null>(
    currencies.find(
      (c) => c.code.toLowerCase() === getAccountDetails.data?.data.currency.toLocaleLowerCase()
    ) ?? currencies[0]
  );
  const [country, setCountry] = useState<CountryType | null>(
    countries.find(
      (c) =>
        c.label.toLocaleLowerCase().replace(/\s/g, '') ===
        getAccountDetails.data?.data.country.toLocaleLowerCase()
    ) ?? null
  );
  const [address, setAddress] = useState(getAccountDetails.data?.data.address || '');
  const [city, setCity] = useState(getAccountDetails.data?.data.city || '');
  const [verificationStatus, setVerificationStatus] = useState(
    getAccountDetails.data?.data.verificationStatus || ''
  );
  const [postCode, setPostCode] = useState(getAccountDetails.data?.data.postCode || '');
  const updateAccount = useMutation(UpdateAccountDetailsQuery, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('myAccount.verificationPage.accountDetailsChangedSuccessfully')
        }
      });
      const updatedAccount = data.data as AccountDetailsModel;
      dispatch({
        type: Actions.SetUser,
        payload: {
          data: {
            ...(state.user.data as UserModel),
            email: updatedAccount.email,
            firstName: updatedAccount.firstName,
            lastName: updatedAccount.lastName,
            currency: updatedAccount.currency
          },
          token: data.accessToken as string
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
      setIsEditMode(false);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('UpdateAccountDetailsQuery');
      queryClient.invalidateQueries([Query.GetPlayerDetails]);
    }
  });

  const updateFields = (data: AccountDetailsModel) => {
    setUsername(data.username ?? '');
    setEmail(data.email ?? '');
    setFirstName(data.firstName ?? '');
    setLastName(data.lastName ?? '');
    const phoneCountry =
      countries.find(
        (c) => c.label.toLocaleLowerCase().replace(/\s/g, '') === data.country.toLocaleLowerCase()
      ) ?? null;
    setPhone(
      phoneCountry && data.phoneNumber
        ? data.phoneNumber.replace(`+${phoneCountry.phone}`, '')
        : data.phoneNumber ?? ''
    );
    setPhoneCountry(phoneCountry);
    setBirthday(data.birthDate ? moment(data.birthDate) : null);
    setCurrency(
      currencies.find((c) => c.code.toLowerCase() === data.currency.toLocaleLowerCase()) ?? null
    );
    setCountry(
      countries.find(
        (c) => c.label.toLocaleLowerCase().replace(/\s/g, '') === data.country.toLocaleLowerCase()
      ) ?? null
    );
    setAddress(data.address ?? '');
    setCity(data.city ?? '');
    setPostCode(data.postCode ?? '');
    setVerificationStatus(data.verificationStatus ?? '');
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, username: t('errors.signup.username') });
    } else {
      setFormErrors({ ...formErrors, username: null });
    }
    setUsername(value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isValidEmail(value)) {
      setFormErrors({ ...formErrors, email: t('errors.signup.email') });
    } else {
      setFormErrors({ ...formErrors, email: null });
    }
    setEmail(value);
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, firstName: t('errors.signup.firstName') });
    } else {
      setFormErrors({ ...formErrors, firstName: null });
    }
    setFirstName(value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, lastName: t('errors.signup.lastName') });
    } else {
      setFormErrors({ ...formErrors, lastName: null });
    }
    setLastName(value);
  };

  const handlePhoneCodeChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, phoneCode: t('errors.signup.phoneCode') });
    } else {
      setFormErrors({ ...formErrors, phoneCode: null });
    }
    setPhoneCountry(value);
  };

  const filterOptions = (options: CountryType[], state: FilterOptionsState<CountryType>) => {
    let searchValue = state.inputValue;
    if (searchValue === '') {
      return options;
    } else if (searchValue.length > 0 && searchValue[0] === '+') {
      searchValue = state.inputValue.substring(1);
    }
    const result = options.filter((opt) => opt.phone.indexOf(searchValue) === 0);
    return result;
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    //TODO Adds better phone validation
    if (value.length < 4) {
      setFormErrors({ ...formErrors, phone: t('errors.signup.phone') });
    } else {
      setFormErrors({ ...formErrors, phone: null });
    }
    setPhone(value);
  };

  const handleBirthdayChange = (value: moment.Moment | null) => {
    if (value && value.isValid()) {
      value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      if (value > getMinBirthdayDate()) {
        setFormErrors({ ...formErrors, birthday: t('errors.signup.under18') });
      } else {
        setFormErrors({ ...formErrors, birthday: null });
      }
    } else {
      setFormErrors({ ...formErrors, birthday: t('errors.signup.birthday') });
    }
    setBirthday(value);
  };

  const handleCurrencyChange = (_: unknown, value: CurrencyType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, currency: t('errors.signup.currency') });
    } else {
      setFormErrors({ ...formErrors, currency: null });
    }
    setCurrency(value);
  };

  const handleCountryChange = (_: unknown, value: CountryType | null) => {
    if (value === null) {
      setFormErrors({ ...formErrors, country: t('errors.signup.country') });
    } else {
      setFormErrors({ ...formErrors, country: null });
    }
    setCountry(value);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, address: t('errors.signup.address') });
    } else {
      setFormErrors({ ...formErrors, address: null });
    }
    setAddress(value);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, city: t('errors.signup.city') });
    } else {
      setFormErrors({ ...formErrors, city: null });
    }
    setCity(value);
  };

  const handlePostCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, postCode: t('errors.signup.postCode') });
    } else {
      setFormErrors({ ...formErrors, postCode: null });
    }
    setPostCode(value);
  };

  const handleSave = () => {
    if (!isEditMode) {
      setIsEditMode(true);
    } else {
      let hasErrors = false;
      const errors: IErrors = {
        username: null,
        email: null,
        firstName: null,
        lastName: null,
        phoneCode: null,
        phone: null,
        birthday: null,
        currency: null,
        country: null,
        address: null,
        city: null,
        postCode: null
      };

      if (username.length < 4) {
        hasErrors = true;
        errors.username = t('errors.signup.username');
      }
      if (!isValidEmail(email)) {
        hasErrors = true;
        errors.email = t('errors.signup.email');
      }
      if (firstName.length < 2) {
        hasErrors = true;
        errors.firstName = t('errors.signup.firstName');
      }
      if (lastName.length < 2) {
        hasErrors = true;
        errors.lastName = t('errors.signup.lastName');
      }
      if (!phoneCountry) {
        hasErrors = true;
        errors.phoneCode = t('errors.signup.phoneCode');
      }
      if (phone.length < 4) {
        hasErrors = true;
        errors.phone = t('errors.signup.phone');
      }
      if (!birthday) {
        hasErrors = true;
        errors.birthday = t('errors.signup.birthday');
      }
      if (birthday && birthday > getMinBirthdayDate()) {
        hasErrors = true;
        errors.birthday = t('errors.signup.under18');
      }
      if (!currency) {
        hasErrors = true;
        errors.currency = t('errors.signup.currency');
      }
      if (!country) {
        hasErrors = true;
        errors.country = t('errors.signup.country');
      }
      if (address.length < 2) {
        hasErrors = true;
        errors.address = t('errors.signup.address');
      }
      if (city.length < 2) {
        hasErrors = true;
        errors.city = t('errors.signup.city');
      }
      if (postCode.length < 2) {
        hasErrors = true;
        errors.postCode = t('errors.signup.postCode');
      }

      if (hasErrors) {
        setFormErrors(errors);
      } else {
        const model: AccountDetailsModel = {
          firstName,
          lastName,
          birthDate: birthday?.format('YYYY-MM-DD') as string,
          email,
          country: (country?.label as string).replace(/\s/g, ''),
          phoneNumber: `+${phoneCountry?.phone as string}${phone}`,
          address,
          city,
          postCode,
          username,
          currency: currency?.code as string,
          verificationStatus: verificationStatus
        };
        updateAccount.mutate(model);
      }
    }
  };

  return (
    <Box className="personal-details-main-container">
      <Box className="personal-details-title-container">
        <Box className="personal-details-title-container__status-title-box">
          <Box className="personal-details-title-container__status-title-box__title-box">
            {t('myAccount.accountPage.personalDetailsTitle')}
          </Box>
          <Box className={'personal-details-title-container__status-title-box__status-box'}>
            <AccountStatus />
          </Box>
        </Box>
      </Box>
      <Box className="personal-details-form-container">
        <Box className="personal-details-form-container__child--custom">
          <InputField
            id="username"
            value={username}
            onChange={handleUsernameChange}
            label={t('account.username') as string}
            errorMsg={formErrors.username}
            placeholder={t('account.usernamePlaceHolder') as string}
            isDarkTheme
            disabled
          />
        </Box>
        <Box className="personal-details-form-container__child--custom">
          <InputField
            id="email"
            value={email}
            onChange={handleEmailChange}
            label={t('account.emailLabel') as string}
            errorMsg={formErrors.email}
            placeholder={t('account.emailPlaceHolder') as string}
            isDarkTheme
            disabled={!isEditMode}
          />
        </Box>
        <Box className="personal-details-form-container__child">
          <InputField
            id="firstName"
            value={firstName}
            onChange={handleFirstNameChange}
            label={t('account.firstNameLabel') as string}
            errorMsg={formErrors.firstName}
            placeholder={t('account.namePlaceHolder') as string}
            isDarkTheme
            disabled={!isEditMode}
          />
        </Box>
        <Box className="personal-details-form-container__child">
          <InputField
            id="lastName"
            value={lastName}
            onChange={handleLastNameChange}
            label={t('account.lastNameLabel') as string}
            errorMsg={formErrors.lastName}
            placeholder={t('account.namePlaceHolder') as string}
            isDarkTheme
            disabled={!isEditMode}
          />
        </Box>
        <Box className="personal-details-form-container__child--custom">
          <InputField
            id="phone"
            value={phone}
            onChange={handlePhoneChange}
            disabled={!isEditMode}
            isDarkTheme
            label={t('account.phoneLabel') as string}
            errorMsg={formErrors.phone}
            placeholder={t('account.phonePlaceHolder') as string}
            startIcon={
              <Box sx={{ width: '81px' }}>
                <AutocompleteField
                  id="phoneCode"
                  value={phoneCountry}
                  onChange={handlePhoneCodeChange}
                  options={countries as unknown[]}
                  optionLabel={(option) => `+${option.phone}`}
                  placeholder={t('account.countryPlaceHolder') as string}
                  errorMsg={formErrors.phoneCode}
                  disableClearable={true}
                  PaperComponent={(props) => (
                    <Paper elevation={8} {...props} style={{ width: '130px' }} />
                  )}
                  filterOptions={filterOptions}
                  noPaddingX={true}
                  borderRight={true}
                  isDarkTheme
                  disabled={!isEditMode}
                />
              </Box>
            }
          />
        </Box>
        <Box className="personal-details-form-container__child--custom">
          <DateField
            id="birthday"
            maxDate={getMinBirthdayDate()}
            label={t('account.birthdayLabel') as string}
            onChange={handleBirthdayChange}
            value={birthday}
            errorMsg={formErrors.birthday}
            isDarkTheme
            disabled={!isEditMode}
          />
        </Box>
        <Box className="personal-details-form-container__child--custom">
          <AutocompleteField
            id="currency"
            label={t('account.currencyLabel') as string}
            value={currency}
            onChange={handleCurrencyChange}
            options={currencies as unknown[]}
            optionLabel={(option) => option.code}
            placeholder={t('account.currencyPlaceHolder') as string}
            errorMsg={formErrors.currency}
            disabled={!isEditMode}
            isDarkTheme
          />
        </Box>
        <Box className="personal-details-form-container__child--custom">
          <AutocompleteField
            id="country"
            label={t('account.countryLabel') as string}
            value={country}
            onChange={handleCountryChange}
            options={countries as unknown[]}
            optionLabel={(option) => option.label}
            placeholder={t('account.countryPlaceHolder') as string}
            errorMsg={formErrors.country}
            isDarkTheme
            disabled={!isEditMode}
          />
        </Box>
        <Box className="personal-details-form-container--span-4">
          <InputField
            id="address"
            value={address}
            onChange={handleAddressChange}
            label={t('account.addressLabel') as string}
            errorMsg={formErrors.address}
            placeholder={t('account.addressPlaceHolder') as string}
            isDarkTheme
            disabled={!isEditMode}
          />
        </Box>
        <Box className="personal-details-form-container__child">
          <InputField
            id="city"
            value={city}
            onChange={handleCityChange}
            label={t('account.cityLabel') as string}
            errorMsg={formErrors.city}
            placeholder={t('account.cityPlaceHolder') as string}
            isDarkTheme
            disabled={!isEditMode}
          />
        </Box>
        <Box className="personal-details-form-container__child">
          <InputField
            id="postCode"
            value={postCode}
            onChange={handlePostCodeChange}
            label={t('account.postCodeLabel') as string}
            errorMsg={formErrors.postCode}
            placeholder={t('account.postCodePlaceHolder') as string}
            isDarkTheme
            disabled={!isEditMode}
          />
        </Box>
        {isEditMode && (
          <Box className="personal-details-form-container--span-4">
            <AccountPersonalDetailsEditMessageBox />
          </Box>
        )}
        <Box
          className={
            isEditMode
              ? 'personal-details-form-container--flex-span-2'
              : 'personal-details-form-container--span-1'
          }
        >
          {isEditMode && (
            <PrimaryButton
              onClick={() => setIsEditMode(false)}
              text={t('myAccount.accountPage.cancelButton')}
              className="personal-details-cancel-button"
              additionalStyle={{
                '&:hover': {
                  color: '#16183D !important'
                }
              }}
            />
          )}
          <PrimaryButton
            onClick={() => handleSave()}
            text={
              isEditMode
                ? `${t('myAccount.accountPage.saveButton2')}`
                : `${t('myAccount.accountPage.editButton')}`
            }
            className={isEditMode ? 'personal-details-save-button' : 'personal-details-edit-button'}
          />
        </Box>
      </Box>
      <Loader loading={getAccountDetails.isLoading || updateAccount.isLoading} />
    </Box>
  );
};

export default AccountPersonalDetailsBox;
