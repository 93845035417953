import React, { useContext, useEffect, useState, useRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import Xarrow from 'react-xarrows';
//* Mui
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
//* Icons
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
//* Images
import chestLocked from '../../assets/challenges/chests/locked.svg';
import islandOne from '../../assets/challenges/islands/one.svg';
import islandTwo from '../../assets/challenges/islands/two.svg';
import islandThree from '../../assets/challenges/islands/three.svg';
import islandFour from '../../assets/challenges/islands/four.svg';
import islandFive from '../../assets/challenges/islands/five.svg';
import levelOneUnlocked from '../../assets/challenges/levels/one_unlocked.svg';
import levelTwoLocked from '../../assets/challenges/levels/two_locked.svg';
import levelTwoUnlocked from '../../assets/challenges/levels/two_unlocked.svg';
import levelThreeLocked from '../../assets/challenges/levels/three_locked.svg';
import levelThreeUnlocked from '../../assets/challenges/levels/three_unlocked.svg';
import levelFourLocked from '../../assets/challenges/levels/four_locked.svg';
import levelFourUnlocked from '../../assets/challenges/levels/four_unlocked.svg';
import levelFiveLocked from '../../assets/challenges/levels/five_locked.svg';
import levelFiveUnlocked from '../../assets/challenges/levels/five_unlocked.svg';
//* Models
import { ChallengeGroup, ChallengeGroupInfo } from '../../models/challenges';
//* Queries
import { AllChallengesQuery } from '../../queries/challenges';
//* Components
import Badge from './Badge';
import Loader from '../Common/Loader';
import InfoBox from '../Common/Dialogs/InfoBox';
import ChallengeCard from './ChallengeCard/ChallengeCard';
import ChallengeIslandInfoBox from './ChallengeIslandInfoBox/ChallengeIslandInfoBox';
import UnlockedChallengeIndicator from './UnlockedChallengeIndicator/UnlockedChallengeIndicator';
import ChallengeInfoDialogContent from './ChallengeInfoDialogContent/ChallengeInfoDialogContent';
//* Utils
import { colors } from '../../utils/theme';
import { handleError } from '../../utils/ui';
import { AppContext } from '../../AppContext';
//* Styles
import './ChallengesPage.scss';

const ChallengesPage: React.FunctionComponent = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);
  const [challengeGroups, setChallengeGroups] = useState<ChallengeGroup[]>([]);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [pastChallengesIds, setPastChallengesIds] = useState<number[]>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [howItWorksIsVisible, setHowItWorksIsVisible] = useState(false);
  const islandOneRef = useRef(null);
  const islandTwoRef = useRef(null);
  const islandTwoSmallRef = useRef(null);
  const islandThreeRef = useRef(null);
  const islandFourRef = useRef(null);
  const islandFourSmallRef = useRef(null);
  const islandFiveRef = useRef(null);
  const challengeGroupRefs = useRef<HTMLDivElement[]>([]);
  const challengesQuery = useMutation(AllChallengesQuery, {
    onSuccess: ({ data }) => {
      let level = 0;
      const { groupData, playerData } = data as ChallengeGroupInfo;

      setChallengeGroups(groupData);

      if (playerData) {
        setPastChallengesIds(playerData.completedChallenges);
        if (playerData.challengeBadgeId) {
          level =
            groupData.findIndex((cg) => cg.challengeBadge.id === playerData.challengeBadgeId) + 1;
        }
      }

      setCurrentLevel(level);
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('AllChallengesQuery');
    }
  });

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  useEffect(() => {
    challengesQuery.mutate();
  }, []);

  const handleChallengeInfoBoxClick = (index: number) => {
    window.scrollTo({
      behavior: 'smooth',
      top: challengeGroupRefs.current[index].offsetTop - 80 // 80 is the height of the navbar in pixels
    });
  };

  const infoBoxActionData = {
    text: 'OK',
    position: 'centered',
    onClick: () => setHowItWorksIsVisible(false)
  };

  const renderChallengeCards = (group: ChallengeGroup, challengeGroupsIndex: number) => {
    return group.challenges.map((challenge, index) => {
      const subtitle = `+ ${challenge.prize}`;
      const image = challenge.imageUrl ?? chestLocked;
      const description =
        challenge.descriptions.find(
          (description) =>
            (description.language as string)?.toUpperCase() === i18n.language.toUpperCase()
        )?.text ?? challenge.descriptions[0].text;
      const challengeName =
        challenge.nameTranslations.find(
          (nt) => nt.language.toUpperCase() === i18n.language.toUpperCase()
        )?.name ?? challenge.nameTranslations[0].name;

      return (
        <ChallengeCard
          key={index}
          image={image}
          subtitle={subtitle}
          title={challengeName}
          description={description}
          isLocked={challengeGroupsIndex > currentLevel}
          isClaimed={pastChallengesIds.includes(challenge.id)}
        />
      );
    });
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box className="challenges">
        <Box
          className="challenges__islands"
          sx={{
            paddingBottom: { xs: '175px', md: '247px', xl: '330px' }
          }}
        >
          <Box
            className="challenges__islands-title"
            sx={{
              fontSize: { xs: '32px', md: '64px' },
              lineHeight: { xs: '45px', md: '90px' }
            }}
          >
            <span>{t('challenges.title')}</span>
            <button onClick={() => setHowItWorksIsVisible(true)}>
              {t('challenges.howItWorks')} <HelpOutlineIcon />
            </button>
          </Box>
          <Box className="challenges__islands-container">
            <Box
              className="challenges__islands-container-row"
              sx={{
                flexFlow: { xs: 'column', md: 'row' },
                gap: { xs: '32px', lg: '64px', xl: '183px' }
              }}
            >
              <Box className="challenge-island" sx={{ display: 'flex', justifyContent: 'start' }}>
                <Box
                  sx={{
                    width: { xs: '225px', md: '100%' },
                    height: '100%',
                    cursor: 'pointer',
                    position: 'relative'
                  }}
                >
                  <Box
                    component="img"
                    src={islandOne}
                    sx={{ width: '100%', height: '100%' }}
                    ref={islandOneRef}
                  />
                  <Box
                    component="img"
                    src={levelOneUnlocked}
                    sx={{
                      position: 'absolute',
                      width: { xs: '85px', md: '120px' },
                      height: { xs: '85px', md: '120px' },
                      left: 0,
                      bottom: 0
                    }}
                  />
                  {currentLevel === 0 && <UnlockedChallengeIndicator position="bottom" />}
                </Box>
                <ChallengeIslandInfoBox
                  islandIndex={0}
                  currentLevel={currentLevel}
                  islandGroupInfo={challengeGroups[0]}
                  handleClick={handleChallengeInfoBoxClick}
                  islandGroupBadge={
                    <Badge rank={1} showName name={challengeGroups[0]?.challengeBadge.name} />
                  }
                />
              </Box>
              <Box
                className="challenge-island"
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  height: '226px',
                  justifyContent: 'end',
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    width: '225px',
                    height: '100%',
                    cursor: 'pointer',
                    position: 'relative'
                  }}
                >
                  <Box
                    component="img"
                    src={islandTwo}
                    sx={{ width: '100%', height: '100%' }}
                    ref={islandTwoSmallRef}
                  />
                  <Box
                    component="img"
                    src={currentLevel >= 1 ? levelTwoUnlocked : levelTwoLocked}
                    sx={{
                      position: 'absolute',
                      width: { xs: '85px', md: '120px' },
                      height: { xs: '85px', md: '120px' },
                      top: 0,
                      right: '140px'
                    }}
                  />
                  {currentLevel === 1 && <UnlockedChallengeIndicator />}
                </Box>
                <ChallengeIslandInfoBox
                  islandIndex={1}
                  currentLevel={currentLevel}
                  islandGroupInfo={challengeGroups[1]}
                  handleClick={handleChallengeInfoBoxClick}
                  islandGroupBadge={
                    <Badge rank={2} showName name={challengeGroups[1]?.challengeBadge.name} />
                  }
                />
              </Box>
              <Box
                className="challenge-island"
                sx={{
                  width: { md: '318px' },
                  height: { xs: '226px', md: '318px' },
                  display: 'flex',
                  justifyContent: 'start',
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    width: { xs: '225px', md: '100%' },
                    height: '100%',
                    cursor: 'pointer',
                    position: 'relative'
                  }}
                >
                  <Box
                    component="img"
                    src={islandThree}
                    sx={{ width: '100%', height: '100%' }}
                    ref={islandThreeRef}
                  />
                  <Box
                    component="img"
                    src={currentLevel >= 2 ? levelThreeUnlocked : levelThreeLocked}
                    sx={{
                      position: 'absolute',
                      width: { xs: '85px', md: '120px' },
                      height: { xs: '85px', md: '120px' },
                      left: { xs: '133px', md: '160px', lg: '185px' },
                      bottom: { xs: '75px', md: '105px' }
                    }}
                  />
                  {currentLevel === 2 && <UnlockedChallengeIndicator position="bottom" />}
                </Box>
                <ChallengeIslandInfoBox
                  islandIndex={2}
                  currentLevel={currentLevel}
                  islandGroupInfo={challengeGroups[2]}
                  handleClick={handleChallengeInfoBoxClick}
                  islandGroupBadge={
                    <Badge rank={3} showName name={challengeGroups[2]?.challengeBadge.name} />
                  }
                />
              </Box>
              <Box
                className="challenge-island"
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  height: '226px',
                  justifyContent: 'end'
                }}
              >
                <Box
                  sx={{
                    width: '225px',
                    height: '100%',
                    cursor: 'pointer',
                    position: 'relative'
                  }}
                >
                  <Box
                    component="img"
                    src={islandFour}
                    sx={{ width: '100%', height: '100%' }}
                    ref={islandFourSmallRef}
                  />
                  <Box
                    component="img"
                    src={currentLevel >= 3 ? levelFourUnlocked : levelFourLocked}
                    sx={{
                      position: 'absolute',
                      width: { xs: '85px', md: '120px' },
                      height: { xs: '85px', md: '120px' },
                      top: 0,
                      right: '140px'
                    }}
                  />
                  {currentLevel === 3 && <UnlockedChallengeIndicator position="right" />}
                </Box>
                <ChallengeIslandInfoBox
                  islandIndex={3}
                  currentLevel={currentLevel}
                  islandGroupInfo={challengeGroups[3]}
                  handleClick={handleChallengeInfoBoxClick}
                  islandGroupBadge={
                    <Badge rank={4} showName name={challengeGroups[3]?.challengeBadge.name} />
                  }
                />
              </Box>
              <Box
                className="challenge-island"
                sx={{
                  width: { md: '318px' },
                  height: { xs: '226px', md: '318px' },
                  display: 'flex',
                  justifyContent: 'start',
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    width: { xs: '225px', md: '100%' },
                    height: '100%',
                    cursor: 'pointer',
                    position: 'relative'
                  }}
                >
                  <Box
                    component="img"
                    src={islandFive}
                    sx={{ width: '100%', height: '100%' }}
                    ref={islandFiveRef}
                  />
                  <Box
                    component="img"
                    src={currentLevel >= 4 ? levelFiveUnlocked : levelFiveLocked}
                    sx={{
                      position: 'absolute',
                      width: { xs: '85px', md: '120px' },
                      height: { xs: '85px', md: '120px' },
                      left: { xs: '135px', md: '165px', lg: '190px' },
                      bottom: { xs: '55px', md: '75px' }
                    }}
                  />
                  {currentLevel === 4 && <UnlockedChallengeIndicator position="top-right" />}
                </Box>
                <ChallengeIslandInfoBox
                  islandIndex={4}
                  currentLevel={currentLevel}
                  islandGroupInfo={challengeGroups[4]}
                  handleClick={handleChallengeInfoBoxClick}
                  islandGroupBadge={
                    <Badge rank={5} showName name={challengeGroups[4]?.challengeBadge.name} />
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                gap: '127px',
                marginTop: '78px'
              }}
            >
              <Box
                className="challenge-island"
                sx={{
                  width: '318px',
                  height: '318px',
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    width: { xs: '225px', md: '100%' },
                    height: '100%',
                    cursor: 'pointer'
                  }}
                >
                  <Box
                    component="img"
                    src={islandTwo}
                    sx={{ width: '100%', height: '100%' }}
                    ref={islandTwoRef}
                  />
                  <Box
                    component="img"
                    src={currentLevel >= 1 ? levelTwoUnlocked : levelTwoLocked}
                    sx={{
                      position: 'absolute',
                      width: '120px',
                      height: '120px',
                      top: 0,
                      left: 0
                    }}
                  />
                  {currentLevel === 1 && <UnlockedChallengeIndicator />}
                </Box>
                <ChallengeIslandInfoBox
                  islandIndex={1}
                  currentLevel={currentLevel}
                  islandGroupInfo={challengeGroups[1]}
                  handleClick={handleChallengeInfoBoxClick}
                  islandGroupBadge={
                    <Badge rank={2} showName name={challengeGroups[1]?.challengeBadge.name} />
                  }
                />
              </Box>
              <Box
                className="challenge-island"
                sx={{
                  width: '318px',
                  height: '318px'
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                    position: 'relative'
                  }}
                >
                  <Box
                    component="img"
                    src={islandFour}
                    sx={{ width: '100%', height: '100%' }}
                    ref={islandFourRef}
                  />
                  <Box
                    component="img"
                    src={currentLevel >= 3 ? levelFourUnlocked : levelFourLocked}
                    sx={{
                      position: 'absolute',
                      width: '120px',
                      height: '120px',
                      top: 0,
                      left: 0
                    }}
                  />
                  {currentLevel === 3 && <UnlockedChallengeIndicator position="right" />}
                </Box>
                <ChallengeIslandInfoBox
                  islandIndex={3}
                  currentLevel={currentLevel}
                  islandGroupInfo={challengeGroups[3]}
                  handleClick={handleChallengeInfoBoxClick}
                  islandGroupBadge={
                    <Badge rank={4} showName name={challengeGroups[3]?.challengeBadge.name} />
                  }
                />
              </Box>
            </Box>
          </Box>

          <Xarrow
            start={islandOneRef}
            end={windowWidth >= theme.breakpoints.values.md ? islandTwoRef : islandTwoSmallRef}
            startAnchor={{ position: 'bottom', offset: { x: -50 } }}
            endAnchor={{
              position: 'left',
              offset: { y: windowWidth >= theme.breakpoints.values.md ? 10 : 5, x: 30 }
            }}
            path="smooth"
            showHead={false}
            strokeWidth={3}
            dashness={true}
            lineColor={currentLevel > 0 ? colors.gradients.yellowRgb : colors.grey.disabled}
          />
          <Xarrow
            start={windowWidth >= theme.breakpoints.values.md ? islandTwoRef : islandTwoSmallRef}
            end={islandThreeRef}
            startAnchor={{
              position: windowWidth >= theme.breakpoints.values.md ? 'top' : 'bottom',
              offset: {
                y: windowWidth < theme.breakpoints.values.md ? -80 : 50,
                x: windowWidth >= theme.breakpoints.values.md ? 40 : 0
              }
            }}
            endAnchor={{
              position: windowWidth >= theme.breakpoints.values.md ? 'bottom' : 'top',
              offset: { y: windowWidth < theme.breakpoints.values.md ? 100 : -60 }
            }}
            path="smooth"
            showHead={false}
            strokeWidth={3}
            dashness={true}
            lineColor={currentLevel > 1 ? colors.gradients.yellowRgb : colors.grey.disabled}
          />
          <Xarrow
            start={islandThreeRef}
            end={windowWidth >= theme.breakpoints.values.md ? islandFourRef : islandFourSmallRef}
            startAnchor={{
              position: windowWidth >= theme.breakpoints.values.md ? 'right' : 'bottom',
              offset: { y: windowWidth < theme.breakpoints.values.md ? -45 : 35 }
            }}
            endAnchor={{
              position: 'top',
              offset: { y: windowWidth >= theme.breakpoints.values.md ? 20 : 15 }
            }}
            path="smooth"
            showHead={false}
            strokeWidth={3}
            dashness={true}
            lineColor={currentLevel > 2 ? colors.gradients.yellowRgb : colors.grey.disabled}
          />
          <Xarrow
            start={windowWidth >= theme.breakpoints.values.md ? islandFourRef : islandFourSmallRef}
            end={islandFiveRef}
            startAnchor={{
              position: windowWidth >= theme.breakpoints.values.md ? 'right' : 'bottom',
              offset: {
                y: windowWidth >= theme.breakpoints.values.md ? -20 : -45,
                x: windowWidth >= theme.breakpoints.values.md ? -5 : 40
              }
            }}
            endAnchor={{
              position: windowWidth >= theme.breakpoints.values.md ? 'bottom' : 'right',
              offset: {
                y: windowWidth >= theme.breakpoints.values.md ? -70 : -10,
                x: windowWidth >= theme.breakpoints.values.md ? 70 : -5
              }
            }}
            path="smooth"
            showHead={false}
            strokeWidth={3}
            dashness={true}
            lineColor={currentLevel > 3 ? colors.gradients.yellowRgb : colors.grey.disabled}
          />
        </Box>
        <Box
          sx={{
            paddingX: { xs: '16px', sm: '7%', xl: '10%' },
            background: colors.primary.second,
            paddingBottom: 48
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
              gap: '48px'
            }}
          >
            {challengeGroups.map((group, challengeGroupsIndex) => (
              <Box
                key={challengeGroupsIndex}
                sx={{ display: 'flex', gap: '24px', flexFlow: 'column' }}
              >
                <Box
                  className="challenges__title"
                  sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}
                >
                  <Box
                    ref={(ref: HTMLDivElement) => {
                      challengeGroupRefs.current.push(ref);
                    }}
                    sx={{
                      fontSize: { xs: '30px', md: '40px' },
                      fontWeight: 600,
                      lineHeight: '56px'
                    }}
                  >
                    {group.name}
                  </Box>
                  <Box
                    sx={{
                      position: 'relative',
                      opacity: challengeGroupsIndex > currentLevel ? 0.5 : 1
                    }}
                  >
                    <Badge
                      showName
                      withBackground
                      rank={challengeGroupsIndex + 1}
                      name={group.challengeBadge.name}
                    />
                  </Box>
                </Box>
                <Box className="challenges__cards">
                  {renderChallengeCards(group, challengeGroupsIndex)}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <InfoBox
        extraHeight
        open={howItWorksIsVisible}
        actionData={infoBoxActionData}
        title="Treasure Loyalty Program"
        onClose={() => setHowItWorksIsVisible(false)}
      >
        <ChallengeInfoDialogContent />
      </InfoBox>
      <Loader loading={challengesQuery.isLoading} />
    </Box>
  );
};

export default ChallengesPage;
