import React, { useContext, useEffect, useState } from 'react';
import { BannerModel } from '../../../models/banner';
import { Box, Grid } from '@mui/material';
import { AppContext } from '../../../AppContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getBannerText } from '../../../utils/ui';
//* Styles
import './HomePageBanner.scss';

interface IProps {
  banner: BannerModel;
  setContainer: (input: number) => void;
}

const HomePageBanner: React.FunctionComponent<IProps> = ({ banner, setContainer }: IProps) => {
  const [boxHeight, setBoxHeight] = useState(0);
  const [flexDirection, setFlexDirection] = useState('row');
  const { state, dispatch } = useContext(AppContext);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const setHeightBasedOnScreenHeight = () => {
    const screenWidth = window.innerWidth;
    let newValue = 773;

    if (screenWidth <= 767) {
      newValue = 710;
      setFlexDirection('column');
    } else if (screenWidth <= 1023) {
      newValue = 520;
      setFlexDirection('row');
    } else if (screenWidth <= 1279) {
      newValue = 516;
      setFlexDirection('row');
    } else if (screenWidth <= 1438) {
      newValue = 516;
      setFlexDirection('row');
    } else if (screenWidth <= 1919) {
      newValue = 580;
      setFlexDirection('row');
    } else {
      setFlexDirection('row');
    }
    setBoxHeight(newValue);
    setContainer(newValue);
  };

  const getText = (banner: BannerModel) => {
    return getBannerText(banner, i18n, state, navigate, dispatch);
  };

  const getImg = (banner: BannerModel) => {
    return (
      <Box
        id="main-img"
        component="img"
        src={banner.desktopImg}
        className="banner__grid-item-image"
      />
    );
  };

  useEffect(() => {
    // Initial height calculation
    setHeightBasedOnScreenHeight();

    // Listen for window resize events and update height
    window.addEventListener('resize', setHeightBasedOnScreenHeight);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setHeightBasedOnScreenHeight);
    };
  }, []);

  return (
    <Box
      id="img-container"
      height={boxHeight}
      sx={{ flexDirection }}
      className="banner__grid-container"
    >
      <Grid
        container
        className="banner__grid"
        sx={{ backgroundImage: `url(${banner.mobileImg})`, height: boxHeight }}
      >
        <Grid className="banner__grid-item" item sm={5} md={5}>
          {getText(banner)}
        </Grid>
        <Grid item sm={7} md={7} className="banner__grid-item banner__grid-item--flex">
          {getImg(banner)}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePageBanner;
