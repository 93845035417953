import axios from 'axios';
import { getHeaders } from '../utils/http';

const baseUrl = process.env.BASE_SERVER_ADDRESS;

export const AllTournamentsQuery = async () => {
  return await axios.get(`${baseUrl}/tournaments/all`, getHeaders());
};

export const PassedTournamentsQuery = async () => {
  return await axios.get(`${baseUrl}/tournaments/passed`, getHeaders());
};

export const JoinTournament = async (tournamentsId: number) => {
  return await axios.post(`${baseUrl}/tournaments/join`, { id: tournamentsId }, getHeaders());
};
