import React, { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import { t } from 'i18next';
//* MUI
import { Box } from '@mui/material';
//* MUI Icons
import { Done } from '@mui/icons-material';
//* Components
import CustomChip from '../../../Common/CustomChip/CustomChip';
import CustomIconButton from '../../../Common/Buttons/CustomIconButton/CustomIconButton';
import MyAccountRowElement from '../../../Common/MyAccountRowElement/MyAccountRowElement';
//* Utils
import { getPastBonusesData } from '../../../../utils/bonuses';
//* Queries
//* Enums
//* Models
import { IBonusTableSelectedFilter, IHistoryBonus } from '../../../../models/bonus';
import { IMyAccountRowElement } from '../../../../models/wallet';
//* Styles
import './PastBonuses.scss';
import { getUserCurrencySymbol } from '../../../../utils/ui';

interface IProps {
  pastBonuses: IHistoryBonus[];
  handleBonusTableFilter: (
    selectedBonusContent: number,
    selectedFilterOption: IBonusTableSelectedFilter | null
  ) => void;
}

const PastBonuses = ({ pastBonuses, handleBonusTableFilter }: IProps) => {
  const { state } = useContext(AppContext);
  const userCurrency = getUserCurrencySymbol(state.user.data?.currency);

  const bonusFilterData = [
    { id: 0, label: t('myAccount.bonusPage.showAll') },
    { id: 1, label: t('myAccount.bonusPage.pastTitle') }
  ];

  const enrichPastBonuses = ({ pastBonusesData }: { pastBonusesData: IMyAccountRowElement[] }) => {
    const enrichedPastBonuses = [...pastBonusesData];

    enrichedPastBonuses.map((bonus) => {
      const bonusData = pastBonuses.find((pastBonus) => pastBonus.bonus.code === bonus.title);
      let chipLabel = '';

      if (bonusData) {
        chipLabel = bonusData.win > 0 ? 'Win' : 'Loss';
      }

      if (chipLabel.length > 0) {
        bonus.titleChips = <CustomChip condensed label={chipLabel} type="disabled" />;
      }

      if (bonusData && bonusData.win > 0) {
        bonus.customControls = (
          <Box className="past-bonus-controls">
            <Box className="past-bonus-controls__text">
              + {userCurrency}
              {bonusData.win}
            </Box>
            <CustomIconButton disabled color="gold" icon={<Done />} size="small" />
          </Box>
        );
      }
    });

    return enrichedPastBonuses;
  };

  const renderPastBonuses = () => {
    if (pastBonuses.length > 0) {
      const pastBonusesData = getPastBonusesData({ pastBonuses });
      const enrichedPastBonuses = enrichPastBonuses({ pastBonusesData });

      const bonuses: JSX.Element[] = enrichedPastBonuses.map((pastBonus, index) => (
        <MyAccountRowElement
          key={`${index}-past`}
          element={pastBonus}
          disabled={pastBonus.type === 'pastBonus'}
        />
      ));

      return bonuses.slice(0, 3);
    }
  };

  return (
    <>
      <Box className="bonuses__past">{renderPastBonuses()}</Box>
      <button
        className="bonuses__see-all"
        onClick={() => handleBonusTableFilter(1, bonusFilterData[1])}
      >
        {t('myAccount.bonusPage.seeAll')}
      </button>
    </>
  );
};

export default PastBonuses;
