import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
//* MUI
import { Box } from '@mui/material';
//* MUI Icons
import { ArrowBack, SearchOutlined } from '@mui/icons-material';
//* Components
import CustomPagination from '../../../Common/CustomPagination/CustomPagination';
import CustomIconButton from '../../../Common/Buttons/CustomIconButton/CustomIconButton';
//* Utils
import { freeSpinItemsPerPage, getFilteredVisibleFreeSpins } from '../../../../utils/bonuses';
//* Styles
import './FreeSpinsTable.scss';
import InputField from '../../../Common/Fields/InputField';

interface IProps {
  freeSpinsData: JSX.Element[];
  setSelectedBonusesContent: (selectedBonusesContent: number) => void;
}

const FreeSpinsTable = ({ freeSpinsData, setSelectedBonusesContent }: IProps) => {
  const dropdownRef = useRef(null);

  const [page, setPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSetFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
    setPage(1);
  };

  const freeSpins = getFilteredVisibleFreeSpins(page, filterValue, freeSpinsData);

  return (
    <Box className="bonuses-box free-spins-table">
      <Box className="free-spins-table__head">
        <Box className="free-spins-table__head-title">
          <CustomIconButton
            icon={<ArrowBack />}
            color="transparent"
            iconColor="white"
            onClick={() => setSelectedBonusesContent(0)}
          />
          <Box className="bonuses__title">{t('bonus.freeSpins')}</Box>
        </Box>
        <Box className="free-spins-table__head-filter" ref={dropdownRef}>
          <InputField
            value={filterValue}
            id="searchFreeSpins"
            isSecondaryStyle={true}
            onChange={handleSetFilterValue}
            className="free-spins-table__head-filter__search"
            placeholder={t('gamesPage.searchPlaceholder') as string}
            endIcon={<SearchOutlined sx={{ color: '#fff', width: '20px', height: '20px' }} />}
          />
        </Box>
      </Box>
      <Box className="free-spins free-spins-table__content">{freeSpins.visibleFreeSpins}</Box>
      <CustomPagination
        page={page}
        setPage={setPage}
        itemsPerPage={freeSpinItemsPerPage}
        totalItems={freeSpins.filteredVisibleFreeSpins.length}
      />
    </Box>
  );
};

export default FreeSpinsTable;
