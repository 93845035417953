import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { getProfileMenuItems } from '../../../utils/menu';
//* MUI
import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
//* MUI Icons
import { PowerSettingsNew } from '@mui/icons-material';
//* Enums
import { AuthRoutes, PublicRoutes } from '../../../enums/RouteEnums';
import { Actions } from '../../../enums/ActionEnums';
import { AppConsts } from '../../../enums/AppConsts';
//* Styles
import './ProfileMenu.scss';

interface IProps {
  anchorElProfile: null | HTMLElement;
  handleClose: () => void;
}

const ProfileMenu: React.FunctionComponent<IProps> = ({ anchorElProfile, handleClose }: IProps) => {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    localStorage.removeItem(AppConsts.AccessToken);
    dispatch({
      type: Actions.RemoveUser
    });
    handleClose();
    navigate(PublicRoutes.Home);
    queryClient.invalidateQueries();
  };

  const menuItems = getProfileMenuItems(t);

  const handleClick = (index: number) => {
    dispatch({
      type: Actions.SetMyAccountMenuIndex,
      payload: index
    });

    handleClose();
    navigate(AuthRoutes.MyAccount);
  };

  return (
    <Menu
      id="menu-profile"
      anchorEl={anchorElProfile}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      keepMounted
      open={Boolean(anchorElProfile)}
      onClose={handleClose}
      className="profile-menu"
    >
      {menuItems.map((item, index) => {
        const IconComponent = item.icon;
        return (
          <MenuItem key={`profile-menu-item-${index}`} onClick={() => handleClick(item.navIndex)}>
            <ListItemIcon className="profile-menu__item">
              <IconComponent className="profile-menu__item__icon" />
            </ListItemIcon>
            <span className="profile-menu__item__text">{item.title}</span>
          </MenuItem>
        );
      })}
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon className="profile-menu__item">
          <PowerSettingsNew className="profile-menu__item__icon" fontSize="small" />
        </ListItemIcon>
        <span className="profile-menu__item__text">{t('navigation.logout')}</span>
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
