import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { AppContext } from '../../../AppContext';
import { PublicRoutes } from '../../../enums/RouteEnums';
import { colors } from '../../../utils/theme';
import { useQuery } from 'react-query';
//* MUI
import { ArrowDropDown, ArrowDropUp, Close } from '@mui/icons-material';
import { Box, Drawer, IconButton, Menu, Tooltip, MenuItem as MenuItemMUI } from '@mui/material';
import { handleError, getNavItems, myAccountMobileNavItems } from '../../../utils/ui';
//* Icons
import logo from '../../../assets/logo.svg';
import logoClone from '../../../assets/logo_clone.svg';
import menu from '../../../assets/burger_menu.svg';
//* Components
import MenuItem from './MenuItem';
import Badge from '../../Challenges/Badge';
import CancelButton from '../../Common/Buttons/CancelButton';
import { AllChallengesQuery } from '../../../queries/challenges';
import LevelInformation from './LevelInformation/LevelInformation';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
//* Models
import { NavItem } from '../../../models/navigation';
//* Enums
import { Query } from '../../../enums/RequestEnums';
import { Actions } from '../../../enums/ActionEnums';
//* Styles
import './MobileNavigation.scss';
import { calculateCompletedChallengesPercentage } from '../../../utils/accountDetails';
import { ILanguageBaseModel } from '../../../models/language';
import { LanguagesQuery } from '../../../queries/languages';
import { useTranslation } from 'react-i18next';

const MobileNavigation: React.FunctionComponent = () => {
  const isClone = process.env.ENVIRONMENT === 'licence';
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(AppContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const languagesGetQuery = useQuery({
    queryKey: Query.Languages,
    queryFn: LanguagesQuery,
    onSuccess: ({ data }) => {
      setLanguages(data);
      if (selecteDbLanguage === null || data.length === 1) {
        const defaultLanguage = data.find(
          (l: ILanguageBaseModel) => l.code.toLocaleLowerCase() === 'en'
        );
        setSelectedDbLanguage(defaultLanguage);
      }
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const [languages, setLanguages] = useState<ILanguageBaseModel[] | []>(
    languagesGetQuery.data?.data || []
  );
  const [mobileNavigationHeight, setMobileNavigationHeight] = useState(window.innerHeight);
  const [anchorElLanguage, setAnchorElLanguage] = useState<null | HTMLElement>(null);
  const [selecteDbLanguage, setSelectedDbLanguage] = useState<ILanguageBaseModel | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setMobileNavigationHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const isSignedIn = !!state.user.token;

  const challengesQuery = useQuery(Query.AllChallenges, AllChallengesQuery, {
    onSuccess: ({ data }) => {
      setGroupData(data?.groupData);
      setPlaterData(data?.playerData);
    },
    onError: ({ response }) => handleError(response, dispatch)
  });
  const [groupData, setGroupData] = useState(challengesQuery.data?.data.groupData || []);
  const [playerData, setPlaterData] = useState(challengesQuery.data?.data.playerData || []);
  const [challengeLevelId] = useState(state.user.data?.challengeLevel?.id ?? 1);

  const percentage = calculateCompletedChallengesPercentage(
    groupData,
    challengeLevelId,
    playerData
  );

  const getLogo = () => {
    const logoSrc = isClone ? logoClone : logo;

    return (
      <img
        src={logoSrc}
        alt="logo"
        onClick={() => {
          navigate(PublicRoutes.Home);
          setIsMenuOpen(false);
        }}
        className="mobile-menu-logo"
      />
    );
  };

  const itemClicked = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  const setSelectedMenuIndex = (itemIndex: string) => {
    dispatch({
      type: Actions.SetMyAccountMenuIndex,
      payload: parseInt(itemIndex)
    });

    setIsMenuOpen(false);
  };

  const renderMyAccountMobileNavItems = (myAccountMobileNavItems: NavItem[]) => {
    const isMyAccountPage = location.pathname.includes('my-account');

    if (isMyAccountPage) {
      const myAccountNavItems = myAccountMobileNavItems.map((navItem, index) => (
        <MenuItem item={navItem} key={`mobile-menu-item-${index}`} onClick={setSelectedMenuIndex} />
      ));

      return <Box className="my-account-nav">{myAccountNavItems}</Box>;
    }
  };

  const onLanguageItemClick = (lang: string) => {
    i18n.changeLanguage(lang);
    setAnchorElLanguage(null);
    const language = languages.find((l) => l.code.toLowerCase() === lang.toLowerCase());
    setSelectedDbLanguage(language as ILanguageBaseModel);
    localStorage.setItem('selectedLanguage', language?.code.toLowerCase() as string);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'flex', lg: 'none' },
        height: isMenuOpen ? mobileNavigationHeight : 'auto'
      }}
    >
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        color="inherit"
        sx={{ padding: 0, marginRight: '31px' }}
      >
        <img src={menu} alt="menu" className="mobile-menu-burger" />
      </IconButton>
      {getLogo()}
      <Drawer
        anchor={'left'}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          sx: {
            background: colors.primary.second,
            borderRadius: '0 5px 5px 0'
          }
        }}
      >
        <Box className="mobile-menu-container">
          <Box>
            <Box className="mobile-menu-container__logo-row">
              <IconButton onClick={() => setIsMenuOpen(false)} sx={{ padding: 0 }}>
                <Close sx={{ color: 'common.white' }} />
              </IconButton>
              {getLogo()}
            </Box>
            {getNavItems(true).map((navItem, index) => (
              <MenuItem key={`mobile-menu-item-${index}`} item={navItem} onClick={itemClicked} />
            ))}
            {renderMyAccountMobileNavItems(myAccountMobileNavItems)}
            <Tooltip
              title={t('navigation.changeLanguageTooltip') as string}
              className="change-language-mobile-tooltip"
            >
              <IconButton onClick={(e) => setAnchorElLanguage(e.currentTarget)}>
                <Box className="change-language-mobile-tooltip__img-display-name-box">
                  <Box
                    component="img"
                    src={selecteDbLanguage?.flagUrl}
                    alt="Current Language"
                    className="flag-img-mobile--border-white"
                  />
                  <Box className="languages-menu-item-mobile__tooltip-display-name">
                    {selecteDbLanguage?.displayName}
                  </Box>
                </Box>
                {Boolean(anchorElLanguage) ? (
                  <ArrowDropUp className="arrow-drop-icon-mobile" />
                ) : (
                  <ArrowDropDown className="arrow-drop-icon-mobile" />
                )}
              </IconButton>
            </Tooltip>
            <Menu
              classes={{ paper: 'language-menu-mobile' }}
              id="menu-language"
              anchorEl={anchorElLanguage}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              keepMounted
              open={Boolean(anchorElLanguage)}
              onClose={() => setAnchorElLanguage(null)}
            >
              {languages
                .filter((l) => l.code.toLowerCase() !== selecteDbLanguage?.code.toLowerCase())
                .map((lang) => (
                  <MenuItemMUI
                    key={lang.id}
                    onClick={() => onLanguageItemClick(lang.code.toLowerCase())}
                    className="languages-menu-item-mobile"
                  >
                    <Box
                      component="img"
                      src={lang?.flagUrl}
                      alt={lang.code}
                      className="flag-img-mobile--border-white"
                    />
                    <Box className="languages-menu-item-mobile__display-name">
                      {lang.displayName}
                    </Box>
                  </MenuItemMUI>
                ))}
            </Menu>
          </Box>
          <Box>
            {isSignedIn ? (
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'column',
                  gap: '16px',
                  paddingX: '12px',
                  marginBottom: '4px',
                  width: '100%'
                }}
              >
                <Badge
                  showName
                  withBackground
                  name={state.user.data?.challengeLevel?.name}
                  rank={state.user.data?.challengeLevel?.rank ?? 0}
                />
                <LevelInformation
                  onLinkClick={itemClicked}
                  loyaltyPoints={state.user.data?.loyaltyPoints ?? 0}
                  completedChallengesPercentage={percentage}
                />
              </Box>
            ) : (
              <Box className="mobile-menu-container__actions">
                <CancelButton
                  text="Log In"
                  onClick={() => navigate(PublicRoutes.Signin)}
                  additionalStyle={{ color: 'white' }}
                />
                <PrimaryButton
                  text={t('account.signUp')}
                  onClick={() => navigate(PublicRoutes.Signup)}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileNavigation;
