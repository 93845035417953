import visa1Logo from '../assets/payments/visa1.png';
import visa2Logo from '../assets/payments/visa2.png';
import visaLogo from '../assets/payments/visa.png';
import masterCard from '../assets/payments/mastercard.png';
import paysafeLogo from '../assets/payments/paysafe.svg';
import jcbLogo from '../assets/payments/jcb.svg';
import skrillLogo from '../assets/payments/skrill.svg';
import bankTransfer from '../assets/payments/banktransfer_1.svg';
import bitcoin from '../assets/payments/bitcoin.svg';
import { VerificationStatuses } from '../models/account';
import { Payment } from '../enums/RequestEnums';

export interface IPaymentMethodDto {
  img: unknown;
  name: string;
  fee?: number;
  permissions?: VerificationStatuses[];
  isForFtd: boolean;
  isForStd: boolean;
  id: number;
  newImg: {
    default: string;
    selected: string;
  };
}

export const paymentMethods: IPaymentMethodDto[] = [
  {
    img: visa1Logo,
    name: Payment.Finateco
  },
  {
    img: visaLogo,
    name: Payment.Gumball
  },
  {
    img: visaLogo,
    name: Payment.SkyChain
  }
];

export const withdrawPaymentMethods: IPaymentMethodDto[] = [
  { img: bitcoin, name: 'Bitcoin' },
  // {
  //   img: visaLogo,
  //   name: 'Visa',
  //   fee: 1,
  //   permissions: [VerificationStatuses.Verified, VerificationStatuses.Pending]
  // },
  // {
  //   img: skrillLogo,
  //   name: 'Skrill',
  //   permissions: [VerificationStatuses.Verified]
  // },
  // {
  //   img: paysafeLogo,
  //   name: 'PaySafe',
  //   fee: 2,
  //   permissions: [VerificationStatuses.Verified]
  // },
  // {
  //   img: jcbLogo,
  //   name: 'JCB',
  //   permissions: [VerificationStatuses.Verified]
  // },
  // {
  //   img: paysafeLogo,
  //   name: 'PaySafe',
  //   fee: 3,
  //   permissions: [VerificationStatuses.Verified]
  // },
  // {
  //   img: skrillLogo,
  //   name: 'Skrill',
  //   permissions: [VerificationStatuses.Verified]
  // },
  // {
  //   img: paysafeLogo,
  //   name: 'PaySafe',
  //   permissions: [VerificationStatuses.Verified]
  // },
  // {
  //   img: visaLogo,
  //   name: 'Visa',
  //   permissions: [VerificationStatuses.Verified]
  // },
  {
    img: bankTransfer,
    name: 'Bank Transfer',
    permissions: [VerificationStatuses.Verified]
  }
];

export const paymentAmountOptions = [20, 40, 60, 100];
