import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  calculateTimeLeft,
  calculateTimeLeftTillMidnightUtc,
  getPrettyNumber
} from '../../utils/tournaments';
import './BannerTimer.scss';
import TournamentCardClockBox from '../Tournaments/TournamentCard/TournamentCardClockBox/TournamentCardClockBox';

interface IProps {
  text: string | undefined;
  startDate?: string | undefined;
  endDate?: string | undefined;
  showTimeLeftTillMidnight?: boolean;
}

const BannerTimer: React.FunctionComponent<IProps> = ({
  startDate,
  endDate,
  text,
  showTimeLeftTillMidnight = false
}: IProps) => {
  const { t, i18n } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft({
      isActive: false,
      endDate,
      startDate
    })
  );

  const [timeLeftTillMidnightUtc, setTimeLeftTillMidnightUtc] = useState(
    calculateTimeLeftTillMidnightUtc()
  );

  useEffect(() => {
    if (startDate && endDate) {
      setTimeout(() => {
        setTimeLeft(
          calculateTimeLeft({
            isActive: false,
            endDate,
            startDate
          })
        );
      }, 1000);
    } else {
      setTimeout(() => {
        setTimeLeftTillMidnightUtc(calculateTimeLeftTillMidnightUtc());
      }, 1000);
    }
  });

  return (
    <>
      <Box className="banner-timer-container">
        <Box className="banner-timer-container__text">{text}</Box>
        <Box className="banner-timer-container__time-box-container">
          {!showTimeLeftTillMidnight && (
            <TournamentCardClockBox
              title={t('tournaments.card.timer.days')}
              value={getPrettyNumber(timeLeft.days)}
              showOnEarnMoreBanners={true}
            />
          )}
          <TournamentCardClockBox
            title={t('tournaments.card.timer.hours')}
            value={getPrettyNumber(
              showTimeLeftTillMidnight ? timeLeftTillMidnightUtc.hours : timeLeft.hours
            )}
            showOnEarnMoreBanners={true}
          />
          <TournamentCardClockBox
            title={t('tournaments.card.timer.min')}
            value={getPrettyNumber(
              showTimeLeftTillMidnight ? timeLeftTillMidnightUtc.minutes : timeLeft.minutes
            )}
            showOnEarnMoreBanners={true}
          />
          <TournamentCardClockBox
            title={t('tournaments.card.timer.sec')}
            value={getPrettyNumber(
              showTimeLeftTillMidnight ? timeLeftTillMidnightUtc.seconds : timeLeft.seconds
            )}
            showOnEarnMoreBanners={true}
          />
        </Box>
      </Box>
    </>
  );
};

export default BannerTimer;
