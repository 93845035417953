import React, { useContext } from 'react';
import { Divider, Menu } from '@mui/material';
import WalletMenuItem from './WalletMenuItem';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import { AuthRoutes, PublicRoutes } from '../../../enums/RouteEnums';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
import { getUserCurrencySymbol } from '../../../utils/ui';
import { CardGiftcardOutlined, SaveAlt, Storefront } from '@mui/icons-material';
import StarsIcon from '@mui/icons-material/Stars';
import './WalletMenu.scss';

interface IProps {
  anchorElProfile: null | HTMLElement;
  handleClose: () => void;
}

const WalletMenu: React.FunctionComponent<IProps> = ({ anchorElProfile, handleClose }: IProps) => {
  const isClone = process.env.ENVIRONMENT === 'licence';
  const { state, dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (index: number) => {
    dispatch({
      type: Actions.SetMyAccountMenuIndex,
      payload: index
    });

    handleClose();
    navigate(AuthRoutes.MyAccount);
  };

  return (
    <Menu
      id="menu-wallet"
      anchorEl={anchorElProfile}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      keepMounted
      open={Boolean(anchorElProfile)}
      onClose={handleClose}
      className="wallet-menu"
    >
      <WalletMenuItem
        title={t('wallet.realBalance')}
        value={`${getUserCurrencySymbol(state.user.data?.currency)}${
          state.user.data?.realBalance || 0
        }`}
        onClick={() => handleClick(1)}
        actIcon={SaveAlt}
      />
      <Divider />
      <WalletMenuItem
        title={t('wallet.bonusBalance')}
        value={`${getUserCurrencySymbol(state.user.data?.currency)}${
          state.user.data?.bonusBalance || 0
        }`}
        onClick={() => handleClick(2)}
        actIcon={CardGiftcardOutlined}
      />
      <Divider />
      {!isClone && (
        <>
          <WalletMenuItem
            title={t('myAccount.coins')}
            value={state.user.data?.loyaltyPoints ?? 0}
            onClick={() => {
              handleClose();
              navigate(PublicRoutes.Shop);
            }}
            actIcon={Storefront}
            valueIcon={<StarsIcon className="stars-icon" />}
          />
          <Divider />
        </>
      )}
      <div className="wallet-menu__deposit">
        <PrimaryButton text={t('wallet.deposit')} onClick={() => handleClick(1)} />
      </div>
    </Menu>
  );
};

export default WalletMenu;
