import React from 'react';
import { Box, SxProps } from '@mui/material';
import './index.css';

interface IProps {
  position: 'left' | 'right';
  onClick: () => void;
  sx?: SxProps;
  children: React.ReactNode;
}

const FixedButton: React.FunctionComponent<IProps> = (props) => {
  const { position, sx, onClick, children } = props as IProps;
  const buttonPosition = position === 'left' ? { left: '25px' } : { right: '25px' };

  return (
    <Box
      className="fixed-button"
      sx={{
        ...buttonPosition,
        ...sx
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default FixedButton;
