export interface WheelElement {
  id: number;
  type: WheelElementType;
  amount: number;
}

export interface Spin {
  elementId: number;
  spinsLeft: number;
}

export enum WheelElementType {
  FreeSpins = 'FreeSpins',
  LoyaltyPoints = 'LoyaltyPoints',
  BonusMoney = 'BonusMoney',
  Lose = 'Lose',
  WheelSpins = 'WheelSpins'
}
