import React from 'react';
//* MUI
import { Box } from '@mui/material';
//* MUI icons
import { Error } from '@mui/icons-material';
//* Components
//* Models
//* Enums
//* Styles
import './ReceivingDetailsWarningMessage.scss';

interface IProps {
  message: string;
}

const ReceivingDetailsWarningMessage: React.FunctionComponent<IProps> = ({ message }) => {
  return (
    <Box className="receiving-details__warning">
      <Error />
      {message}
    </Box>
  );
};

export default ReceivingDetailsWarningMessage;
