import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import React, { JSXElementConstructor } from 'react';
import { colors } from '../../../utils/theme';
import Label from './Label';

interface IProps {
  id: string;
  label?: string;
  value: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (_: unknown, value: any | null) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionLabel: (value: any) => string;
  renderOption?: (props: React.HTMLAttributes<HTMLElement>, option: unknown) => JSX.Element;
  errorMsg?: string | null;
  startIcon?: JSX.Element;
  placeholder?: string;
  disableClearable?: boolean;
  PaperComponent?: JSXElementConstructor<React.HTMLAttributes<HTMLElement>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterOptions?: (options: any, state: any) => any[];
  noPaddingX?: boolean;
  borderRight?: boolean;
  disabled?: boolean;
  isDarkTheme?: boolean;
  border?: string;
  inputHeight?: number;
  inputFont?: string;
  customClassName?: string;
}

const AutocompleteField: React.FunctionComponent<IProps> = ({
  id,
  label,
  value,
  onChange,
  options,
  optionLabel,
  renderOption,
  errorMsg,
  startIcon,
  placeholder,
  disableClearable,
  PaperComponent,
  filterOptions,
  noPaddingX,
  borderRight,
  disabled = false,
  isDarkTheme,
  border,
  inputHeight = 46,
  inputFont,
  customClassName
}: IProps) => {
  const disabledDarkColor = 'rgba(255, 255, 255, 0.50)';
  return (
    <Box sx={{ width: '100%' }}>
      {label && (
        <Label
          id={id}
          label={label}
          sx={{
            color: disabled && isDarkTheme ? disabledDarkColor : isDarkTheme ? '#FFFFFF' : '#FFFFFF'
          }}
        />
      )}
      <Autocomplete
        className={customClassName}
        id={`${id}Autocomplete`}
        fullWidth
        color="info"
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={optionLabel}
        disabled={disabled}
        disableClearable={disableClearable ?? false}
        {...(PaperComponent && { PaperComponent })}
        {...(filterOptions && { filterOptions })}
        {...(renderOption && { renderOption })}
        sx={{
          '& .MuiAutocomplete-popupIndicator': {
            color: disabled && isDarkTheme ? disabledDarkColor : isDarkTheme ? '#FFFFFF' : ''
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor:
              disabled && isDarkTheme ? disabledDarkColor : isDarkTheme ? '#FFFFFF' : ''
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            name={id}
            {...(errorMsg !== undefined && {
              required: true,
              error: errorMsg !== null,
              helperText: errorMsg ?? ''
            })}
            sx={{
              height: inputHeight,
              '& .MuiOutlinedInput-root': {
                height: inputHeight,
                padding: '0 !important',
                '& fieldset': {
                  background: isDarkTheme ? '#25274A' : colors.white,
                  borderColor: isDarkTheme ? '#25274A' : colors.white,
                  border: border || 'none'
                }
              }
            }}
            {...(placeholder && { placeholder })}
            inputProps={{
              ...params.inputProps,
              sx: {
                color: isDarkTheme ? '#FFFFFF' : '',
                borderRight: borderRight
                  ? isDarkTheme
                    ? '1px solid #FFFFFF'
                    : `1px solid ${colors.info.main}`
                  : null,
                fontSize: '16px',
                lineHeight: '22px',
                padding: '12px 16px !important',
                paddingLeft: startIcon || noPaddingX ? '0px !important' : '16px !important',
                paddingRight: noPaddingX ? '0px !important' : '16px !important',
                zIndex: 2,
                fontFamily: inputFont
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end" sx={{ zIndex: 2 }}>
                  {params.InputProps.endAdornment}
                </InputAdornment>
              ),
              startAdornment: startIcon ? (
                <InputAdornment position="start">{startIcon}</InputAdornment>
              ) : null
            }}
          />
        )}
      />
    </Box>
  );
};

export default AutocompleteField;
