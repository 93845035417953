import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
import { useMutation, useQueryClient } from 'react-query';
//* MUI
import { Box, Fade } from '@mui/material';
//* Icons
import StarsIcon from '@mui/icons-material/Stars';
//* Components
import Loader from '../../Common/Loader';
import FreeSpinItem from './FreeSpinItem';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
//* Models
import { IFreeSpinExchange, IFreeSpinOfferViewModel } from '../../../models/shop';
//* Enums
import { PublicRoutes } from '../../../enums/RouteEnums';
//* Queries
import { PostExchange } from '../../../queries/shop';
//* Utils
import { handleError } from '../../../utils/ui';
import { Actions } from '../../../enums/ActionEnums';
import { isUserLoggedIn } from '../../../utils/validators';
//* Styles
import './FreeSpinsPrizeList.scss';

interface IProps {
  selectedFreeSpin: IFreeSpinOfferViewModel | null;
  resetSelectedFreeSpin: () => void;
  selectedFreeSpinItem: IFreeSpinExchange | null;
  onChange: (value: IFreeSpinExchange | null) => void;
  playerLevel: number;
}

const freeSpins = [
  { key: 'ten', amount: 10 },
  { key: 'twenty', amount: 20 },
  { key: 'forty', amount: 40 },
  { key: 'eighty', amount: 80 },
  { key: 'oneSixty', amount: 160 }
];

const FreeSpinsPrizeList: React.FunctionComponent<IProps> = ({
  selectedFreeSpin,
  resetSelectedFreeSpin,
  selectedFreeSpinItem,
  onChange,
  playerLevel
}: IProps) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(AppContext);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(PostExchange, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowPopup,
        payload: {
          isCongrat: true,
          state: `${state.user.data?.firstName}, successfully purchased ${selectedFreeSpin?.game.name} ${selectedFreeSpinItem?.amount} free spins`
        }
      });
      dispatch({
        type: Actions.UpdateUserLoyaltyPoints,
        payload: {
          loyaltyPoints: data as number
        }
      });
      onChange(null);
      resetSelectedFreeSpin();
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries('PostExchange');
    }
  });

  const handleExchange = () => {
    if (isUserLoggedIn()) {
      mutate({
        type: 'FreeSpin',
        amount: selectedFreeSpinItem?.amount as number,
        freeSpinOfferId: selectedFreeSpin?.id
      });
    } else {
      dispatch({
        type: Actions.ShowLoginPopup,
        payload: {
          state: { path: PublicRoutes.Shop }
        }
      });
    }
  };

  const getFreeSpinsTotalValue = () => {
    let value = 0;

    if (selectedFreeSpin && selectedFreeSpinItem) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value = selectedFreeSpin?.badges.find((b) => b.badge.rank === playerLevel)[
        selectedFreeSpinItem.key
      ];
    }

    return value;
  };

  const freeSpinsKey = selectedFreeSpin && selectedFreeSpinItem ? selectedFreeSpinItem?.key : null;
  const freeSpinsTotalValue = getFreeSpinsTotalValue();

  return (
    <Box className="free-spins__prizes">
      <Box className="free-spins__prizes__row">
        <span className="free-spins__prizes__title">{t('shop.freeSpins.selectSpins')}</span>
        <Box className="free-spins__prizes__list">
          {freeSpins.map((freeSpin, index) => (
            <FreeSpinItem
              key={index}
              freeSpin={freeSpin}
              setSelected={onChange}
              selected={selectedFreeSpinItem === freeSpin}
              prize={
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                selectedFreeSpin?.badges.find((b) => b.badge.rank === playerLevel)[
                  freeSpin.key
                ] as number
              }
            />
          ))}
        </Box>
      </Box>
      <Box className="free-spins__prizes__row">
        <Box className="free-spins__prizes__controls">
          <Box className="free-spins__prizes__controls-title">{t('shop.titles.total')}</Box>
          <Fade in key={freeSpinsKey} timeout={750}>
            <Box className="free-spins__prizes__controls-value">
              {freeSpinsTotalValue}
              <StarsIcon className="summary__price-icon" />
            </Box>
          </Fade>
        </Box>
        <PrimaryButton
          text={t('common.button.exchange')}
          onClick={handleExchange}
          disabled={!selectedFreeSpin || !selectedFreeSpinItem || isLoading}
        />
      </Box>
      <Loader loading={isLoading} />
    </Box>
  );
};

export default FreeSpinsPrizeList;
