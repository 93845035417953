import { AccountDetailsStatuses, VerificationStatuses } from '../models/account';
import { ChallengeGroup, ChallengeModel, PlayerData } from '../models/challenges';

export const getUserVerifiactionStatus = (userStatus: string) => {
  if (userStatus === VerificationStatuses.Verified) {
    userStatus = AccountDetailsStatuses.Verified;
  } else if (userStatus === VerificationStatuses.NotVerified) {
    userStatus = AccountDetailsStatuses.Unverified;
  } else if (userStatus === VerificationStatuses.Declined) {
    userStatus = AccountDetailsStatuses.Declined;
  }
  return userStatus;
};

export const calculateCompletedChallengesPercentage = (
  groupData: ChallengeGroup[],
  challengeLevelId: number,
  playerData: PlayerData
) => {
  const currentGroupData = groupData?.find(
    (group: ChallengeGroup) => group.id === challengeLevelId
  );

  const completedChallenges = currentGroupData?.challenges.filter(
    (groupChallenge: ChallengeModel) => playerData?.completedChallenges?.includes(groupChallenge.id)
  );

  const completedChallengesPercentage =
    (100 * (completedChallenges?.length as number)) /
    (currentGroupData?.amountToComplete as number);

  return completedChallengesPercentage;
};
