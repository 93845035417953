import { createTheme } from '@mui/material/styles';

export const colors = {
  primary: {
    main: '#031E44',
    second: '#0D1365',
    dark: '#0C0F2B'
  },
  info: {
    main: '#2575FC',
    dark: '#08287C'
  },
  grey: {
    placeholder: '#B5BAC3',
    background: '#E6E8EB',
    disabled: '#B5BAC3',
    50: '#838C9A',
    main: '#CED1D7'
  },
  white: '#FFFFFF',
  gradients: {
    yellow: 'linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%)',
    yellowRgb: 'rgb(227,171,0)'
  },
  error: {
    main: '#d32f2f'
  },
  background: {
    blue: '#0d0f36',
    blueSecondary: 'rgba(255, 255, 255, 0.2)',
    grey: 'rgba(255, 255, 255, 0.1)'
  },
  statuses: {
    success: '#2CDB78',
    pending: '#F09A05',
    failed: '#E03828'
  }
};

const shapes = {
  borderRadius: '100px'
};

export const buildTheme = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primary.main,
        dark: colors.primary.dark
      },
      info: {
        main: colors.info.main,
        dark: colors.info.dark
      },
      grey: {
        50: colors.grey[50],
        300: colors.grey.disabled
      },
      common: {
        white: colors.white
      },
      error: {
        main: colors.error.main
      }
    },
    typography: {
      button: {
        textTransform: 'none',
        height: '54px',
        borderRadius: shapes.borderRadius,
        fontWeight: 700,
        fontSize: '16px',
        fontFamily: 'Poppins'
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: shapes.borderRadius,
            '&.Mui-disabled': {
              color: colors.white,
              backgroundColor: colors.grey.background,
              background: colors.grey.background
            }
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: colors.white,
            opacity: 1,
            '&.Mui-selected': {
              background: colors.gradients.yellow,
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            background: colors.gradients.yellow,
            bottom: '23px'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'Poppins'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: shapes.borderRadius,
            color: colors.info.main,
            '&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root': {
              color: colors.info.main
            }
          },
          input: {
            '&::placeholder': {
              color: colors.grey.placeholder
            },
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px white inset',
              WebkitTextFillColor: colors.info.main
            }
          }
        }
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: colors.grey.main,
            '&.Mui-completed': {
              color: colors.info.main
            },
            '&.Mui-active': {
              color: colors.info.main
            }
          },
          text: {
            fill: colors.white,
            fontFamily: 'Poppins',
            fontWeight: 600
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: colors.info.main,
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '18px'
          }
        }
      }
    }
  });

  return theme;
};
