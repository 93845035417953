import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
//* MUI
import { Box } from '@mui/material';
//* MUI Icons
import { ArrowBack, Done } from '@mui/icons-material';
//* Components
import CustomPagination from '../../../Common/CustomPagination/CustomPagination';
import CustomChip from '../../../Common/CustomChip/CustomChip';
import AutocompleteField from '../../../Common/Fields/AutocompleteField';
import CustomIconButton from '../../../Common/Buttons/CustomIconButton/CustomIconButton';
import MyAccountRowElement from '../../../Common/MyAccountRowElement/MyAccountRowElement';
import ActiveBonusControls from '../RecentBonuses/ActiveBonusControls/ActiveBonusControls';
import SuggestedBonusControls from '../RecentBonuses/SuggestedBonusControls/SuggestedBonusControls';
//* Utils
import { getUserCurrencySymbol } from '../../../../utils/ui';
import {
  borderColor,
  bonusItemsPerPage,
  getPastBonusesData,
  getRecentBonusesData,
  getActiveBonusProgress,
  getFilteredVisibleBonuses
} from '../../../../utils/bonuses';
//* Models
import { IMyAccountRowElement } from '../../../../models/wallet';
import {
  IBonus,
  IActiveBonus,
  IHistoryBonus,
  IBonusTableSelectedFilter
} from '../../../../models/bonus';
//* Styles
import './BonusesTable.scss';

interface IProps {
  // bonuses: JSX.Element[];
  suggestedBonuses: IBonus[];
  pastBonuses: IHistoryBonus[];
  activeBonus: IActiveBonus | undefined;
  selectedFilterOption: IBonusTableSelectedFilter;
  handleClaimBonus: (suggestedBonus: IBonus) => void;
  handleDeleteBonusConfirmationOpen: (bonus: IMyAccountRowElement) => void;
  handleBonusTableFilter: (
    selectedBonusContent: number,
    selectedFilterOption: IBonusTableSelectedFilter | null | undefined
  ) => void;
}

const BonusesTable = ({
  activeBonus,
  pastBonuses,
  suggestedBonuses,
  handleClaimBonus,
  selectedFilterOption,
  handleBonusTableFilter,
  handleDeleteBonusConfirmationOpen
}: IProps) => {
  const dropdownRef = useRef(null);
  const { state } = useContext(AppContext);
  const userCurrency = getUserCurrencySymbol(state.user.data?.currency);
  const { i18n } = useTranslation();
  const bonusFilterData = [
    { id: 0, label: t('myAccount.bonusPage.showAll') },
    { id: 1, label: t('myAccount.bonusPage.pastTitle') }
  ];

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(selectedFilterOption);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigateBack = (
    _: unknown,
    selectedBonusesContent: number,
    selectedFilterOption?: IBonusTableSelectedFilter | null
  ) => {
    handleBonusTableFilter(selectedBonusesContent, selectedFilterOption);
  };

  const handleSetFilter = (_: unknown, filterValue: IBonusTableSelectedFilter) => {
    setFilter(filterValue);
    setPage(1);
  };

  const enrichRecentBonuses = ({
    activeBonus,
    recentBonuses
  }: {
    activeBonus: IActiveBonus | undefined;
    recentBonuses: IMyAccountRowElement[];
  }) => {
    const enrichedRecentBonuses = [...recentBonuses];
    let disableClaimBonus = false;

    enrichedRecentBonuses.map((bonus: IMyAccountRowElement) => {
      if (activeBonus && bonus.type === 'activeBonus') {
        const progress = getActiveBonusProgress(activeBonus);

        disableClaimBonus = Object.keys(activeBonus).length > 0;
        bonus.titleChips = <CustomChip condensed label="Active" type="highlighted" />;
        bonus.customControls = <ActiveBonusControls bonus={activeBonus} progress={progress} />;
      }

      if (bonus.type === 'suggestedBonus') {
        const bonusData = suggestedBonuses.find(
          (suggestedBonus) => suggestedBonus.code === bonus.title
        );

        if (bonusData) {
          bonus.customControls = (
            <SuggestedBonusControls
              bonus={bonusData}
              handleClaimBonus={handleClaimBonus}
              disableClaimBonus={disableClaimBonus}
            />
          );
        }
      }
    });

    return enrichedRecentBonuses;
  };

  const prepareRecentBonuses = () => {
    const recentBonuses = getRecentBonusesData({
      activeBonus,
      suggestedBonuses,
      language: i18n.language
    });

    const enrichedRecentBonuses = enrichRecentBonuses({ activeBonus, recentBonuses });

    const bonuses: JSX.Element[] = enrichedRecentBonuses.map((recentBonus, index) => (
      <MyAccountRowElement
        key={`${index}-recent`}
        element={recentBonus}
        handleConfirmation={handleDeleteBonusConfirmationOpen}
      />
    ));

    return bonuses;
  };

  const enrichPastBonuses = ({ pastBonusesData }: { pastBonusesData: IMyAccountRowElement[] }) => {
    const enrichedPastBonuses = [...pastBonusesData];

    enrichedPastBonuses.map((bonus) => {
      const bonusData = pastBonuses.find((pastBonus) => pastBonus.bonus.code === bonus.title);
      let chipLabel = '';

      if (bonusData) {
        chipLabel = bonusData.win > 0 ? 'Win' : 'Loss';
      }

      if (chipLabel.length > 0) {
        bonus.titleChips = <CustomChip condensed label={chipLabel} type="disabled" />;
      }

      if (bonusData && bonusData.win > 0) {
        bonus.customControls = (
          <Box className="past-bonus-controls">
            <Box className="past-bonus-controls__text">
              + {userCurrency}
              {bonusData.win}
            </Box>
            <CustomIconButton disabled color="gold" icon={<Done />} size="small" />
          </Box>
        );
      }
    });

    return enrichedPastBonuses;
  };

  const preparePastBonuses = () => {
    if (pastBonuses.length > 0) {
      const pastBonusesData = getPastBonusesData({ pastBonuses });
      const enrichedPastBonuses = enrichPastBonuses({ pastBonusesData });

      const bonuses: JSX.Element[] = enrichedPastBonuses.map((pastBonus, index) => (
        <MyAccountRowElement
          key={`${index}-past`}
          element={pastBonus}
          disabled={pastBonus.type === 'pastBonus'}
        />
      ));

      return bonuses;
    }
  };

  const renderBonuses = () => {
    const bonuses: JSX.Element[] = [];
    const pastBonuses = preparePastBonuses();
    const recentBonuses = prepareRecentBonuses();

    if (recentBonuses && recentBonuses.length > 0) {
      recentBonuses.map((recentBonus) => bonuses.push(recentBonus));
    }

    if (pastBonuses && pastBonuses?.length > 0) {
      pastBonuses.map((pastBonus) => bonuses.push(pastBonus));
    }

    return bonuses;
  };

  const bonuses = renderBonuses();
  const filteredVisibleBonuses = getFilteredVisibleBonuses(bonuses, filter, page);

  return (
    <Box className="bonuses-box bonuses-table">
      <Box className="bonuses-table__head">
        <Box className="bonuses-table__head-title">
          <CustomIconButton
            icon={<ArrowBack />}
            color="transparent"
            iconColor="white"
            onClick={() => handleNavigateBack(0, 0, null)}
          />
          <Box className="bonuses__title">{t('myAccount.bonusPage.allBonuses')}</Box>
        </Box>
        <Box className="bonuses-table__head-filter" ref={dropdownRef}>
          <AutocompleteField
            id="type"
            isDarkTheme
            value={filter}
            disableClearable
            border={borderColor}
            options={bonusFilterData}
            onChange={handleSetFilter}
            optionLabel={(option) => option.label}
          />
        </Box>
      </Box>
      <Box className="bonuses-table__content">{filteredVisibleBonuses}</Box>
      <CustomPagination
        page={page}
        setPage={setPage}
        itemsPerPage={bonusItemsPerPage}
        totalItems={bonuses.length}
      />
    </Box>
  );
};

export default BonusesTable;
