import React, { useEffect, useRef, useState } from 'react';
//* MUI
import { Box } from '@mui/material';
//* Components
import CustomIconButton from '../../Common/Buttons/CustomIconButton/CustomIconButton';
//* Icons
import { LockOutlined, CheckOutlined, Close, Stars } from '@mui/icons-material';
//* Custom Hooks
import useClickOutside from '../../../customHooks/useClickOutside';
//* Styles
import './ChallengeCard.scss';

interface IProps {
  image: string;
  title: string;
  subtitle: string;
  isLocked?: boolean;
  isClaimed?: boolean;
  isCompact?: boolean;
  description?: string;
  isPopupCard?: boolean;
}

const ChallengeCard = ({
  image,
  title,
  subtitle,
  isLocked,
  isClaimed,
  isCompact,
  description
}: IProps) => {
  const popupRef = useRef(null);
  const [popupIsVisible, setPopupIsVisible] = useState(false);

  useEffect(() => {
    if (popupIsVisible) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [popupIsVisible]);

  useClickOutside(popupRef, () => setPopupIsVisible(false));

  const handleSetPopupVisibility = () => {
    const screenWidth = window.innerWidth;

    if (!isCompact && screenWidth < 768) {
      setPopupIsVisible(true);
    }
  };

  return (
    <>
      <Box
        onClick={handleSetPopupVisibility}
        className={`card ${isCompact ? 'card--is-compact' : ''} ${
          isClaimed ? 'card--is-claimed' : ''
        } ${isLocked ? 'card--is-locked' : ''}`}
      >
        {isLocked && <LockOutlined className="card__icon-container" />}
        {isClaimed && (
          <Box className="card__icon-container card__icon-container--adjusted">
            <CheckOutlined />
          </Box>
        )}
        <Box className="card-image" src={image} component="img" />
        <Box className="card-title">{title}</Box>
        <Box className="card-subtitle">
          {subtitle} <Stars />
        </Box>
        {description && <Box className="card-description">{description}</Box>}
      </Box>
      <Box
        className={`card__popup ${popupIsVisible ? 'card__popup--is-visible' : ''}  ${
          isClaimed ? 'card--is-claimed' : ''
        } ${isLocked ? 'card--is-locked' : ''}`}
      >
        <Box className="card__popup-content__close">
          <CustomIconButton icon={<Close />} onClick={() => setPopupIsVisible(false)} />
        </Box>
        <Box ref={popupRef} className="card card__popup-content">
          {isLocked && <LockOutlined className="card__icon-container" />}
          {isClaimed && (
            <Box className="card__icon-container card__icon-container--adjusted">
              <CheckOutlined />
            </Box>
          )}
          <Box className="card-image" src={image} component="img" />
          <Box className="card-title">{title}</Box>
          <Box className="card-subtitle">{subtitle}</Box>
          {description && <Box className="card-description">{description}</Box>}
        </Box>
      </Box>
    </>
  );
};

export default ChallengeCard;
