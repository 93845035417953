import React from 'react';
//* MUI
import { Box } from '@mui/material';
//* Utils
import { IPaymentMethodDto } from '../../../../utils/paymentConstants';
//* Styles
import './DepositPaymentMethod.scss';

interface IProps {
  paymentMethod: IPaymentMethodDto;
  selectedPaymentMethod: IPaymentMethodDto | null;
  handlePaymentMethodChange: (payment: IPaymentMethodDto) => void;
}

const DepositPaymentMethod = ({
  paymentMethod,
  selectedPaymentMethod,
  handlePaymentMethodChange
}: IProps) => {
  const isSelected = selectedPaymentMethod?.id === paymentMethod.id;
  const paymentMethodLogo = isSelected
    ? paymentMethod.newImg.selected
    : paymentMethod.newImg.default;

  return (
    <Box
      className={`payment-method ${isSelected ? 'payment-method--is-selected' : ''}`}
      onClick={() => handlePaymentMethodChange(paymentMethod)}
    >
      <Box
        alt={'logo'}
        component="img"
        src={paymentMethodLogo}
        sx={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    </Box>
  );
};

export default DepositPaymentMethod;
