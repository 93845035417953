import React, { useState } from 'react';
import { Box, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import Label from './Label';
import moment from 'moment';
import { getBorderColor } from '../../Account/SignupPage';
import { colors } from '../../../utils/theme';

interface IProps {
  id: string;
  value: moment.Moment | null;
  placeholder?: string;
  label?: string;
  onChange: (value: moment.Moment | null) => void;
  errorMsg?: string | null;
  startIcon?: JSX.Element;
  maxDate?: moment.Moment;
  isDarkTheme?: boolean;
  disabled?: boolean;
  border?: string;
}

const DateField: React.FunctionComponent<IProps> = ({
  id,
  value,
  placeholder,
  label,
  onChange,
  errorMsg,
  startIcon,
  maxDate,
  isDarkTheme,
  disabled,
  border
}: IProps) => {
  const [openPicker, setOpenPicker] = useState(false);
  const disabledDarkColor = 'rgba(255, 255, 255, 0.50)';

  return (
    <Box sx={{ width: '100%' }}>
      {label && (
        <Label
          id={id}
          label={label}
          sx={{
            color: disabled && isDarkTheme ? disabledDarkColor : isDarkTheme ? '#FFFFFF' : '#FFFFFF'
          }}
        />
      )}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          open={openPicker}
          onOpen={() => setOpenPicker(true)}
          onClose={() => setOpenPicker(false)}
          disabled={disabled}
          value={value}
          onChange={onChange}
          clearable
          inputFormat="DD/MM/YYYY"
          {...(maxDate && { maxDate })}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              {...params}
              onClick={() => setOpenPicker(true)}
              // variant="outlined"
              fullWidth
              color="info"
              {...(errorMsg !== undefined && {
                required: true,
                error: errorMsg !== null,
                helperText: errorMsg ?? ''
              })}
              disabled
              {...(placeholder && { placeholder })}
              sx={{
                height: '46px',
                '& .MuiOutlinedInput-root': {
                  height: '46px',
                  '& fieldset': {
                    background: isDarkTheme ? '#25274A' : colors.white,
                    border: border
                      ? `${border}`
                      : `1px solid ${isDarkTheme ? '#25274A' : getBorderColor(value)} !important`
                  }
                },
                '& .Mui-disabled': {
                  color: errorMsg ? 'error.main' : 'info.main',
                  WebkitTextFillColor: 'inherit'
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor:
                    disabled && isDarkTheme ? disabledDarkColor : isDarkTheme ? '#FFFFFF' : ''
                }
              }}
              {...(placeholder && { placeholder })}
              inputProps={{
                ...params.inputProps,
                sx: {
                  fontSize: '16px',
                  lineHeight: '22px',
                  padding: '12px 16px',
                  zIndex: 2
                }
              }}
              InputProps={{
                startAdornment: startIcon ? (
                  <InputAdornment position="start">{startIcon}</InputAdornment>
                ) : null
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateField;
