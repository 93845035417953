import React from 'react';
import { useTranslation } from 'react-i18next';
//* Components
import CustomIconButton from '../../Common/Buttons/CustomIconButton/CustomIconButton';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
//* Models
import { Promotion } from '../../../models/bonus';
import { getSplittedPromoTitle } from '../../../utils/promos';
//* MUI
import { Box, Fade } from '@mui/material';
//* MUI icons
import { InfoOutlined, LockOutlined, CheckOutlined } from '@mui/icons-material';
//* Styles
import './PromotionTile.scss';

type PromotionTileProps = {
  index: number;
  promotion: Promotion;
  showInfo: (arg: Promotion) => void;
  onClaim: (promo: Promotion) => void;
};

const PromotionTile = ({ index, promotion, showInfo, onClaim }: PromotionTileProps) => {
  const { t, i18n } = useTranslation();

  const getPromoTitle = () => {
    const promoTitle =
      promotion.promoDetails.find(
        (details) => details.language.toUpperCase() === i18n.language.toUpperCase()
      )?.title ?? (promotion.promoDetails[0]?.title as string);

    return getSplittedPromoTitle(promoTitle);
  };

  const buttonText = t('promotionsPage.claim');

  const getActionButton = () => {
    switch (promotion.state) {
      case 'Locked':
        return <CustomIconButton color="gold" icon={<LockOutlined />} />;
      case 'Claimed':
        return <CustomIconButton color="gold" icon={<CheckOutlined />} />;
      default:
        return (
          <PrimaryButton
            text={buttonText}
            className="promotion__actions__button"
            onClick={() => onClaim(promotion)}
          />
        );
    }
  };

  return (
    <Fade key={`fade-${index}`} in timeout={1000 + index * 600}>
      <Box
        className={`promotion ${promotion.state === 'Claimed' ? 'promotion--claimed' : ''}`}
        sx={{
          backgroundImage: `url(${promotion.imgLink})`
        }}
      >
        <Box className="promotion__content">
          {promotion.packName && (
            <span className="promotion__content__category">{promotion.packName}</span>
          )}
          <Box className="promotion__content__overline">
            {promotion.promoDetails.find(
              (details) => details.language.toUpperCase() === i18n.language.toUpperCase()
            )?.overline ?? promotion.promoDetails[0]?.overline}
          </Box>
          <Box className="promotion__content__title">{getPromoTitle()}</Box>
        </Box>
        <Box className="promotion__actions">
          {getActionButton()}
          <CustomIconButton icon={<InfoOutlined />} onClick={() => showInfo(promotion)} />
        </Box>
      </Box>
    </Fade>
  );
};

export default PromotionTile;
