import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Paper,
  TextField
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import backGround from '../../assets/login.svg';
import logo from '../../assets/logo_light.svg';
import logoClone from '../../assets/logo_clone_light.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import CSS from 'csstype';
import { colors } from '../../utils/theme';
import { useMutation, useQueryClient } from 'react-query';
import { LoginQuery } from '../../queries/account';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthRoutes, PublicRoutes } from '../../enums/RouteEnums';
import { useTranslation } from 'react-i18next';
import ResetPasswordPage from './ForgerPasswordPage';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { AppConsts } from '../../enums/AppConsts';
import { getBorderColor, getIconColor } from './SignupPage';
import Loader from '../Common/Loader';
import { makeStyles } from '@mui/styles';
import { handleError } from '../../utils/ui';

const useStyles = makeStyles({
  root: {
    fontFamily: 'Poppins'
  }
});

export const labelStyle: CSS.Properties = {
  top: '10px',
  color: colors.primary.main,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Poppins'
};

type IErrors = {
  username: string | null;
  password: string | null;
};

const defaultErrorsObj: IErrors = {
  username: null,
  password: null
};

const SigninPage: React.FunctionComponent = () => {
  const isClone = process.env.ENVIRONMENT === 'licence';
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openResetPass, setOpenResetPass] = useState(false);
  const [visiblePass, setVisiblePass] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);

  const { mutate, isLoading } = useMutation(LoginQuery, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.SetUser,
        payload: {
          token: data.accessToken,
          data
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
      // navigate(PublicRoutes.Home);
      navigate(
        location.state?.path ?? (data.hasLoggedInBefore ? PublicRoutes.Home : AuthRoutes.Wallet)
      );
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries();
    }
  });

  if (state.user.data) {
    navigate(PublicRoutes.Home);
  }

  const handleUsernameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, username: t('errors.shortUsername') });
    } else {
      setFormErrors({ ...formErrors, username: null });
    }
    setUsername(value);
  };

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    if (value.length < 4) {
      setFormErrors({ ...formErrors, password: t('errors.shortPass') });
    } else {
      setFormErrors({ ...formErrors, password: null });
    }
    setPassword(value);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSignin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let hasErrors = false;
    const errors: IErrors = {
      username: null,
      password: null
    };

    if (username.length < 4) {
      hasErrors = true;
      errors.username = t('errors.shortUsername');
    }
    if (password.length < 4) {
      hasErrors = true;
      errors.password = t('errors.shortPass');
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      mutate({ username, password });
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={8} component={Paper} square>
        <Box
          sx={{
            mt: {
              xs: '16px',
              sm: '16px',
              md: '45px'
            },
            ml: {
              xs: '16px',
              sm: '16px',
              md: '45px'
            },
            fontFamily: 'Poppins'
          }}
        >
          <img
            src={isClone ? logoClone : logo}
            alt="logo"
            onClick={() => navigate(PublicRoutes.Home)}
            style={{ cursor: 'pointer', width: '184px' }}
          />
        </Box>
        <Box
          sx={{
            my: {
              xs: 1,
              sm: 4,
              md: 8
            },
            mx: {
              xs: 2,
              sm: 2,
              md: 4
            },
            color: 'primary.main',
            fontWeight: 700,
            fontStyle: 'normal',
            fontFamily: 'Poppins',
            fontSize: {
              xs: '28px',
              sm: '28px',
              md: '40px'
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: {
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'center'
            }
          }}
        >
          {t('account.signIn')}
        </Box>
        <Box
          sx={{
            mx: {
              xs: 2,
              sm: 2,
              md: 4
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            component="form"
            onSubmit={handleSignin}
            sx={{
              maxWidth: 548
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  mt: {
                    xs: '0px',
                    sm: '0px',
                    md: '32px'
                  },
                  height: 66
                }}
              >
                <InputLabel htmlFor="username" style={labelStyle}>
                  {t('account.username')}
                </InputLabel>
                <TextField
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="username"
                  name="username"
                  value={username}
                  onChange={handleUsernameChange}
                  error={formErrors.username !== null}
                  helperText={formErrors.username ? formErrors.username : ''}
                  placeholder={t('account.usernamePlaceHolder') as string}
                  color="info"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon
                          sx={{ fill: getIconColor(username, formErrors.username) }}
                        />
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    height: '46px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: getBorderColor(username)
                      }
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ marginTop: { xs: '35px', sm: '35px', md: '50px' }, height: 66 }}
              >
                <InputLabel htmlFor="password" style={labelStyle}>
                  {t('account.password')}
                </InputLabel>
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  type={visiblePass ? 'text' : 'password'}
                  id="password"
                  color="info"
                  placeholder={t('account.passPlaceholder') as string}
                  value={password}
                  onChange={handlePasswordChange}
                  error={formErrors.password !== null}
                  helperText={formErrors.password ? formErrors.password : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon sx={{ fill: getIconColor(password, formErrors.password) }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setVisiblePass(!visiblePass)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {visiblePass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    height: '46px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: getBorderColor(password)
                      }
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: '50px' }}>
              <Grid item xs>
                <FormControlLabel
                  control={<Checkbox value="remember" color="info" />}
                  label={t('account.remember') as string}
                  sx={{
                    color: (t) => t.palette.grey[50],
                    fontWeight: 400,
                    fontSize: '16px'
                  }}
                  className={classes.root}
                />
              </Grid>
              <Grid item sx={{ mt: '10px' }}>
                <Link
                  onClick={() => setOpenResetPass(true)}
                  variant="body2"
                  color="info.main"
                  style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: '16px'
                  }}
                >
                  {t('account.forgotPass')}
                </Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              className="custom-button"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{
                mt: { xs: 1, sm: 2, md: 3 },
                mb: 2,
                background: 'linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%)',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                '&:hover': {
                  background: (t) => t.palette.common.white,
                  boxShadow: '0px 0px 10px rgba(255, 212, 27, 0.5)'
                },
                color: 'primary.main'
              }}
            >
              {t('account.signIn')}
            </Button>
            <Box
              sx={{
                marginTop: { xs: '22px', sm: '22px', md: '32px' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: (t) => t.palette.grey[50],
                fontWeight: 500,
                fontSize: '14px'
              }}
            >
              <Grid container>
                <Grid item xs={5}>
                  <Divider />
                </Grid>
                <Grid item xs={1}>
                  <div
                    style={{
                      position: 'relative',
                      top: '-9px',
                      textAlign: 'center',
                      fontFamily: 'Poppins'
                    }}
                  >
                    {t('common.or')}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                marginTop: '16px',
                marginBottom: '24px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: (t) => t.palette.grey[50],
                fontWeight: 500,
                fontSize: '14px',
                fontFamily: 'Poppins'
              }}
            >
              {t('account.noAccount')}
            </Box>
            <Button
              className="custom-button"
              fullWidth
              variant="outlined"
              disabled={isLoading}
              sx={{
                mt: {
                  xs: 1,
                  sm: 1,
                  md: 3
                },
                mb: 2,
                color: 'primary.main',
                boxShadow: '0px 0px 5px rgba(37, 117, 252, 0.5)',
                '&:hover': {
                  background: (t) => t.palette.common.white,
                  boxShadow: '0px 0px 5px rgba(37, 117, 252, 0.5)',
                  border: 'none'
                },
                border: (t) => `2px solid ${t.palette.info.main}`
              }}
              onClick={() => navigate(PublicRoutes.Signup)}
            >
              {t('account.createAccount')}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={4}
        sx={{
          backgroundImage: `url(${backGround})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => t.palette.primary.main,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      {openResetPass && <ResetPasswordPage handleClose={() => setOpenResetPass(false)} />}
      <Loader loading={isLoading} />
    </Grid>
  );
};

export default SigninPage;
