import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import moment from 'moment';
//* MUI
import { Box, Fade } from '@mui/material';
import { CheckOutlined, InfoOutlined } from '@mui/icons-material';
//* Components
import InfoBox from '../../Common/Dialogs/InfoBox';
import InfoPairs from '../../Common/Fields/InfoPairs';
import CustomChip from '../../Common/CustomChip/CustomChip';
import TournamentCardGames from './TournamentCardGames/TournamentCardGames';
import PrimaryButton from '../../Common/Buttons/PrimaryButton/PrimaryButton';
import CustomIconButton from '../../Common/Buttons/CustomIconButton/CustomIconButton';
import TournamentCardExtraInfo from './TournamentCardExtraInfo/TournamentCardExtraInfo';
//* Models
import { InfoPair } from '../../../models/bonus';
import { IPassedTournament, ITournament, ITournamentRanking } from '../../../models/tournaments';
//* Enums
import { Query } from '../../../enums/RequestEnums';
import { Actions } from '../../../enums/ActionEnums';
//* Queries
import { JoinTournament } from '../../../queries/tournaments';
//* Utils
// import { colors } from '../../../utils/theme';
import { handleError } from '../../../utils/ui';
import { AppContext } from '../../../AppContext';
import { DATE_FORMAT } from '../../../utils/tournaments';
import { isUserLoggedIn } from '../../../utils/validators';
//* Images
import doubleCoin from '../../../assets/tournaments/doubleCoin.svg';
//* Styles
import './TournamentCard.scss';

// TODO move this to tournaments-utils.js

interface IProps {
  index: number;
  isCompact?: boolean;
  hasJoinedTournament?: boolean;
  ranking?: ITournamentRanking[];
  tournament: ITournament | IPassedTournament;
}

const TournamentCard: React.FunctionComponent<IProps> = ({
  index,
  isCompact,
  tournament,
  hasJoinedTournament
}) => {
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const [extraInfoExpanded, setExtraInfoExpanded] = useState(false);
  const [openPromoInfo, setOpenPromoInfo] = useState(false);

  const joinTournamentQuery = useMutation(JoinTournament, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: t('tournaments.card.successfullyJoinedPayloadText')
        }
      });
    },
    onError: ({ response }) => handleError(response, dispatch),
    onSettled: () => {
      queryClient.invalidateQueries(Query.AllTournaments);
    }
  });

  const toggleDialogExtraInfoExpand = () => {
    setExtraInfoExpanded(!extraInfoExpanded);
  };

  const getInfoBoxItems = () => {
    const infoBoxItems: InfoPair[] = [
      {
        key: (
          <Box className="prize-row--is-header">
            <Box className="prize-row-cell">
              <em>#</em>
              <span>User</span>
            </Box>
            <Box className="prize-row-cell--centered">
              {tournament?.type.toLowerCase() === 'spin' ? 'Spins' : 'Wager'}
            </Box>
            <Box className="prize-row-cell--aligned-right">Prize</Box>
          </Box>
        ),
        value: ''
      }
    ];

    tournament?.ranking?.forEach((rank, index) => {
      let wagered = '';

      if (rank.wagered > 0) {
        wagered = rank.wagered.toFixed(2);
      }

      infoBoxItems.push({
        key: (
          <Box className="prize-row">
            <Box className="prize-row-cell">
              <Box className={`prize-item__place${rank.prize > 0 ? '--winning' : ''}`}>
                {index + 1}
              </Box>
              <span>{rank.userName}</span>
            </Box>
            <Box className="prize-row-cell--centered">
              {tournament?.type.toLowerCase() === 'spin' ? rank.spins : wagered}
            </Box>
            <Box className="prize-row-cell--aligned-right">
              {rank.prize} {rank.prize > 0 && <Box component="img" src={doubleCoin} />}
            </Box>
          </Box>
        ),
        value: ''
      });
    });

    return infoBoxItems;
  };

  const renderDetails = () => {
    let details = (
      <>
        <section className="tournament-info__details--highlighted">
          <span>
            <strong>{`${moment(tournament?.startDate).format(DATE_FORMAT)}`}</strong>
          </span>
          <span className="separator">
            <strong>-</strong>
          </span>
          <span>
            <strong>{`${moment(tournament?.endDate).format(DATE_FORMAT)}`}</strong>
          </span>
        </section>
      </>
    );

    if (isCompact) {
      details = (
        <>
          <section>
            <span>{`${t('tournaments.card.poolPrize')}`}</span>
            <Box component="img" src={doubleCoin} />
            <span>
              <strong>{tournament?.prizePool}</strong>
            </span>
          </section>
          <section className="tournament-info__details--highlighted">
            <span>{`${t('tournaments.card.timer.ended')}`}</span>
            <span>
              <strong>{`${moment(tournament?.endDate).format(DATE_FORMAT)}`}</strong>
            </span>
          </section>
        </>
      );
    }

    return details;
  };

  const renderTournamentCardGames = () => {
    let tournamentCardGamesContent = (
      <Box className="tournament-card__games">
        <Box className="tournament-card__games-title">
          {t('tournaments.card.applicableForAllGames')}
        </Box>
      </Box>
    );

    if (tournament?.availableGameIds && tournament?.availableGameIds?.length > 0) {
      tournamentCardGamesContent = (
        <TournamentCardGames availableGameIds={tournament?.availableGameIds} />
      );
    }

    return tournamentCardGamesContent;
  };

  const infoBoxItems = getInfoBoxItems();
  const customChipLabel = t(
    `tournaments.card.label.${tournament?.isActive ? 'active' : 'upcoming'}`
  );

  return (
    <>
      <Fade key={`fade-${index}`} in timeout={1000 + index * 600}>
        <Box className={`tournament ${isCompact ? 'tournament--passed' : ''}`}>
          <Box className="tournament-info">
            {!isCompact && (
              <CustomChip
                label={customChipLabel}
                type={tournament?.isActive ? 'success' : 'default'}
              />
            )}
            <section>
              <Box className="tournament-info__name">{tournament?.name}</Box>
              <Box className="tournament-info__details">{renderDetails()}</Box>
            </section>
            {!isCompact && (
              <TournamentCardExtraInfo
                minBet={tournament?.minBet}
                winType={tournament?.winType}
                endDate={tournament?.endDate}
                duration={tournament?.duration}
                startDate={tournament?.startDate}
                prizePool={tournament?.prizePool}
                isActive={Boolean(tournament?.isActive)}
              />
            )}
            {!isCompact && tournament?.isActive && (
              <Box className="tournament-actions--extra-top-margin">
                {!hasJoinedTournament ? (
                  <PrimaryButton
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isUserLoggedIn()) {
                        joinTournamentQuery.mutate(tournament?.id);
                      } else {
                        dispatch({
                          type: Actions.ShowLoginPopup,
                          payload: {}
                        });
                      }
                    }}
                    text={t('tournaments.card.joinTournamentButton')}
                    className="tournament-actions__main-button"
                  />
                ) : (
                  <PrimaryButton
                    disabled
                    icon={<CheckOutlined />}
                    text={t('tournaments.card.joinedTournamentButton')}
                    className="tournament-actions__main-button"
                  />
                )}

                <CustomIconButton icon={<InfoOutlined />} onClick={() => setOpenPromoInfo(true)} />
              </Box>
            )}
            {!isCompact && renderTournamentCardGames()}
          </Box>
          {isCompact && (
            <Box className="tournament-actions">
              <PrimaryButton
                onClick={() => setOpenPromoInfo(true)}
                className="tournament-actions__main-button"
                text={`${t('tournaments.card.leaderBoard.label')}`}
              />
            </Box>
          )}
        </Box>
      </Fade>
      <InfoBox
        open={openPromoInfo}
        title={tournament?.name}
        subtitle={
          <Box className="tournament-dialog__extra-info">
            <Box className="tournament-dialog__extra-info__date">
              {tournament?.startDate ? (
                <span>{`${t('tournaments.card.timer.endsОn')}`}</span>
              ) : (
                <span>{`${t('tournaments.card.timer.ended')}`}</span>
              )}
              <span>
                <strong>{`${moment(tournament?.endDate).format(DATE_FORMAT)}`}</strong>
              </span>
            </Box>
            <span
              className={`tournament-dialog__extra-info__text ${
                extraInfoExpanded && 'tournament-dialog__extra-info__text--expanded'
              }`}
            >
              {tournament?.descriptions.find(
                (d) => d.language.toUpperCase() === i18n.language.toUpperCase()
              )?.text ?? tournament?.descriptions[0].text}
            </span>
            <button
              onClick={() => toggleDialogExtraInfoExpand()}
              className="tournament-dialog__extra-info__button"
            >
              {extraInfoExpanded ? t('tournaments.card.readLess') : t('tournaments.card.readMore')}
            </button>
          </Box>
        }
        onClose={() => setOpenPromoInfo(false)}
      >
        <InfoPairs items={infoBoxItems ?? []} />
      </InfoBox>
    </>
  );
};

export default TournamentCard;
