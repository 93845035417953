import React, { useContext } from 'react';
import { Box } from '@mui/material';
import BalanceBox from './BalanceBox';
import { colors } from '../../utils/theme';
import { CardGiftcard, Payments, Stars } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../AppContext';
import CSS from 'csstype';

export const IconBox = ({ icon, isNav = false }: { icon: JSX.Element; isNav?: boolean }) => {
  return (
    <Box
      sx={{
        width: isNav ? '44px' : '52px',
        height: isNav ? '44px' : '52px',
        border: '2px solid',
        color: colors.gradients.yellowRgb,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px'
      }}
    >
      {icon}
    </Box>
  );
};

interface IProps {
  sx?: CSS.Properties;
}
const BalanceOverview: React.FunctionComponent<IProps> = ({ sx }: IProps) => {
  const { state } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        ...(sx && { ...sx }),
        display: 'flex',
        gap: { xs: '8px', md: '16px' },
        flexFlow: { xs: 'column', md: 'row' }
      }}
    >
      <Box sx={{ display: 'flex', flex: 2, gap: { xs: '8px', md: '16px' } }}>
        <BalanceBox
          title={t('wallet.realBalance')}
          value={state.user.data?.realBalance || 0}
          loading={state.loadingBalance}
          icon={
            <IconBox
              icon={
                <Payments
                  sx={{ color: colors.gradients.yellowRgb, width: '32px', height: '32px' }}
                />
              }
            />
          }
        />
        <BalanceBox
          title={t('wallet.bonusBalance')}
          value={state.user.data?.bonusBalance || 0}
          loading={state.loadingBalance}
          icon={
            <IconBox
              icon={
                <CardGiftcard
                  sx={{ color: colors.gradients.yellowRgb, width: '32px', height: '32px' }}
                />
              }
            />
          }
        />
      </Box>
      <Box sx={{ display: 'flex', flex: 1 }}>
        <BalanceBox
          title={t('myAccount.coins')}
          value={state.user.data?.loyaltyPoints ?? 0}
          loading={state.loadingBalance}
          hideCurrency
          icon={
            <IconBox
              icon={
                <Stars sx={{ color: colors.gradients.yellowRgb, width: '32px', height: '32px' }} />
              }
            />
          }
        />
      </Box>
    </Box>
  );
};

export default BalanceOverview;
