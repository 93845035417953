import React from 'react';
//* MUI
import { Box } from '@mui/material';
//* Styles
import './TournamentCardClockBox.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  title: string;
  value: string;
  showOnEarnMoreBanners?: boolean;
}

const TournamentCardClockBox = ({ title, value, showOnEarnMoreBanners = false }: IProps) => {
  const { t } = useTranslation();
  return (
    <Box
      className={
        showOnEarnMoreBanners
          ? `tournament-card__clock-box--earn-more-banner ${
              title === t('tournaments.card.timer.hours') ||
              title === t('tournaments.card.timer.min')
                ? 'tournament-card__clock-box--extra-content'
                : ''
            }`
          : `tournament-card__clock-box ${
              title === t('tournaments.card.timer.hours') ||
              title === t('tournaments.card.timer.min')
                ? 'tournament-card__clock-box--extra-content'
                : ''
            }`
      }
    >
      <Box
        className={
          showOnEarnMoreBanners
            ? 'tournament-card__clock-box--earn-more-banner-value'
            : 'tournament-card__clock-box__value'
        }
      >
        {value}
      </Box>
      {title}
    </Box>
  );
};

export default TournamentCardClockBox;
