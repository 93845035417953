import React from 'react';
//* MUI
import { Box } from '@mui/material';
//* Styles
import './UnlockedChallengeIndicator.scss';

interface IProps {
  position?: string;
}

const UnlockedChallengeIndicator = ({ position }: IProps) => {
  return <Box className={`indicator indicator--${position}`} />;
};

UnlockedChallengeIndicator.propTypes = {};

export default UnlockedChallengeIndicator;
