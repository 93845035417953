import React from 'react';
import { useTranslation } from 'react-i18next';
//* MUI
import { Box, TextField } from '@mui/material';
//* MUI icons
//* Components
import AutocompleteField from '../../../../Common/Fields/AutocompleteField';
import ReceivingDetailsWarningMessage from '../ReceivingDetailsWarningMessage/ReceivingDetailsWarningMessage';
//* Models
import { IBankDetails } from '../../../../../models/wallet';
//* Enums
//* Utils
import { countries, CountryType } from '../../../../../utils/countries';
//* Styles
import './ReceivingDetailsBankTranfer.scss';

interface IProps {
  iban: string;
  ibanError: string | null;
  bankDetails: IBankDetails;
  handleIbanChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSetBankDetails: (field: string, value: string | CountryType | null) => void;
}

const ReceivingDetailsBankTranfer: React.FunctionComponent<IProps> = ({
  iban,
  ibanError,
  bankDetails,
  handleIbanChange,
  handleSetBankDetails
}) => {
  const { t } = useTranslation();

  const getCountriesOptions = () => {
    const countriesOptions = [...countries];

    return countriesOptions;
  };

  const getAutocompleteValue = () => {
    const countryDetails = countriesOptions.find(
      (country) => country.label === bankDetails.country
    );

    return countryDetails;
  };

  const countriesOptions = getCountriesOptions();
  const autoCompleteValue = getAutocompleteValue();
  const ibanHasError = !!ibanError && iban.length > 0;

  return (
    <Box className="receiving-details__bank">
      <Box className="receiving-details__bank-info">
        <AutocompleteField
          id="type"
          isDarkTheme
          inputHeight={56}
          disableClearable
          inputFont="Poppins"
          value={autoCompleteValue}
          options={countriesOptions}
          customClassName="receiving-details__bank-info__dropdown"
          placeholder={`${t('wallet.withdrawPage.bank')} ${t('wallet.withdrawPage.country')}`}
          onChange={(event: unknown, newValue: CountryType | null) => {
            if (newValue) {
              handleSetBankDetails('country', newValue.label);
            }
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                height: '46px',
                '& > img': { mr: 2, flexShrink: 0 },
                '& .MuiInputBase - input': {
                  height: '46px'
                }
              }}
              {...props}
            >
              <img
                className="receiving-details__bank-filter__option-image"
                width="20"
                loading="lazy"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt="countryFlag"
              />
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {option.label}
            </Box>
          )}
          optionLabel={(option) => option.label}
        />
        <TextField
          className="receiving-details__bank-info__input"
          fullWidth
          color="info"
          margin="none"
          id="otherAmount"
          name="otherAmount"
          value={iban || ''}
          error={ibanHasError}
          placeholder={t('wallet.withdrawPage.ibanLabel') as string}
          onChange={(event) => handleIbanChange(event as React.ChangeEvent<HTMLInputElement>)}
        />
        <ReceivingDetailsWarningMessage message={t('wallet.withdrawPage.bankDetailsWarning')} />
        <Box className="receiving-details__bank-details">
          <Box className="receiving-details__bank-details-title">
            {t('wallet.withdrawPage.bank')} {t('wallet.withdrawPage.details')}
          </Box>
          <Box className="receiving-details__bank-details-row">
            <Box className="receiving-details__bank-details-row__input-container">
              <label>{t('wallet.withdrawPage.swiftBic')}</label>
              <TextField
                fullWidth
                color="info"
                margin="none"
                id="otherAmount"
                name="otherAmount"
                value={bankDetails.swift || ''}
                className="receiving-details__bank-info__input"
                placeholder={t('wallet.withdrawPage.swiftBic') as string}
                onChange={(event) => handleSetBankDetails('swift', event.target.value)}
              />
            </Box>
            <Box className="receiving-details__bank-details-row__input-container">
              <label>
                {t('wallet.withdrawPage.bank')} {t('wallet.withdrawPage.name')}
              </label>
              <TextField
                fullWidth
                color="info"
                margin="none"
                id="otherAmount"
                name="otherAmount"
                value={bankDetails.bankName || ''}
                className="receiving-details__bank-info__input"
                placeholder={t('wallet.withdrawPage.name') as string}
                onChange={(event) => handleSetBankDetails('bankName', event.target.value)}
              />
            </Box>
          </Box>
          <Box className="receiving-details__bank-details-row">
            <Box className="receiving-details__bank-details-row__input-container">
              <label>
                {t('wallet.withdrawPage.bank')} {t('wallet.withdrawPage.branch')}
              </label>
              <TextField
                fullWidth
                color="info"
                margin="none"
                id="otherAmount"
                name="otherAmount"
                value={bankDetails.branch || ''}
                className="receiving-details__bank-info__input"
                placeholder={t('wallet.withdrawPage.branch') as string}
                onChange={(event) => handleSetBankDetails('branch', event.target.value)}
              />
            </Box>
            <Box className="receiving-details__bank-details-row__input-container">
              <label>
                {t('wallet.withdrawPage.bank')} {t('wallet.withdrawPage.city')}
              </label>
              <TextField
                fullWidth
                color="info"
                margin="none"
                id="otherAmount"
                name="otherAmount"
                value={bankDetails.city || ''}
                className="receiving-details__bank-info__input"
                placeholder={t('wallet.withdrawPage.city') as string}
                onChange={(event) => handleSetBankDetails('city', event.target.value)}
              />
            </Box>
          </Box>
          <Box className="receiving-details__bank-details-row">
            <Box className="receiving-details__bank-details-row__input-container">
              <label>
                {t('wallet.withdrawPage.bank')} {t('wallet.withdrawPage.address')}
              </label>
              <TextField
                fullWidth
                color="info"
                margin="none"
                id="otherAmount"
                name="otherAmount"
                value={bankDetails.address || ''}
                className="receiving-details__bank-info__input"
                placeholder={t('wallet.withdrawPage.address') as string}
                onChange={(event) => handleSetBankDetails('address', event.target.value)}
              />
            </Box>
            <Box className="receiving-details__bank-details-row__input-container">
              <label>
                {t('wallet.withdrawPage.bank')} {t('wallet.withdrawPage.postalCode')}
              </label>
              <TextField
                fullWidth
                color="info"
                margin="none"
                id="otherAmount"
                name="otherAmount"
                value={bankDetails.postCode || ''}
                className="receiving-details__bank-info__input"
                placeholder={t('wallet.withdrawPage.postalCode') as string}
                onChange={(event) => handleSetBankDetails('postCode', event.target.value)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReceivingDetailsBankTranfer;
