import React from 'react';
//* MUI
import { Box, Fade, SvgIconProps } from '@mui/material';
//* Styles
import './MenuTabs.scss';

interface MenuTabsOptions {
  title: string;
  icon: React.ReactElement<SvgIconProps>;
  iconRotationDegrees: string;
}

interface IProps {
  type?: string;
  options: MenuTabsOptions[];
  handleSelection: (index: number) => void;
  selectedIndex: number;
}

const MenuTabs = ({ options, handleSelection, type = 'horizontal', selectedIndex }: IProps) => {
  return (
    <Box className={`menu-tabs menu-tabs-${type}`}>
      {options.map((option, index) => (
        <Fade key={`fade-${index}`} in timeout={1000 + index * 300}>
          <button
            className={`menu-tabs__button ${
              index === selectedIndex ? 'menu-tabs__button--is-selected' : ''
            }`}
            onClick={() => handleSelection(index)}
          >
            <Box
              className={`menu-tabs__button-icon ${
                option.iconRotationDegrees.length > 0
                  ? `menu-tabs__button-icon--rotate-${option.iconRotationDegrees}`
                  : ''
              }`}
            >
              {option.icon}
            </Box>
            <Box className="menu-tabs__button-title">{option.title}</Box>
          </button>
        </Fade>
      ))}
    </Box>
  );
};

export default MenuTabs;
