import React from 'react';
import { Box } from '@mui/material';
import './index.css';
import bubbleImg from '../../assets/bubble.svg';

const Bubbles: React.FunctionComponent = () => {
  return (
    <Box className="ocean" sx={{ width: '100%' }}>
      <Box className="bubble bubble--1" component="img" src={bubbleImg}></Box>
      <Box className="bubble bubble--2" component="img" src={bubbleImg}></Box>
      <Box className="bubble bubble--3" component="img" src={bubbleImg}></Box>
      <Box className="bubble bubble--4" component="img" src={bubbleImg}></Box>
      <Box className="bubble bubble--5" component="img" src={bubbleImg}></Box>
      <Box className="bubble bubble--6" component="img" src={bubbleImg}></Box>
      <Box className="bubble bubble--7" component="img" src={bubbleImg}></Box>
      <Box className="bubble bubble--8" component="img" src={bubbleImg}></Box>
      <Box className="bubble bubble--9" component="img" src={bubbleImg}></Box>
      <Box className="bubble bubble--10" component="img" src={bubbleImg}></Box>
      <Box className="bubble bubble--11" component="img" src={bubbleImg}></Box>
    </Box>
  );
};

export default Bubbles;
